import { ForwardRefExoticComponent, SVGProps } from "react";
import clsx from "clsx";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

export interface AlertProps {
  message: string | string[];
  severity?: "info" | "warn" | "error" | "success";
  title?: string;
  className?: string;
}

/**
 * A styled alert component
 *
 * @param {"info" | "warn" | "error" | "success"} severity
 * @param {string} title
 * @param {string} message
 */

export function Alert({
  severity = "info",
  title,
  message,
  className,
}: AlertProps) {
  let classes: string;
  let Icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref">>;

  switch (severity) {
    case "success":
      Icon = CheckCircleIcon;
      classes = "bg-green-700/30 border-green-600/70 text-green-200";
      break;
    case "error":
      Icon = XCircleIcon;
      classes = "bg-red-700/30 border-red-600/70 text-red-200";
      break;
    case "warn":
      Icon = ExclamationCircleIcon;
      classes = "bg-yellow-700/30 border-yellow-600/70 text-yellow-100";
      break;
    case "info":
    default:
      Icon = InformationCircleIcon;
      classes = "bg-blue-700/30 border-blue-600/70 text-blue-200";
      break;
  }

  const Message = () => {
    return Array.isArray(message) ? (
      <div>
        {message.map((msg) => (
          <p className="text-sm break-words">{msg}</p>
        ))}
      </div>
    ) : (
      <p className="text-sm break-words">{message}</p>
    );
  };

  return (
    <div
      className={clsx(
        "flex items-center px-3 py-2 rounded text-white border",
        classes,
        className,
      )}
    >
      <Icon className="flex-shrink-0 w-6 h-6 mr-3" />
      {title ? (
        <div>
          <h4 className="font-bold">{title}</h4>
          <Message />
        </div>
      ) : (
        <Message />
      )}
    </div>
  );
}
