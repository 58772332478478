import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import {
  IHardwareDeleteRequest,
  IHardware,
  IHardwareCreateRequest,
  IHardwareUpdateRequest,
} from "../../types/Hardware/Hardware";

export default function useHardwareMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware");

  const createHardware = useMutation({
    mutationFn: (hardware: IHardwareCreateRequest) =>
      post<IHardwareCreateRequest, IHardware>("/v1/hardware", hardware),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => queryClient.invalidateQueries("hardware"),
  });

  const updateHardware = useMutation({
    mutationFn: (hardware: IHardwareUpdateRequest) =>
      put<IHardwareUpdateRequest, IHardware>(
        `/v1/hardware/${hardware.id}`,
        hardware,
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => queryClient.invalidateQueries("hardware"),
  });

  const delHardware = useMutation({
    mutationFn: (hardware: IHardwareDeleteRequest) =>
      del(`/v1/hardware/${hardware.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => queryClient.invalidateQueries("hardware"),
  });

  return {
    createHardware,
    updateHardware,
    delHardware,
  };
}
