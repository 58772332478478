import useJobCategories from "../data/job/useJobCategories";

export const useCategoryLookups = () => {
  const { jobCategories } = useJobCategories();

  const lookup = (categoryId: string) =>
    jobCategories.data?.find((c) => c.id === categoryId);

  return {
    isLoading: jobCategories.isLoading,
    categoryLookup: lookup,
  };
};
