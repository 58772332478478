import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotifierContextProvider } from "react-headless-notifier";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "react-query/devtools";

import routes from "./routes";
import appInfo from "../package.json";
import { UserContextProvider } from "./contexts/UserContext";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import { PageTitleContextProvider } from "./contexts/PageTitleContext";
import { isProd } from "./config";
import { Button } from "./components/base/Button";

console.log(`V${appInfo.version}`);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const notifierConfig = {
  max: 3,
  duration: 8000,
  position: "topRight",
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotifierContextProvider config={notifierConfig}>
        <CookiesProvider>
          <UserContextProvider>
            <ThemeContextProvider>
              <PageTitleContextProvider>
                {!isProd ? (
                  <div className="py-2 flex items-center justify-center text-lg text-white bg-red-500">
                    You are viewing the Planet Devices{" "}
                    <span className="mx-2 font-bold">
                      {process.env.REACT_APP_RUN_ENV}
                    </span>{" "}
                    environment
                    <Button
                      size="sm"
                      className="ml-2 !border-none !text-white !bg-red-600 hover:!bg-red-700 active:!bg-red-700"
                      onClick={() =>
                        window.location.assign(
                          "https://sphere.planetdevices.com"
                        )
                      }
                    >
                     Go to Production System
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                <RouterProvider router={router} />
              </PageTitleContextProvider>
            </ThemeContextProvider>
          </UserContextProvider>
        </CookiesProvider>
      </NotifierContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
