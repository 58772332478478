import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { IActionProfile } from "../../../types/Action/ActionProfile";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { CreateEditActionProfile } from "../../../components/Action/CreateEditActionProfile";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import useSuperAdminActionProfileMutations from "../../../data/action/useSuperAdminActionProfileMutation";
import { actionProfileQueryKeys } from "../../../data/QueryKeys";
import { useActionLookups } from "../../../hooks/useActionLookups";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";
import { SectionLoading } from "../../../components/shared/SectionLoading";
import { useClipboardCopy } from "../../../hooks/useClipboardCopy";

const colHelper = createColumnHelper<IActionProfile>();

export function ActionProfilesPage() {
  const { CopyToClipboardButton } = useClipboardCopy();
  const { delActionProfile, updateActionProfile } =
    useSuperAdminActionProfileMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editActionProfile, setEditActionProfile] = useState<
    IActionProfile | undefined
  >(undefined);
  const [deleteActionProfile, setDeleteActionProfile] = useState<
    IActionProfile | undefined
  >(undefined);
  const {
    isLoading: isHardwareLookupLoading,
    manufacturerLookup,
    modelLookup,
    typeLookup,
  } = useSuperAdminHardwareLookups();
  const { isLoading: isActionLookupLoading, actionLookup } = useActionLookups();

  const handleDeleteActionProfile = (actionProfile: IActionProfile) => {
    setDeleteActionProfile(actionProfile);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteActionProfile = () => {
    delActionProfile.mutate(deleteActionProfile!);
    setDeleteOpen(false);
  };

  const handleRestoreActionProfile = (actionProfile: IActionProfile) => {
    updateActionProfile.mutate({ ...actionProfile, restore: true });
  };

  const handleEditActionProfile = (actionProfile: IActionProfile) => {
    setEditActionProfile(actionProfile);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editActionProfile && setTimeout(() => setEditActionProfile(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" },
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => actionLookup(row.actionId)?.name, {
      header: "Action",
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreActionProfile(info.row.original)}
            />
          ) : (
            <>
                          <CopyToClipboardButton iconOnly copyText={info.row.original.id} />
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditActionProfile(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteActionProfile(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditActionProfile
        open={editOpen}
        onClose={handleCreateEditClose}
        actionProfile={editActionProfile}
      />
      <ConfirmDelete
        itemName={"Action Profile"}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteActionProfile()}
      />
      <Card title="Action Profiles">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Action Profile</Button>
        </div>
        {isHardwareLookupLoading || isActionLookupLoading ? (
          <SectionLoading />
        ) : (
          <div>
            <Table<IActionProfile>
              url="/superadmin/action/profile"
              queryKey={actionProfileQueryKeys.superAdminAll()}
              columns={cols}
              predefinedFilterFields={
                globalHardwareModelFilter
                  ? [
                      {
                        field: "hardwareModelId",
                        value: globalHardwareModelFilter.id,
                        fieldLabel: "Hardware Model",
                        valueLabel: globalHardwareModelFilter.name,
                      },
                    ]
                  : undefined
              }
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
