import { Button } from "./components/base/Button";

export function ErrorFallback() {
  return (
    <div className="mt-24 min-h-1/2 bg-backdrop px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-primary sm:text-5xl">
            Application Error
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-600 dark:sm:border-gray-200 sm:pl-6">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-white">
                Well this is embarrassing, we've run into a problem.
              </h1>
              <p className="mt-1 text-base text-gray-500">
                This error has been reported to the team for investigation,
                please try again. If the issue persists, contact{" "}
                <a
                  className=" underline"
                  href="mailto:support@planetdevices.com"
                >
                  support
                </a>
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button onClick={() => window.location.reload()}>Reload</Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
