import { useFormContext } from "react-hook-form";

import { Button } from "../../base/Button";
import { FormSelect } from "../../base/Form/FormSelect";
import { ComponentCalibration } from "../ComponentCalibration";
import { useCalibrationDefaults } from "../../../hooks/useCalibrationDefaults";
import { IHardwareCreateUpdateFormFields } from "../../../types/Hardware/Hardware";
import {
  isValidCTOffsetValue,
  isValidScaleValue,
} from "../../../utils/validationFunctions";

export function TemperatureSensorSettings() {
  const { isLoading: defaultsLoading, getDefaultValue } =
    useCalibrationDefaults();
  const locationOpts = ["Indoor", "Outdoor"];
  const { register, setValue } =
    useFormContext<IHardwareCreateUpdateFormFields>();

  const handleApplyDefaultTSCalib = () => {
    setValue("peripheralSettings.temperatureSensor", {
      enabled: true,
      offset: getDefaultValue("Temperature", "Offset"),
      scale: getDefaultValue("Temperature", "Scale"),
      location: "Indoor",
    });
  };

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <div className="flex items-center justify-between mb-4">
        <p className="text-base font-medium">Temperature Sensor Settings</p>
        {!defaultsLoading && (
          <Button onClick={() => handleApplyDefaultTSCalib()} size="sm">
            Apply Default Calibration
          </Button>
        )}
      </div>
      <ComponentCalibration
        offsetLabel="Offset (°C)"
        offsetRegisterFn={register(
          "peripheralSettings.temperatureSensor.offset",
          {
            validate: {
              isValid: (v) =>
                isValidCTOffsetValue(v)
                  ? true
                  : "Offset must be between -327.68°C and 327.67°C",
            },
          }
        )}
        scaleRegisterFn={register(
          "peripheralSettings.temperatureSensor.scale",
          {
            validate: {
              isValid: (v) =>
                isValidScaleValue(v)
                  ? true
                  : "Scale must be between 0.1% and 6553.5%",
            },
          }
        )}
        classes="grid grid-cols-2 sm:grid-cols-4 gap-4"
        extraConfigComponent={
          <>
            <FormSelect
              {...register("peripheralSettings.temperatureSensor.location")}
              label="Location"
            >
              {locationOpts.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </FormSelect>
          </>
        }
      />
    </div>
  );
}
