import { Dispatch, FormEvent, SetStateAction, useMemo } from "react";

import { Select } from "../../base/Select";
import { LoadingWheel } from "../../base/LoadingWheel";
import { IHardware } from "../../../types/Hardware/Hardware";
import { useUserContext } from "../../../contexts/UserContext";
import { ITemplateConfiguration } from "../../../types/Template/Template";
import useCustomerTemplateConfigurations from "../../../data/configuration/useCustomerTemplateConfiguration";
import useInstallerTemplateConfigurations from "../../../data/configuration/useInstallerTemplateConfiguration";

interface TemplateSelectOptionsProps {
  templates?: ITemplateConfiguration[];
  hardwareModelId: string;
  noTemplates: boolean;
}

function TemplateSelectOptions({
  templates,
  hardwareModelId,
  noTemplates,
}: TemplateSelectOptionsProps) {
  const filteredTemplates = useMemo(
    () =>
      templates?.filter((t) => t.template.hardwareModelId === hardwareModelId),
    [templates, hardwareModelId]
  );

  if (
    filteredTemplates !== null &&
    filteredTemplates !== undefined &&
    filteredTemplates.length > 0
  ) {
    return (
      <>
        {filteredTemplates.map((t) => (
          <option key={t.id} value={t.id}>
            {t.template.name}
          </option>
        ))}
      </>
    );
  } else {
    return (
      <option selected={noTemplates} disabled value="">
        No templates
      </option>
    );
  }
}

interface ViewTemplateSelectProps {
  selectedHardware: IHardware;
  selectedTemplate?: ITemplateConfiguration;
  setSelectedTemplate: Dispatch<
    SetStateAction<ITemplateConfiguration | undefined>
  >;
}

export function ViewTemplateSelect({
  selectedHardware,
  selectedTemplate,
  setSelectedTemplate,
}: ViewTemplateSelectProps) {
  const { isInstaller, installerId, installerName, organisationName } =
    useUserContext();
  const { templateConfigurations: installerTemplateConfigurations } =
    useInstallerTemplateConfigurations();
  const { templateConfigurations: customerTemplateConfigurations } =
    useCustomerTemplateConfigurations();

  const noTemplates = useMemo(
    () =>
      (!installerTemplateConfigurations.data &&
        !customerTemplateConfigurations.data) ||
      (!installerTemplateConfigurations.data?.length &&
        !customerTemplateConfigurations.data?.length),
    [installerTemplateConfigurations.data, customerTemplateConfigurations.data]
  );

  const installerDefault = useMemo(
    () =>
      installerTemplateConfigurations.data?.find(
        (t) => t.template.default === true
      )!,
    [installerTemplateConfigurations.data]
  );

  const handleTemplateChange = (e: FormEvent<HTMLSelectElement>) => {
    const template = [
      ...installerTemplateConfigurations.data!,
      ...customerTemplateConfigurations.data!,
    ].find((t) => t.id === e.currentTarget.value);

    if (template) {
      setSelectedTemplate(template);
    } else {
      console.error("Failed to match template");
    }
  };

  if (
    installerTemplateConfigurations.isLoading ||
    customerTemplateConfigurations.isLoading
  ) {
    return (
      <div>
        <LoadingWheel size="sm" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        <p className="text-sm mb-2 md:mb-0">Template</p>
        <Select
          value={
            noTemplates
              ? ""
              : selectedTemplate
                ? selectedTemplate.id
                : installerDefault.id
          }
          onChange={handleTemplateChange}
        >
          {installerId && (
            <optgroup label={`${installerName}'s Templates`}>
              <TemplateSelectOptions
                templates={installerTemplateConfigurations.data}
                hardwareModelId={selectedHardware.hardwareModelId}
                noTemplates={noTemplates}
              />
            </optgroup>
          )}
          <optgroup label={`${organisationName}'s Templates`}>
            <TemplateSelectOptions
              templates={
                isInstaller && !installerId
                  ? installerTemplateConfigurations.data
                  : customerTemplateConfigurations.data
              }
              hardwareModelId={selectedHardware.hardwareModelId}
              noTemplates={noTemplates}
            />
          </optgroup>
        </Select>
      </div>
    );
  }
}
