import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { configurationQueryKeys } from "../QueryKeys";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";
import {
  ITemplateConfiguration,
  TemplateCreateUpdateRequest,
} from "../../types/Template/Template";

export default function useInstallerTemplateConfigurationsMutations() {
  const { handleError, handleSuccess } =
    useDefaultMutateResponseHandlers("Template");
  const queryClient = useQueryClient();
  const { put, post, del } = useApiHelper();

  const createTemplate = useMutation({
    mutationFn: (config: TemplateCreateUpdateRequest) =>
      post<
        TemplateCreateUpdateRequest,
        IDefaultResponseWithData<ITemplateConfiguration>
      >("/v1/installer/configurations", config).then((res) => res.data.data),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(configurationQueryKeys.all());
    },
  });

  const updateTemplate = useMutation({
    mutationFn: (config: TemplateCreateUpdateRequest) =>
      put<
        TemplateCreateUpdateRequest,
        IDefaultResponseWithData<ITemplateConfiguration>
      >(`/v1/installer/configurations/${config.id}`, config).then(
        (res) => res.data.data
      ),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(configurationQueryKeys.all());
    },
  });

  const delTemplate = useMutation({
    mutationFn: (config: ITemplateConfiguration) =>
      del(`/v1/installer/configurations/${config.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(configurationQueryKeys.all());
    },
  });

  return {
    createTemplate,
    updateTemplate,
    delTemplate,
  };
}
