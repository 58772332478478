import { LoadingWheel } from "../base/LoadingWheel";

import SizeOptions from "../../types/system/SizeOption";

export interface SectionLoadingProps {
  size?: SizeOptions;
}

export function SectionLoading({ size }: SectionLoadingProps) {
  return (
    <div className="py-12 w-full flex items-center justify-center">
      <LoadingWheel size={size} />
    </div>
  );
}
