import { Dispatch, useEffect, useState } from "react";

import clsx from "clsx";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import { Card } from "../base/Card";
import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { TextField } from "../base/TextField";
import { LoadingWheel } from "../base/LoadingWheel";
import { IHardware } from "../../types/Hardware/Hardware";
import { getGranularities } from "../../utils/graphUtils";
import { StatisticStateReducerActions } from "./Statistics";
import { useGraphData } from "../../data/datapoint/useGraphData";
import { ISimpleDataPoint } from "../../types/DataPoint/DataPoint";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";
import { IStatistic, StatisticType } from "../../types/Statistic/Statistic";
import {
  accumulatedDataPoints,
  averagedDataPoints,
} from "../../constants/datapointLists";
import useDataPointsByHardwareModel from "../../data/datapoint/useDataPointsByHardwareModel";

interface StatCardValue {
  hasValue: boolean;
  value?: number;
}

interface StatCardProps extends Omit<IStatistic, "id"> {
  id: string;
  hardware: IHardware;
  dispatch: Dispatch<StatisticStateReducerActions>;
}

export function StatCard({
  type,
  title,
  id,
  dataPointIds,
  dispatch,
}: StatCardProps) {
  const {
    currentValue: { hardware, timespan, granularity },
  } = useGraphSettings();
  const { getGraphData } = useGraphData();
  const { dataPoints } = useDataPointsByHardwareModel(
    hardware!.hardwareModelId
  );
  const [showConfig, setShowConfig] = useState(false);
  const [titleEditing, setTitleEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(title);
  const [selectedDataPoint, setSelectedDataPoint] = useState<string>(
    dataPointIds[0] ?? "-1"
  );
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<StatCardValue>();
  const [datapoint, setDataPoint] = useState<ISimpleDataPoint>();
  const [noDataPoint, setNoDataPoint] = useState(false);
  const dataPointOptions =
    type === StatisticType.average ? averagedDataPoints : accumulatedDataPoints;

  const handleHideConfig = () => {
    setTitleEditing(false);
    setShowConfig(false);
  };

  const handleSaveTitle = () => {
    dispatch({
      type: "updateTitle",
      payload: {
        id,
        title: titleValue,
      },
    });
    setTitleEditing(false);
  };

  const handleRemoveAverage = () => {
    dispatch({
      type: "removeStat",
      payload: {
        id,
      },
    });
  };

  const findLowestGranularity = () =>
    getGranularities(timespan.endDate.diff(timespan.startDate))[0];

  const findDataPoint = async () =>
    dataPoints.data!.find((d) => d.id === dataPointIds[0]);

  const calculateAccumulation = async () => {
    const data = await getGraphData({
      hardwareId: hardware!.id,
      timespan,
      granularity: findLowestGranularity(),
      datapoints: dataPointIds,
    });

    const total = data[0].data.reduce(
      (acc, curr) =>
        (acc += curr.readingData[0] ? curr.readingData[0].transformedValue : 0),
      0
    );

    return { hasValue: true, value: total };
  };

  const calculateAverage = async () => {
    const data = await getGraphData({
      hardwareId: hardware!.id,
      timespan,
      granularity: findLowestGranularity(),
      datapoints: dataPointIds,
    });

    const total = data[0].data.reduce(
      (acc, curr) =>
        (acc += curr.readingData[0] ? curr.readingData[0].transformedValue : 0),
      0
    );

    if (data[0].data.length > 0) {
      return { hasValue: true, value: total / data[0].data.length };
    } else {
      return { hasValue: false, value: undefined };
    }
  };

  useEffect(() => {
    setLoading(true);

    if (!dataPoints.isLoading && dataPoints.data && dataPointIds.length > 0) {
      const calculateFn =
        type === StatisticType.average
          ? calculateAverage
          : calculateAccumulation;
      Promise.all([calculateFn(), findDataPoint()]).then((d) => {
        noDataPoint && setNoDataPoint(false);
        setValue(d[0]);
        setDataPoint(d[1]);
        setLoading(false);
      });
    } else if (dataPointIds.length <= 0) {
      setNoDataPoint(true);
      setLoading(false);
    }
  }, [
    type,
    dataPoints.isLoading,
    dataPoints.data,
    dataPointIds,
    hardware,
    timespan,
    granularity,
  ]);

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  return (
    <Card>
      <div className="flex items-start justify-between mb-3">
        <div className="flex items-center space-x-4">
          {titleEditing ? (
            <TextField
              value={titleValue}
              onChange={(e) => setTitleValue(e.currentTarget.value)}
            />
          ) : (
            <p className="text-3xl">{title}</p>
          )}
          {showConfig ? (
            titleEditing ? (
              <Button className="h-[42px]" onClick={handleSaveTitle}>
                <CheckIcon className="w-5 h-5" />
              </Button>
            ) : (
              <PencilIcon
                className="w-5 h-5"
                onClick={() => setTitleEditing(true)}
              />
            )
          ) : (
            <></>
          )}
        </div>
        {showConfig ? (
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <ChevronUpIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "up" },
                  })
                }
              />
              <ChevronDownIcon
                className="w-6 h-6"
                onClick={() =>
                  dispatch({
                    type: "moveTile",
                    payload: { id, direction: "down" },
                  })
                }
              />
            </div>
            <TrashIcon
              className="w-6 h-6 text-red-400 dark:text-red-800"
              onClick={handleRemoveAverage}
            />
            <CheckIcon className="w-8 h-8" onClick={handleHideConfig} />
          </div>
        ) : (
          <Cog6ToothIcon
            className="w-8 h-8"
            onClick={() => setShowConfig(true)}
          />
        )}
      </div>
      {showConfig && (
        <div className="w-full flex flex-row items-center space-x-4 pt-2 mb-4">
          <p className="text-sm">Data point</p>
          <Select
            value={selectedDataPoint}
            onChange={(e) =>
              e.currentTarget.value !== "-1" &&
              setSelectedDataPoint(e.currentTarget.value)
            }
            className="w-64"
          >
            <option value="-1">Choose...</option>
            {dataPointOptions.map((dataPoint) => {
              const label = dataPoints.data!.find(
                (dp) => dp.id === dataPoint
              )?.name;

              if (label) {
                return <option value={dataPoint}>{label}</option>;
              } else {
                return <></>;
              }
            })}
          </Select>
          <Button
            className="h-[42px]"
            disabled={selectedDataPoint === "-1"}
            onClick={() =>
              dispatch({
                type: "updateStatConfig",
                payload: {
                  id,
                  dataPointId: selectedDataPoint,
                  dataPointName: dataPoints.data!.find(
                    (dp) => dp.id === selectedDataPoint
                  )?.name!,
                },
              })
            }
          >
            <CheckIcon className="w-5 h-5" />
          </Button>
        </div>
      )}
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="text-center space-y-2 my-8">
            <LoadingWheel />
            <p>This may take a while</p>
          </div>
        </div>
      ) : noDataPoint ? (
        <div className="h-[200px] flex items-center justify-center">
          <p>Choose a Data point to get started</p>
        </div>
      ) : (
        <div>
          <p
            className={clsx(
              "text-5xl",
              !value?.hasValue && "text-gray-400 dark:text-gray-600"
            )}
          >
            {value?.hasValue
              ? `${
                  datapoint?.precision
                    ? value.value!.toFixed(datapoint.precision)
                    : value.value!.toFixed(2)
                } ${datapoint?.unit ? datapoint.unit : ""}`
              : "Unavailable"}
          </p>
        </div>
      )}
    </Card>
  );
}
