import { CookieSetOptions } from "universal-cookie";

export const isLocal =
  window.location.host.includes("localhost") ||
  window.location.host.includes("127.0.0.1");
export const isProd = process.env.REACT_APP_RUN_ENV === "PROD";

export const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_URL;
export const authAPIURL = process.env.REACT_APP_AUTH_API_URL;
export const CDNURL = process.env.REACT_APP_CDN_URL;
export const fingotiCDNURL = process.env.REACT_APP_FINGOTI_CDN_URL;

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const stripePricingTable = process.env.REACT_APP_STRIPE_PRICING_TABLE;

export const applicationId =
  process.env.REACT_APP_FINGOTI_APPLICATION_ID ??
  "39853a77-4b6f-481d-9ec9-654ce8b06bb2";
export const pdOrgId =
  process.env.REACT_APP_PD_ORG_ID ?? "800cd8d4-a4af-4f8e-932e-5b48ec299330";

const cookieExpire = new Date();

cookieExpire.setMonth(cookieExpire.getMonth() + 1);

export const cookieSettings: CookieSetOptions = isLocal
  ? { sameSite: "lax", secure: false, expires: cookieExpire }
  : { sameSite: "lax", secure: true, expires: cookieExpire };
