import clsx from "clsx";

import ThemeColorOptions from "../../types/system/ThemeColorOption";
import React from "react";

interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  fullWidth?: boolean;
  small?: boolean;
  color?: ThemeColorOptions;
  label?: string;
}

export const TextField = React.forwardRef<any, TextFieldProps>(
  (
    {
      fullWidth = false,
      className,
      small = false,
      color = "primary",
      label,
      disabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className="flex flex-col space-y-1">
        {label && (
          <label className="text-sm text-black font-medium opacity-80 dark:text-white">
            {label}
          </label>
        )}
        <input
          className={clsx(
            "rounded border outline-none focus:drop-shadow-none focus:ring-2",
            fullWidth && "w-full",
            disabled
              ? "bg-gray-200 text-gray-800 dark:bg-gray-500 dark:text-gray-300 cursor-not-allowed"
              : "bg-white text-black dark:bg-gray-700 dark:text-white",
            color === "primary"
              ? "!border-primary focus:ring-primary"
              : "!border-secondary focus:ring-secondary",
            small ? "px-1 py-0.5" : "px-3 py-2",
            className,
          )}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
      </div>
    );
  },
);
