import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import date from "date-and-time";

import { Card } from "../../components/base/Card";
import { Table } from "../../components/base/Table";

import { usePageTitle } from "../../contexts/PageTitleContext";

import useAlertMutations from "../../data/alerts/useAlertMutations";

import { Button } from "../../components/base/Button";
// import { PencilIcon } from "@heroicons/react/24/outline";
import { AlertSeverity, IAlert } from "../../types/Alerts/Alerts";
import { CreateEditAlert } from "../../components/Alerts/CreateEditAlert";
import {
  TrashIcon,
  ArrowUturnDownIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { ConfirmDelete } from "../../components/shared/ConfirmDelete";
import { AlertIcon } from "../../components/shared/AlertIcon";

const colHelper = createColumnHelper<IAlert>();

export function AlertsPage() {
  const { setPageTitle } = usePageTitle();
  const { delAlert, updateAlert } = useAlertMutations();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [editAlert, setEditAlert] = useState<IAlert | undefined>(undefined);
  const [deleteAlert, setDeleteAlert] = useState<IAlert | undefined>(undefined);

  // const handleEditAlert = (alert: IAlert) => {
  //   setEditAlert(alert);
  //   setOpen(true);
  // };

  const handleToggleAcknowledge = (alert: IAlert) => {
    updateAlert.mutate({ ...alert, acknowledged: !alert.acknowledged });
  };

  const handleDeleteAlert = (alert: IAlert) => {
    setDeleteAlert(alert);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteAlert = () => {
    delAlert.mutate(deleteAlert!);
    setDeleteOpen(false);
  };

  const handleRestoreAlert = (alert: IAlert) => {
    updateAlert.mutate({ ...alert, restore: true });
  };

  const cols = [
    colHelper.display({
      header: "Severity",
      cell: (info) => <AlertIcon severity={info.row.original.severity} />,
    }),
    colHelper.accessor("title", { header: "Title" }),
    colHelper.accessor("description", { header: "Description" }),
    colHelper.accessor("createdOn", {
      header: "Created",
      cell: (info) =>
        date.format(new Date(info.getValue()), "DD/MM/YYYY HH:mm"),
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreAlert(info.row.original)}
            />
          ) : (
            <>
              {info.cell.row.original.acknowledged ? (
                <EnvelopeOpenIcon
                  className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                  onClick={() => handleToggleAcknowledge(info.row.original)}
                />
              ) : (
                <EnvelopeIcon
                  className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                  onClick={() => handleToggleAcknowledge(info.row.original)}
                />
              )}
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteAlert(info.row.original)}
              />
              {info.cell.row.original.severity === AlertSeverity.Error && (
                <a href="error">
                  <WrenchScrewdriverIcon className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer" />
                </a>
              )}
            </>
          )}
        </div>
        // <PencilIcon
        //   className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
        //   onClick={() => handleEditAlert(info.row.original)}
        // />
      ),
    }),
  ];

  useEffect(() => {
    setPageTitle("Alerts");
  }, [setPageTitle]);

  return (
    <>
      <CreateEditAlert open={open} onClose={setOpen} alert={undefined} />
      <ConfirmDelete
        itemName={deleteAlert?.title}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteAlert()}
      />
      <Card title="All Alerts">
        <div className="my-6">
          <Button onClick={() => setOpen(true)}>Add Alert</Button>
        </div>
        <div>
          <Table<IAlert>
            url="/alert"
            queryKey={["alert"]}
            columns={cols}
            //enableShowDeleted
          />
        </div>
      </Card>
    </>
  );
}
