import React, { useState } from "react";

import { LoadingWheel } from "../base/LoadingWheel";
import { FormSelect } from "../base/Form/FormSelect";
import useHardwareModels from "../../data/hardware/useHardwareModel";
import clsx from "clsx";

export interface FormModelSelectProps {
  manufacturer: string;
  label?: string;
}

export const FormModelSelect = React.forwardRef<any, FormModelSelectProps>(
  ({ manufacturer, label, ...rest }, ref) => {
    const { hardwareModels } = useHardwareModels(manufacturer);
    const [open, setOpen] = useState(false);

    if (hardwareModels.isLoading) {
      return (
        <div className="h-full flex items-center justify-center">
          <LoadingWheel size="sm" />
        </div>
      );
    } else {
      return (
        <div
          className="relative"
          onMouseEnter={!manufacturer ? () => setOpen(true) : () => {}}
          onMouseLeave={!manufacturer ? () => setOpen(false) : () => {}}
        >
          <FormSelect
            disabled={!manufacturer}
            ref={ref}
            label={label ? label : "Model"}
            {...rest}
          >
            <option disabled selected hidden></option>
            {hardwareModels.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </FormSelect>
          <div
            className={clsx(
              open ? "block " : "hidden ",
              "absolute z-20 left-6 mt-2 top-16 px-3 py-2 rounded-md border border-red-500 bg-red-300/90 dark:border-red-700 dark:bg-red-600/90",
            )}
          >
            You must choose a Manufacturer first
          </div>
        </div>
      );
    }
  },
);
