import { useEffect, useState } from "react";

import date from "date-and-time";

import { createColumnHelper } from "@tanstack/react-table";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { Card } from "../../components/base/Card";
import { Table } from "../../components/base/Table";
import { Button } from "../../components/base/Button";
import { IHardware } from "../../types/Hardware/Hardware";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { useHardwareLookups } from "../../hooks/useHardwareLookups";
import { ConfirmDelete } from "../../components/shared/ConfirmDelete";
import { SectionLoading } from "../../components/shared/SectionLoading";
import useHardwareMutations from "../../data/hardware/useHardwareMutations";
import { CreateEditHardware } from "../../components/Hardware/CreateEditHardware";
import { SubscriptionTier } from "../../types/Billing/Subscription";

const colHelper = createColumnHelper<IHardware>();

export function HardwarePage() {
  const { setPageTitle } = usePageTitle();
  const { delHardware } = useHardwareMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editHardware, setEditHardware] = useState<IHardware | undefined>(
    undefined,
  );
  const [deleteHardware, setDeleteHardware] = useState<IHardware | undefined>(
    undefined,
  );
  const { isLoading, manufacturerLookup, modelLookup } = useHardwareLookups();

  const handleDeleteHardware = (hardware: IHardware) => {
    setDeleteHardware(hardware);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteHardware = () => {
    delHardware.mutate({
      ...deleteHardware!,
    });
    setDeleteOpen(false);
  };

  const handleEditHardware = (hardware: IHardware) => {
    setEditHardware(hardware);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editHardware && setTimeout(() => setEditHardware(undefined), 200);
    setEditOpen(false);
  };

  useEffect(() => {
    setPageTitle("Manage Devices");
  }, [setPageTitle]);

  const cols = [
    colHelper.accessor("name", { header: "Device Name" }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" },
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => Object.values(SubscriptionTier)[row.tier], {
      header: "Subscription",
    }),
    colHelper.accessor("installedDate", {
      header: "Install Date",
      cell: (info) =>
        !info.getValue() ? (
          <span className="opacity-70 dark:text-white italic">not set</span>
        ) : (
          date.format(new Date(info.getValue()), "DD/MM/YYYY")
        ),
    }),
    colHelper.accessor("serviceDue", {
      header: "Service Due",
      cell: (info) =>
        !info.getValue() ? (
          <span className="opacity-70 dark:text-white italic">not set</span>
        ) : (
          Math.round(
            (new Date(info.getValue()).getTime() - Date.now()) /
              (1000 * 60 * 60 * 24),
          ) + " days"
        ),
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          <PencilIcon
            className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
            onClick={() => handleEditHardware(info.row.original)}
          />
          <TrashIcon
            className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
            onClick={() => handleDeleteHardware(info.row.original)}
          />
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditHardware
        open={editOpen}
        onClose={handleCreateEditClose}
        hardware={editHardware}
      />
      <ConfirmDelete
        itemName={deleteHardware?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteHardware()}
      />
      <Card title="All Devices">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>
            Install an Atmo device
          </Button>
        </div>
        {isLoading ? (
          <SectionLoading />
        ) : (
          <div>
            <Table<IHardware>
              url="/hardware"
              queryKey={["hardware"]}
              columns={cols}
            />
          </div>
        )}
      </Card>
    </>
  );
}
