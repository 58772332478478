import { useEffect, useState } from "react";

import { Select } from "../../base/Select";
import { LoadingWheel } from "../../base/LoadingWheel";
import { useHardwareLookups } from "../../../hooks/useHardwareLookups";
import { ITemplateConfiguration } from "../../../types/Template/Template";

interface TemplateSelectProps {
  templates: ITemplateConfiguration[];
  onChange: (template: ITemplateConfiguration) => void;
}

export function TemplateSelect({ templates, onChange }: TemplateSelectProps) {
  const {
    manufacturerLookup,
    modelLookup,
    isLoading: isLookupsLoading,
  } = useHardwareLookups();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(
    templates.length > 0 ? templates[0].id : "no-templates"
  );

  const handleTemplateSelect = (e: React.FormEvent<HTMLSelectElement>) => {
    setSelectedTemplateId(e.currentTarget.value);
    onChange(templates.find((t) => t.id === e.currentTarget.value)!);
  };

  useEffect(() => {
    if (templates.length > 0) {
      onChange(templates[0]);
    }
  }, [onChange, templates]);

  return isLookupsLoading ? (
    <div className="flex items-center justify-center py-10">
      <LoadingWheel />
    </div>
  ) : (
    <div className="grid gird-cols-2 gap-4">
      <Select
        label="Template"
        value={selectedTemplateId}
        onChange={handleTemplateSelect}
      >
        {templates.length === 0 ? (
          <option value="no-templates" disabled>
            No templates available
          </option>
        ) : (
          templates.map((template) => (
            <option key={template.id} value={template.id}>
              {
                manufacturerLookup(template.template.hardwareManufacturerId)
                  ?.name
              }{" "}
              - {modelLookup(template.template.hardwareModelId)?.name} -{" "}
              {template.template.name}
            </option>
          ))
        )}
      </Select>
    </div>
  );
}
