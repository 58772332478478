import { SubscriptionTier } from "../Billing/Subscription";
import { IBaseModel } from "../system/BaseModel";

export interface IHardwareCreateUpdateFormFields
  extends Omit<
    IHardwareCreateRequest,
    | "hasCtClamp"
    | "hasVoltMeter"
    | "managedBy"
    | "measuredVoltage"
    | "tier"
    | "heatPumpSettings"
    | "serialSettings"
    | "peripheralSettings"
    | "modbusSettings"
    | "powerSettings"
  > {
  installedDate: string;
  serviceDue: string;
  modbusSettings: {
    protocol?: string;
    rtuAddress?: string;
    ipAddress?: string;
    port?: string;
  };
  heatPumpSettings: {
    assumedFlowRate?: string;
    antifreeze?: string;
  };
  serialSettings: {
    baudRate: string;
    dataBits: string;
    parity: string;
    stopBits: string;
  };
  powerSettings: {
    measuredVoltage: string;
    phase: string;
    frequency: string;
  };
  peripheralSettings: {
    ctClamp: {
      enabled: boolean;
      offset: string;
      scale: string;
      current: string;
      resolution: string;
    };
    voltmeter: {
      enabled: boolean;
      offset: string;
      scale: string;
    };
    temperatureSensor: {
      enabled: boolean;
      offset: string;
      scale: string;
      location: string;
    };
  };
}

export interface IHardwareUpdateRequest
  extends Omit<IHardwareCreateRequest, "installedDate"> {}

export interface IHardwareCreateRequest
  extends Omit<
    IHardware,
    | "organisationId"
    | "deviceId"
    | "installedDate"
    | "serviceDue"
    | "subscriptionId"
    | "serialSettings"
  > {
  deviceId?: string;
  installedDate?: string;
  serviceDue?: string;
  subscriptionId?: string;
  useDefaultSerialSettings: boolean;
  serialSettings?: {
    baudRate: number;
    dataBits: number;
    parity: number;
    stopBits: number;
  };
}

export interface IHardwareDeleteRequest extends IHardware {}

export interface IHardware extends IBaseModel {
  organisationId: string;
  name: string;
  reference?: string;
  deviceId?: string;
  installedDate: string;
  serviceDue: string;
  hardwareTypeId: string;
  hardwareManufacturerId: string;
  hardwareModelId: string;

  subscriptionId?: string;
  tier: SubscriptionTier;

  serialSettings: IHardwareSerialSettings;
  peripheralSettings: IHardwarePeripheralSettings;
  heatPumpSettings: IHardwareHeatpumpSettings;
  modbusSettings: IHardwareModbusSettings;
  powerSettings: IHardwarePowerSettings;

  managedBy?: string;
}

export interface IHardwareSerialSettings {
  baudRate: number;
  dataBits: number;
  parity: 0 | 1 | 2;
  stopBits: number;
}

export interface IHardwareSelection {
  id: string;
  name: string;
}

export interface IHardwareType extends IBaseModel {
  disabled: boolean;
  name: string;
  restore?: boolean;
}

export interface IHardwareManufacturer extends IBaseModel {
  disabled: boolean;
  name: string;
  hardwareTypeId: string;
  hardwareType: IHardwareType;
  restore?: boolean;
}

export interface IHardwareModel extends IBaseModel {
  disabled: boolean;
  name: string;
  hardwareManufacturerId: string;
  hardwareManufacturer: IHardwareManufacturer;
  hardwareTypeId: string;
  classification: HardwareModelClassification;
  hardwareType: IHardwareType;
  partial: boolean;
  serialSettings: IHardwareSerialSettings;
  address: number;
  restore?: boolean;
  endianness: HardwareModelEndianness;
}

export interface IHardwareModbusSettings {
  protocol: HardwareModbusProtocol;
  rtuAddress?: number;
  ipAddress?: string;
  port?: number;
}

export interface IHardwareHeatpumpSettings {
  assumedFlowRate?: number;
  antifreeze?: number;
}

export interface IHardwarePeripheralSettings {
  ctClamp: IHardwareCTClampConfig;
  voltmeter: IHardwareVoltMeterConfig;
  temperatureSensor: IHardwareTemperatureSensorConfig;
}

export interface IHardwareVoltMeterConfig extends IHardwarePeripheral {}

export interface IHardwareCTClampConfig extends IHardwarePeripheral {
  current: number;
  resolution: number;
}

export interface IHardwareTemperatureSensorConfig extends IHardwarePeripheral {
  location: HardwareTemperatureProbeLocation;
}

export interface IHardwarePowerSettings {
  phase: HardwarePowerPhase;
  frequency: number;
  measuredVoltage: number;
}

export interface IHardwarePeripheral {
  enabled: boolean;
  offset: number;
  scale: number;
}

export enum HardwarePowerPhase {
  SinglePhase = 1,
  ThreePhase = 3,
}

export enum HardwareTemperatureProbeLocation {
  Outdoor,
  Indoor,
}

export enum HardwareModbusProtocol {
  RTU,
  TCPIP,
}

export enum HardwareModelEndianness {
  MsbMsw,
  MsbLsw,
  LsbMsw,
  LsbLsw,
}

export enum HardwareModelClassification {
  None,
  Verified,
  Pilot,
  Preview,
  Tentative,
  Core,
}

export const HardwareModelClassificationColours = [
  undefined,
  "00C64A",
  "007BFF",
  "C6AD00",
  "C64200",
  "B760F9",
];

export const BaudRateOptions = [
  1200, 2400, 4800, 9600, 14400, 19200, 28800, 38400, 56000, 57600, 115200,
];

export const DataBitsOptions = [5, 6, 7, 8];

export const ParityOptions = [
  { key: "None", value: 0 },
  { key: "Odd", value: 1 },
  { key: "Even", value: 2 },
];

export const StopBitsOptions = [1, 2];
