import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { TextArea } from "../base/TextArea";
import { Button } from "../base/Button";

import { IAlert, AlertSeverity } from "../../types/Alerts/Alerts";
import useAlertMutations from "../../data/alerts/useAlertMutations";
import { Select } from "../base/Select";

export interface CreateEditAlertProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  alert?: IAlert;
}

export function CreateEditAlert({
  open,
  onClose,
  alert,
}: CreateEditAlertProps) {
  const { register, handleSubmit, reset } = useForm();
  const { createAlert, updateAlert } = useAlertMutations();

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createAlert.mutate({
      ...data,
      severity: parseInt(data.severity),
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateAlert.mutate({
      ...alert,
      ...data,
      severity: parseInt(data.severity),
    });
    handleClose();
  };

  const alertSeverities = [
    ...Object.values(AlertSeverity).filter((ct) => isNaN(Number(ct))),
  ];

  useEffect(() => {
    if (alert) {
      reset({
        title: alert.title,
        severity: alert.severity,
        description: alert.description,
      });
    } else {
      reset({});
    }
  }, [reset, alert]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${alert ? "Edit" : "Create"} Alert`}
      description={`Use this form to ${alert ? "edit" : "create"} an alert`}
    >
      <form
        onSubmit={handleSubmit(alert ? handleUpdate : handleCreate)}
        className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
      >
        <TextField {...register("title")} label="Title" />
        <Select label="Severity" {...register("severity")}>
          <option disabled selected hidden></option>
          {alertSeverities.map((severity, i) => (
            <option key={severity} value={i}>
              {String(severity)}
            </option>
          ))}
        </Select>
        <div className="sm:col-span-2">
          <TextArea rows={4} {...register("description")} label="Description" />
        </div>
        <div className="mt-6 sm:col-span-2 space-x-4">
          <Button type="submit">{alert ? "Update" : "Create"}</Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
}
