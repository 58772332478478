import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { ISimpleDataPoint } from "../../types/DataPoint/DataPoint";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useDataPointsByHardwareModel(hardwareModelId?: string) {
  const { get } = useApiHelper();

  const dataPoints = useQuery({
    queryKey: ["reading", "datapoints", "byModelId", hardwareModelId],
    queryFn: () =>
      get<IDefaultResponseWithData<ISimpleDataPoint>>(
        `/v1/hardware/datapoints/${hardwareModelId}`
      ).then((res) => res.data.data),
  });

  return {
    dataPoints,
  };
}
