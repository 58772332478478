import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IHardware } from "../../types/Hardware/Hardware";

export default function useHardware() {
  const { get } = useApiHelper();

  const hardware = useQuery({
    queryKey: ["hardware"],
    queryFn: () =>
      get<IDefaultResponseWithData<IHardware>>("/v1/hardware").then(
        (res) => res.data.data,
      ),
  });

  return {
    hardware,
  };
}
