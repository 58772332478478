import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from "react";
import clsx from "clsx";

import ThemeColorOptions from "../../types/system/ThemeColorOption";
import SizeOptions from "../../types/system/SizeOption";

export interface ButtonProps {
  children?: ReactNode;
  type?: "submit" | "reset" | "button";
  className?: string;
  fullWidth?: boolean;
  variant?: "contained" | "outlined";
  color?: ThemeColorOptions;
  size?: SizeOptions;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export function Button({
  children,
  className,
  type = "button",
  fullWidth = false,
  variant = "contained",
  color = "primary",
  size = "md",
  onClick,
  disabled = false,
}: ButtonProps) {
  let sizeClass: String;
  let styleClass: String;

  switch (size) {
    case "xs":
      sizeClass = "px-1 py-0.5 text-xs";
      break;
    case "sm":
      sizeClass = "px-2 py-1 text-sm";
      break;
    case "lg":
      sizeClass = "px-3.5 py-2.5 text-md";
      break;
    default:
      sizeClass = "px-3 py-2 text-sm";
      break;
  }

  if (variant === "contained" && color === "secondary") {
    styleClass =
      "bg-secondary/80 text-secondaryContrast border border-secondary/80 hover:bg-secondary hover:border-secondary active:border-secondary/90 active:bg-secondary/90";
  } else if (variant === "outlined" && color === "primary") {
    styleClass =
      "border border-primary text-black hover:bg-primary/20 active:bg-primary/10 dark:text-white";
  } else if (variant === "outlined" && color === "secondary") {
    styleClass =
      "border border-secondary text-secondaryContrast hover:bg-secondary/20 active:bg-secondary/10 dark:text-white";
  } else {
    styleClass =
      "bg-primary/80 text-primaryContrast border border-primary/80 hover:bg-primary hover:border-primary active:border-primary/90 active:bg-primary/90";
  }

  return (
    <button
      type={type}
      onClick={disabled ? () => {} : onClick}
      className={clsx(
        "rounded",
        fullWidth && "w-full",
        sizeClass,
        styleClass,
        className,
        disabled &&
          "!bg-gray-200 !border-gray-200 !text-gray-400 dark:!bg-gray-400 dark:!border-gray-400 dark:!text-gray-600",
      )}
    >
      {children}
    </button>
  );
}
