interface SeperatorProps {
  text?: string;
  vertical?: boolean;
}

export function Seperator({ text = "OR", vertical = false }: SeperatorProps) {
  if (text && text !== "") {
    return (
      <div className="py-4 flex items-center text-sm text-gray-800 before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:before:border-gray-600 dark:after:border-gray-600">
        {text}
      </div>
    );
  } else {
    return (
      <div className="my-4 border-t border-gray-200 dark:border-gray-600"></div>
    );
  }
}
