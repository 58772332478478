import { IBaseModel } from "../system/BaseModel";
import { ConfigurationType } from "../Configuration/Configuration";

export enum TileVisability {
  hide,
  show,
  exclude,
}

export interface ISingleTile {
  dataPointId: string;
  visability: TileVisability;
  control: boolean;
}

export interface IViewBasedOn {
  ownerId: string;
  templateId: string;
}

export interface IView {
  hardwareId: string;
  basedOn: IViewBasedOn;
  tiles: ISingleTile[];
}

export interface IViewConfiguration extends IBaseModel {
  type: ConfigurationType.tile;
  ownerId: string;
  view: IView;
}
