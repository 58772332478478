import { useNavigate } from "react-router";

import { Card } from "../base/Card";
import { Button } from "../base/Button";
import { MiniTable } from "../base/MiniTable";
import useJobs from "../../data/job/useJobs";
import { SectionLoading } from "../shared/SectionLoading";
import { JobStatus } from "../../types/Job/Job";
import useCustomers from "../../data/customer/useCustomers";
import { ICustomer } from "../../types/Customer/Customer";
import { useCategoryLookups } from "../../hooks/useCategoryLookups";

interface JobRowProps {
  type: string;
  customer: ICustomer | undefined;
  jobText: string;
  // status: "upcoming" | "active" | "finished";
  status: JobStatus;
}

function JobRow({ type, customer, jobText, status = 0 }: JobRowProps) {
  let statusText = "Upcoming";
  let statusClass = "text-blue-700";

  switch (status) {
    case 2:
      statusText = "Finished";
      statusClass = "text-gray-700";
      break;
    case 0:
      statusText = "Active";
      statusClass = "text-green-700";
      break;
    case 1:
    default:
      statusText = "Upcoming";
      statusClass = "text-blue-700";
      break;
  }

  return (
    <div className="flex flex-col px-4 py-3 bg-gray-400/10 hover:bg-gray-400/20 hover:cursor-pointer dark:bg-white/10 dark:hover:bg-white/20">
      <div>
        <span className={`font-medium ${statusClass}`}>{statusText}</span> |{" "}
        <span className="font-medium">{type}</span>{" "}
        {customer && `- ${customer.organisationName}`}
      </div>
      {jobText}
    </div>
  );
}

export function Jobs() {
  const navigate = useNavigate();
  const { jobs } = useJobs();
  const { customers } = useCustomers();
  const { categoryLookup, isLoading: isCategoryLookupLoading } =
    useCategoryLookups();

  return (
    <Card title="Jobs">
      <div className="mt-4 space-y-6">
        <div>
          <Button>Create Job</Button>
        </div>
        <div>
          <h4 className="mb-2 font-medium text-lg">Active Jobs</h4>
          {jobs.isLoading || customers.isLoading || isCategoryLookupLoading ? (
            <SectionLoading />
          ) : (
            <MiniTable>
              {jobs.data
                ?.slice(0, 4)
                .map((job) => (
                  <JobRow
                    type={categoryLookup(job.categoryId)?.name!}
                    customer={customers.data?.find(
                      (x) => (x.id = job.customerId),
                    )}
                    jobText={job.title}
                    status={job.status}
                  />
                ))}
            </MiniTable>
          )}
        </div>
        <div>
          <Button onClick={() => navigate("jobs")}>View All</Button>
        </div>
      </div>
    </Card>
  );
}
