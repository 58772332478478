import { useState } from "react";

import { Button } from "../../base/Button";
import { Form } from "../../base/Form/Form";
import { LoadingWheel } from "../../base/LoadingWheel";
import { FormErrors } from "../../base/Form/FormErrors";
import { FormSelect } from "../../base/Form/FormSelect";
import { FormTextField } from "../../base/Form/FormTextField";
import { useUserContext } from "../../../contexts/UserContext";
import { FormModelSelect } from "../../Hardware/FormModelSelect";
import useHardwareTypes from "../../../data/hardware/useHardwareTypes";
import { FormManufacturerSelect } from "../../Hardware/FormManufacturerSelect";
import { ConfigurationType } from "../../../types/Configuration/Configuration";
import useInstallerTemplateConfigurationsMutations from "../../../data/configuration/useInstallerTemplateConfigurationsMutations";
import { ITemplateConfiguration } from "../../../types/Template/Template";

interface TemplateConfigFormFields {
  hardwareTypeId: string;
  hardwareManufacturerId: string;
  hardwareModelId: string;
  name: string;
}

interface CreateTemplateHardwareInformationProps {
  onTemplateCreate: (template: ITemplateConfiguration) => void;
}

export function CreateTemplateHardwareInformation({
  onTemplateCreate,
}: CreateTemplateHardwareInformationProps) {
  const { organisationId } = useUserContext();
  const { hardwareTypes } = useHardwareTypes();
  const { createTemplate } = useInstallerTemplateConfigurationsMutations();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (data: TemplateConfigFormFields) => {
    setSubmitting(true);

    createTemplate.mutate(
      {
        type: ConfigurationType.template,
        ownerId: organisationId!,
        template: {
          ...data,
          managedBy: organisationId!,
          default: true,
          tiles: [],
        },
      },
      {
        onSuccess: (template) => {
          onTemplateCreate(template[0]);
          setSubmitting(false);
        },
      }
      //TODO: ERROR HANDLING
    );
  };

  return (
    <>
      {submitting ? (
        <div className="flex py-10 justify-center items-start">
          <LoadingWheel />
        </div>
      ) : (
        <div className="">
          <p className="text-lg">Create Template</p>
          <Form<TemplateConfigFormFields> onSubmit={handleSubmit}>
            {({ register, watch }) => (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="md:col-span-2">
                  <FormErrors />
                </div>
                <FormSelect
                  {...register("hardwareTypeId", {
                    required: true,
                  })}
                  label="Type of Renewable Technology"
                >
                  <option disabled hidden value={undefined}>
                    Please Select
                  </option>
                  {hardwareTypes.data?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </FormSelect>
                <fieldset disabled={!watch("hardwareTypeId")}>
                  <FormManufacturerSelect
                    {...register("hardwareManufacturerId", {
                      required: true,
                    })}
                    type={watch("hardwareTypeId")}
                  />
                </fieldset>
                <fieldset disabled={!watch("hardwareManufacturerId")}>
                  <FormModelSelect
                    {...register("hardwareModelId", {
                      required: true,
                    })}
                    manufacturer={watch("hardwareManufacturerId")}
                  />
                </fieldset>
                <FormTextField
                  {...register("name", { required: true })}
                  label="Template Name"
                />
                <div className="mt-6 md:col-span-2">
                  <Button fullWidth type="submit">
                    Create
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      )}
    </>
  );
}
