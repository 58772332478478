import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IDevice } from "../../types/Device/Device";

export default function useDevice(deviceId: string) {
  const { get } = useApiHelper();

  const device = useQuery({
    queryKey: ["devices", deviceId],
    queryFn: () =>
      get<IDefaultResponseWithData<IDevice>>(`/v1/device/${deviceId}`).then(
        (res) => res.data.data,
      ),
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });

  return {
    device,
  };
}
