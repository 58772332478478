import { useEffect, useState } from "react";

import { Card } from "../../components/base/Card";
import useHardware from "../../data/hardware/useHardware";
import { IHardware } from "./../../types/Hardware/Hardware";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { ReadingsDisplay } from "../../components/LiveView/ReadingsDisplay";
import { PendingActionContextProvider } from "../../contexts/PendingActionContext";
import { useHardwareLookups } from "../../hooks/useHardwareLookups";
import { LiveReadingContextProvider } from "../../contexts/LiveReadingContext";
import { useUserContext } from "../../contexts/UserContext";
import { Seperator } from "../../components/base/Seperator";
import { ITemplateConfiguration } from "../../types/Template/Template";
import { HardwareSettings } from "../../components/LiveView/LiveViewSettings/HardwareSettings";
import { ViewSettings } from "../../components/LiveView/LiveViewSettings/ViewSettings";

export function LiveViewPage() {
  const { setPageTitle } = usePageTitle();
  const { isLoading } = useHardwareLookups();
  const { loading: userLoading } = useUserContext();
  const { hardware: allHardware } = useHardware();
  const [selectedHardware, setSelectedHardware] = useState<
    IHardware | undefined
  >(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    ITemplateConfiguration | undefined
  >(undefined);
  const [noHardware, setNoHardware] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [editViewOpen, setEditViewOpen] = useState(false);

  const handleHardwareChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const hardwareId = e.currentTarget.value;
    const foundHardware = allHardware.data?.find(
      (hardware) => hardware.id === hardwareId
    );

    setSelectedHardware(foundHardware);
  };

  useEffect(() => {
    setPageTitle("Live Readings", false);
  }, [setPageTitle]);

  useEffect(() => {
    if (!allHardware.isLoading && allHardware.data?.length) {
      if (!selectedHardware) {
        setSelectedHardware(allHardware.data[0]);
      }
    } else if (!allHardware.isLoading) {
      setNoHardware(true);
    }
  }, [selectedHardware, allHardware.isLoading, allHardware.data]);

  return noHardware ? (
    <Card>
      <div className="h-24 flex items-center justify-center">
        <p>No Atmo devices registered to this account</p>
      </div>
    </Card>
  ) : !selectedHardware || isLoading || userLoading ? (
    <SectionLoading />
  ) : (
    <PendingActionContextProvider hardwareId={selectedHardware.id}>
      <LiveReadingContextProvider hardwareId={selectedHardware.id}>
        <Card className="mb-6">
          <HardwareSettings
            hardware={allHardware.data!}
            selectedHardware={selectedHardware}
            onHardwareChange={handleHardwareChange}
          />
          <Seperator text="" />
          <ViewSettings
            selectedHardware={selectedHardware}
            tableView={tableView}
            setTableView={setTableView}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </Card>
        <ReadingsDisplay
          selectedHardware={selectedHardware}
          selectedTemplate={selectedTemplate}
          tableView={tableView}
        />
      </LiveReadingContextProvider>
    </PendingActionContextProvider>
  );
}
