import { useNavigate } from "react-router";

import { Card } from "../base/Card";
import { Button } from "../base/Button";
import { MiniTable } from "../base/MiniTable";
import { TextField } from "../base/TextField";
import useCustomers from "../../data/customer/useCustomers";
import { SectionLoading } from "../shared/SectionLoading";
import { useState, useEffect } from "react";
import { ICustomer } from "../../types/Customer/Customer";

interface CustomerRowProps {
  name: string;
  address1: string;
  address2: string;
  town: string;
  postcode: string;
}

function CustomerRow({
  name,
  address1,
  address2,
  town,
  postcode,
}: CustomerRowProps) {
  return (
    <div className="flex flex-col px-4 py-3 bg-gray-400/10 hover:bg-gray-400/20 hover:cursor-pointer dark:bg-white/10 dark:hover:bg-white/20">
      <span>{name}</span>
      <span className="text-gray-700 dark:text-gray-400">{`${address1}, ${address2 ? `${address2},` : ""} ${town}, ${postcode}`}</span>
    </div>
  );
}

export function Customers() {
  const navigate = useNavigate();
  const { customers } = useCustomers();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<ICustomer[] | undefined>(
    undefined,
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const results = customers.data?.filter(
      (customer) =>
        customer.organisationName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer.address.line1
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer.address.line2
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer.address.city
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer.address.postcode
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
    );
    setSearchResults(results);
  }, [customers.data, searchTerm]);

  return (
    <Card title="Customers">
      <div className="mt-4 space-y-6">
        <div>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
          />
        </div>
        <div>
          <h4 className="mb-2 font-medium text-lg">Recent Customers</h4>
          {customers.isLoading ? (
            <SectionLoading />
          ) : (
            <MiniTable>
              {searchResults
                ?.slice(0, 4)
                .map((customer) => (
                  <CustomerRow
                    name={customer.organisationName}
                    address1={customer.address.line1}
                    address2={customer.address.line2}
                    town={customer.address.city}
                    postcode={customer.address.postcode}
                  />
                ))}
            </MiniTable>
          )}
        </div>
        <div>
          <Button onClick={() => navigate("customers")}>View All</Button>
        </div>
      </div>
    </Card>
  );
}
