import { useLayoutEffect, useRef, useState } from "react";

import { DateTime, Interval } from "luxon";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { GUIDtoHEX } from "../../utils/GUIDtoHEX";
import { LoadingWheel } from "../base/LoadingWheel";
import { useTheme } from "../../contexts/ThemeContext";
import { IDataPoint, ISimpleDataPoint } from "../../types/DataPoint/DataPoint";
import { useGraphData } from "../../data/datapoint/useGraphData";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";
import {
  GranularityDuration,
  GraphBaseIntervalGranularity,
} from "../../types/system/GranularityOptions";
import useDataPointsByHardwareProfileId from "../../data/datapoint/useDataPointsByHardwareProfile";
import useDataPointsByHardwareModel from "../../data/datapoint/useDataPointsByHardwareModel";

interface LineGraphProps {
  title: string;
  identifier: string;
  dataPointIds: string[];
}

export function LineGraph({ title, identifier, dataPointIds }: LineGraphProps) {
  const { theme } = useTheme();
  const {
    currentValue: { hardware, timespan, granularity },
  } = useGraphSettings();
  const { getGraphData } = useGraphData();
  const { dataPoints } = useDataPointsByHardwareModel(
    hardware!.hardwareModelId
  );
  const [graphDataError, setGraphDataError] = useState(false);
  const [noReadings, setNoReadings] = useState(false);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef<am5.Root | null>(null);

  useLayoutEffect(() => {
    const drawGraph = async () => {
      const divId = `chartdiv-${dataPointIds[0]}-${identifier}`;
      const div = document.getElementById(divId);
      const hasCanvas = div?.querySelector("canvas");

      if (!hasCanvas) {
        setLoading(true);
        const graphData = await getGraphData({
          hardwareId: hardware!.id,
          timespan,
          granularity,
          datapoints: dataPointIds,
        });

        const hasData = graphData.some((gd) => gd.data.length > 0);

        if (!hasData) {
          setNoReadings(true);
          setLoading(false);
        } else {
          setNoReadings(false);
          setGraphDataError(false);

          // Dispose of the existing root if it exists
          if (chartRef.current) {
            chartRef.current.dispose();
            chartRef.current = null;
          }

          const root = am5.Root.new(divId);
          chartRef.current = root;

          root.setThemes([am5themes_Animated.new(root)]);

          if (theme.isDark) {
            root.interfaceColors.set("text", am5.color(0xffffff));
          } else {
            root.interfaceColors.set("text", am5.color(0x000000));
          }

          const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              paddingLeft: 0,
              paddingRight: 24,
              layout: root.verticalLayout,
              maxTooltipDistance: 0,
              pinchZoomY: false,
              pinchZoomX: false,
              wheelY: "none",
              wheelX: "none",
              wheelable: false,
              panX: false,
              panY: false,
            })
          );

          const xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
              baseInterval: GraphBaseIntervalGranularity[granularity],
              renderer: am5xy.AxisRendererX.new(root, {}),
              zoomY: false,
              zoomX: false,
            })
          );

          const addYAxis = (dataPoint: {
            unit: string;
            right?: boolean;
            dataPoints: ISimpleDataPoint[];
          }) => {
            const yRenderer = am5xy.AxisRendererY.new(root, {
              opposite: dataPoint.right === true,
            });

            const axis = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                autoZoom: false,
                extraTooltipPrecision: 1,
                numberFormat: `##${dataPoint.unit}`,
                renderer: yRenderer,
                zoomY: false,
                zoomX: false,
                panX: false,
                panY: false,
              })
            );

            yRenderer.labels.template.set("fill", am5.color(`#000`));
            yRenderer.labels.template.set("fontWeight", "bold");

            dataPoint.dataPoints.forEach((dp) => {
              const series = chart.series.push(
                am5xy.SmoothedXYLineSeries.new(root, {
                  connect: false,
                  name: dp.name,
                  xAxis: xAxis,
                  yAxis: axis,
                  tension: 0.8,
                  valueYField: "value",
                  valueXField: "timestamp",
                  tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY} {valueX}",
                  }),
                  fill: am5.color(`${GUIDtoHEX(dp.id)}`),
                  stroke: am5.color(`${GUIDtoHEX(dp.id)}`),
                })
              );

              series.strokes.template.set("strokeWidth", 2);

              series.bullets.push(() => {
                return am5.Bullet.new(root, {
                  sprite: am5.Circle.new(root, {
                    radius: 1,
                    fill: series.get("fill"),
                    strokeWidth: 0,
                  }),
                });
              });

              const readingsForDp = graphData.find(
                (gd) => gd.datapointId === dp.id
              )?.data;

              const data = readingsForDp!.map((reading) => ({
                timestamp: DateTime.fromISO(reading.timestamp, {
                  zone: "utc",
                }).toMillis(),
                value: reading.readingData[0]
                  ? reading.readingData[0].transformedValue
                  : null,
              }));

              if (readingsForDp && readingsForDp?.length > 0) {
                const firstReadingTimestamp = DateTime.fromFormat(
                  readingsForDp![0].timestamp,
                  "yyyy-MM-dd'T'HH:mm:ss'Z'",
                  { zone: "utc" }
                );

                const intervalEnd = timespan.startDate.plus(
                  GranularityDuration[granularity]
                );

                const interval = Interval.fromDateTimes(
                  timespan.startDate,
                  intervalEnd
                );

                if (!interval.contains(firstReadingTimestamp)) {
                  //Add a null to force graph length
                  data.push({
                    timestamp: new Date(timespan.startDate.toISO()!).getTime(),
                    value: null,
                  });
                }
              }

              series.data.setAll(data!);
            });
          };

          const graphConfigWithDataPointObjects = graphData.map((gd) => ({
            ...gd,
            dataPoint: dataPoints.data!.find((dp) => dp.id === gd.datapointId)!,
          }));

          const groupedByUnit = graphConfigWithDataPointObjects.reduce<
            { unit: string; right?: boolean; dataPoints: ISimpleDataPoint[] }[]
          >((acc, obj) => {
            const unit = obj.dataPoint.unit;
            const found = acc.findIndex((v) => v.unit === unit);

            if (found === -1) {
              acc.push({
                unit: unit,
                dataPoints: [obj.dataPoint],
              });
            } else {
              acc[found].dataPoints.push(obj.dataPoint);
            }

            return acc;
          }, []);

          groupedByUnit.forEach((group, index) =>
            addYAxis({ ...group, right: index % 2 !== 0 })
          );

          const legend = chart.children.push(
            am5.Legend.new(root, { paddingTop: 12, paddingBottom: 12 })
          );

          legend.data.setAll(chart.series.values);

          if (chartRef.current) {
            const color = theme.isDark ? 0xffffff : 0x000000;
            chartRef.current.interfaceColors.set("text", am5.color(color));
            chartRef.current.container.children.each((child) => {
              if (child instanceof am5xy.XYChart) {
                child.xAxes.each((axis) => {
                  axis
                    .get("renderer")
                    .labels.template.set("fill", am5.color(color));
                });
                child.yAxes.each((axis) => {
                  axis
                    .get("renderer")
                    .labels.template.set("fill", am5.color(color));
                });
              }
            });
          }

          setLoading(false);
        }
      }
    };

    if (!dataPoints.isLoading && dataPoints.data && dataPointIds.length > 0) {
      drawGraph();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }
    };
  }, [
    dataPoints.isLoading,
    dataPointIds,
    hardware,
    timespan,
    granularity,
    theme.isDark,
  ]);

  if (!dataPoints.isLoading) {
    if (dataPointIds.length === 0) {
      return (
        <div
          id={`chartdiv-${dataPointIds[0]}-${identifier}`}
          className="flex items-center justify-center"
          style={{ width: "100%", height: "200px" }}
        >
          <p>Add a Data point to the graph to get started</p>
        </div>
      );
    } else if (!graphDataError && !noReadings) {
      return (
        <div>
          <div
            id={`chartdiv-${dataPointIds[0]}-${identifier}`}
            style={{ width: "100%", height: "500px", fontSize: 12 }}
          >
            {loading && (
              <div className="h-full flex items-center justify-center">
                <div className="text-center space-y-2">
                  <LoadingWheel />
                  <p>This may take a while</p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (noReadings) {
      return (
        <>
          <div>
            <div
              id={`chartdiv-${dataPointIds[0]}-${identifier}`}
              className="flex items-center justify-center"
              style={{ width: "100%", height: "500px" }}
            >
              <p>No readings for selected time period</p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div
              id={`chartdiv-${dataPointIds[0]}-${identifier}`}
              className="flex items-center justify-center"
              style={{ width: "95%", height: "500px" }}
            >
              <p>Error getting graph data</p>
            </div>
          </div>
        </>
      );
    }
  } else {
    return (
      <div
        id={`chartdiv-${dataPointIds[0]}-${identifier}`}
        className="flex items-center justify-center"
        style={{ width: "100%", height: "200px" }}
      >
        <LoadingWheel />
      </div>
    );
  }
}
