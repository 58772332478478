import useMetadata from "../data/metadata/useMetadata";

type PeripheralType = "CtClamp" | "Voltmeter" | "Temperature";

type CalibrationField = "Offset" | "Scale";

export function useCalibrationDefaults() {
  const { defaultValues } = useMetadata();

  const getDefaultValue = (
    peripheral: PeripheralType,
    field: CalibrationField
  ) =>
    defaultValues.data?.find((d) => d.key === `${peripheral}:${field}`)
      ?.value ?? "0";

  return {
    isLoading: defaultValues.isLoading,
    getDefaultValue,
  };
}
