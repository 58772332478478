import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IHardwareSelection } from "../../types/Hardware/Hardware";

export default function useHardwareManufacturers(type?: string) {
  const { get } = useApiHelper();

  const hardwareManufacturers = useQuery({
    queryKey: ["hardware", "manufacturers", type],
    queryFn: () =>
      get<IDefaultResponseWithData<IHardwareSelection>>(
        `/v1/hardware/manufacturers${type !== undefined ? `/${type}` : ""}`,
      ).then((res) => res.data.data),
  });

  return {
    hardwareManufacturers,
  };
}
