import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { ITemplateDataPointsResponse } from "../../types/responses/datapoint/TemplateDataPointsResponse";
import useQSBuilder from "../../hooks/useQSBuilder";
import { dataPointQueryKeys } from "./QueryKey";

export default function useDataPointsByHardwareProfileId(
  hardwareId?: string,
  templateId?: string,
  organisationId?: string
) {
  const buildQs = useQSBuilder();
  const { get } = useApiHelper();

  const dataPoints = useQuery({
    queryKey: dataPointQueryKeys.byHardwareProfileId(
      hardwareId,
      templateId,
      organisationId
    ),
    queryFn: () =>
      get<IDefaultResponseWithData<ITemplateDataPointsResponse>>(
        `/v1/reading/${hardwareId}/datapoints${buildQs({ templateId, organisationId })}`
      ).then((res) => res.data.data),
  });

  return {
    dataPoints,
  };
}
