import { IBaseModel } from "../system/BaseModel";

export enum SubscriptionTier {
  None,
  Free,
  Standard,
}

export enum SubscriptionStatus {
  Pending,
  Trial,
  Active,
  Cancelled,
}

export interface ISubscriptionSeats {
  standard: number;
}

export interface ISubscription extends IBaseModel {
  status: SubscriptionStatus;
  ownerId: string;
  stripeId: string;
  renewalDate: string;
  seats: ISubscriptionSeats;
}
