import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";

import { IReading } from "../types/DataPoint/Reading";
import useReadings from "../data/datapoint/useReading";
import { latestReadingTimestampKey } from "../constants/common";

interface LiveReadingContextValue {
  latest?: IReading;
  latestTimestamp?: Date;
  lastUpdate?: IReading[];
  loading: boolean;
}

const LiveReadingContext = createContext<LiveReadingContextValue>({
  latest: undefined,
  latestTimestamp: undefined,
  lastUpdate: undefined,
  loading: true,
});

interface LiveReadingContextProviderProps {
  hardwareId: string;
  children: ReactElement | ReactElement[];
}

export function LiveReadingContextProvider({
  hardwareId,
  children,
}: LiveReadingContextProviderProps) {
  const { readings } = useReadings(hardwareId);
  const [latest, setLatest] = useState<IReading>();
  const [latestTimestamp, _setLatestTimestamp] = useState<Date>();
  const [lastUpdate, setLastUpdate] = useState<IReading[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const setLastestTimestamp = (ts?: string) => {
    if (ts) {
      sessionStorage.setItem(latestReadingTimestampKey, ts);
      _setLatestTimestamp(new Date(ts));
    } else {
      sessionStorage.removeItem(latestReadingTimestampKey);
      _setLatestTimestamp(undefined);
    }
  };

  const findNewestReading = (readings: IReading[]) =>
    readings.reduce((newestReading, currentReading) => {
      return currentReading.timestamp > newestReading.timestamp
        ? currentReading
        : newestReading;
    }, readings[0]);

  useEffect(() => {
    setLoading(true);
    setLastUpdate(undefined);
    setLatest(undefined);
    setLastestTimestamp(undefined);
  }, [hardwareId]);

  useEffect(() => {
    if (!readings.isLoading && readings.data) {
      if (readings.data.length > 0) {
        if (readings.data.length > 1) {
          let newestReading = findNewestReading(readings.data);

          setLastUpdate(readings.data);
          setLatest(newestReading);
          setLastestTimestamp(newestReading.timestamp);
        } else {
          setLastUpdate(readings.data);
          setLatest(readings.data[0]);
          setLastestTimestamp(readings.data[0].timestamp);
        }
      }

      setLoading(false);
    } else if (!readings.isLoading) {
      setLoading(false);
    }
  }, [readings.isLoading, readings.data]);

  useEffect(() => {
    return () => {
      readings.remove();
      setLastestTimestamp(undefined);
    };
  }, []);

  return (
    <LiveReadingContext.Provider
      value={{ latest, latestTimestamp, lastUpdate, loading }}
    >
      {children}
    </LiveReadingContext.Provider>
  );
}

export function useLiveReading() {
  const liveReadingCtx = useContext(LiveReadingContext);

  if (!liveReadingCtx) {
    throw Error(
      "useLiveReading() must be used within a LiveReadingContextProvider",
    );
  }

  return liveReadingCtx;
}
