import {
  useState,
  ReactElement,
  Dispatch,
  createContext,
  useContext,
  SetStateAction,
} from "react";

import { PredefinedTableFilterField, TableFilterField } from "./TableFilter";
import { TableFilterPillProps } from "./TableFilterPill";

interface TableFilterContextValue {
  selectedFilterField?: string;
  selectedFilterValue?: string;
  filters?: TableFilterPillProps[];
  setSelectedFilterField: Dispatch<SetStateAction<string | undefined>>;
  setSelectedFilterValue: Dispatch<SetStateAction<string>>;
  setFilters: Dispatch<SetStateAction<TableFilterPillProps[] | undefined>>;
  handleFilterAdd: VoidFunction;
  handleFilterRemove: (field: string) => void;
  getFilterQueryString: () => string;
}

const TableFilterContext = createContext<TableFilterContextValue>({
  selectedFilterField: undefined,
  selectedFilterValue: undefined,
  filters: undefined,
  setSelectedFilterField: () => {},
  setSelectedFilterValue: () => {},
  setFilters: () => {},
  handleFilterAdd: () => {},
  handleFilterRemove: () => {},
  getFilterQueryString: () => "",
});

interface TableFilterContextProviderProps {
  children: ReactElement | ReactElement[];
  filterFields: TableFilterField[] | undefined;
  predefinedFilterFields: PredefinedTableFilterField[] | undefined;
}

export function TableFilterContextProvider({
  children,
  filterFields,
  predefinedFilterFields,
}: TableFilterContextProviderProps) {
  const [selectedFilterField, setSelectedFilterField] = useState(
    filterFields ? filterFields[0].field : undefined,
  );
  const [selectedFilterValue, setSelectedFilterValue] = useState("");
  const [filters, setFilters] = useState<TableFilterPillProps[] | undefined>(
    predefinedFilterFields?.length && predefinedFilterFields.length > 0
      ? predefinedFilterFields.map(
          (preDefFilter): TableFilterPillProps => ({
            field: preDefFilter.field,
            fieldLabel: preDefFilter.fieldLabel,
            value: preDefFilter.value,
            valueLabel: preDefFilter.valueLabel,
          }),
        )
      : undefined,
  );

  const handleFilterAdd = () => {
    setFilters((oldFilters) => {
      if (Array.isArray(oldFilters)) {
        let exists = oldFilters.findIndex(
          (filter) => filter.field === selectedFilterField!,
        );
        if (exists !== -1) {
          oldFilters[exists] = {
            ...oldFilters[exists],
            value: selectedFilterValue!,
          };

          return [...oldFilters];
        } else {
          return [
            ...oldFilters,
            {
              field: selectedFilterField!,
              fieldLabel: filterFields?.find(
                (f) => f.field === selectedFilterField,
              )?.fieldLabel,
              value: selectedFilterValue!,
            },
          ];
        }
      } else {
        return [
          {
            field: selectedFilterField!,
            fieldLabel: filterFields?.find(
              (f) => f.field === selectedFilterField,
            )?.fieldLabel,
            value: selectedFilterValue!,
          },
        ];
      }
    });
  };

  const handleFilterRemove = (field: string) => {
    setFilters((oldFilters) => {
      if (Array.isArray(oldFilters)) {
        let filtered = oldFilters.filter((filter) => filter.field !== field);
        if (filtered.length === 0) {
          return undefined;
        } else {
          return filtered;
        }
      }

      return undefined;
    });
  };

  const getFilterQueryString = () => {
    let filterString = "";
    if (Array.isArray(filters)) {
      filters.forEach((filter) => {
        filterString = filterString + `&${filter.field}=${filter.value}`;
      });
      return filterString;
    } else {
      return filterString;
    }
  };

  return (
    <TableFilterContext.Provider
      value={{
        selectedFilterField,
        selectedFilterValue,
        filters,
        setSelectedFilterField,
        setSelectedFilterValue,
        setFilters,
        handleFilterAdd,
        handleFilterRemove,
        getFilterQueryString,
      }}
    >
      {children}
    </TableFilterContext.Provider>
  );
}

export function useTableFilter() {
  const pageTitleCtx = useContext(TableFilterContext);

  if (!pageTitleCtx) {
    throw Error(
      "useTableFilter() must be used within a TableFilterContextProvider",
    );
  }

  return pageTitleCtx;
}
