import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { subscriptionQueryKeys } from "./QueryKeys";
import { ISubscription } from "../../types/Billing/Subscription";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useSubscription() {
  const { get } = useApiHelper();

  const subscription = useQuery({
    queryKey: subscriptionQueryKeys.all(),
    queryFn: () =>
      get<IDefaultResponseWithData<ISubscription>>(
        "/v1/billing/subscription",
      ).then((res) => res.data.data),
  });

  return {
    subscription,
  };
}
