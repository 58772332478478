import { ReactNode } from "react";

import { UseFormRegisterReturn } from "react-hook-form";

import { FormTextField } from "../base/Form/FormTextField";

export interface ComponentCalibrationProps {
  offsetRegisterFn: UseFormRegisterReturn;
  scaleRegisterFn: UseFormRegisterReturn;
  offsetLabel: string;
  classes?: string;
  extraConfigComponent?: ReactNode;
}

export function ComponentCalibration({
  offsetRegisterFn,
  scaleRegisterFn,
  classes,
  extraConfigComponent,
  offsetLabel,
}: ComponentCalibrationProps) {
  return (
    <div className={classes}>
      <FormTextField {...offsetRegisterFn} label={offsetLabel} type="text" />
      <FormTextField {...scaleRegisterFn} label="Scale (%)" type="text" />
      {extraConfigComponent}
    </div>
  );
}
