import { useUserContext } from "../../../contexts/UserContext";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { Button } from "../../base/Button";
import { Seperator } from "../../base/Seperator";
import { CreateTemplateHardwareInformation } from "./CreateTemplateHardwareInformation";
import { ITemplateConfiguration } from "../../../types/Template/Template";
import { useState } from "react";
import { ImportTemplate } from "./ImportTemplate";

interface AddTemplateModalProps extends ModalControlProps {
  setEditTemplate: (template: ITemplateConfiguration) => void;
}

export function AddTemplateModal({
  setEditTemplate,
  open,
  onClose,
}: AddTemplateModalProps) {
  const { installerId, installerName } = useUserContext();
  const [showImport, setShowImport] = useState(false);

  const handleImportSuccess = (template: ITemplateConfiguration) => {
    setEditTemplate(template);
    setShowImport(false);
  };

  return (
    <Modal title="Add Template" open={open} onClose={onClose}>
      {showImport ? (
        <ImportTemplate setEditTemplate={handleImportSuccess} />
      ) : (
        <div className="space-y-6">
          <p>
            To get started creating a template we need to know the type, make
            and model of appliance you are intending to create the template for
          </p>
          {installerId && (
            <p>
              As you are logged in as an installer you can copy templates you
              have already setup to the customers account, this then defaults
              the view for the customer to this template and also allows you to
              modify the template per customer
            </p>
          )}
          {installerId && (
            <>
              <div className="space-y-4">
                <p className="text-lg">Import Template</p>
                <Button fullWidth onClick={() => setShowImport(true)}>
                  Import from {installerName}
                </Button>
              </div>
              <Seperator />
            </>
          )}
          <CreateTemplateHardwareInformation
            onTemplateCreate={setEditTemplate}
          />
        </div>
      )}
    </Modal>
  );
}
