import { Select } from "../Select";
import { TextField } from "../TextField";
import { Button } from "../Button";

import { TableFilterPill } from "./TableFilterPill";

import { useTableFilter } from "./TableFilterContext";

export interface TableFilterField {
  field: string;
  fieldLabel?: string;
}

export interface PredefinedTableFilterField extends TableFilterField {
  value: string;
  valueLabel?: string;
}

export interface TableFilterProps {
  filterFields?: TableFilterField[];
}

export function TableFilter({ filterFields }: TableFilterProps) {
  const {
    selectedFilterField,
    selectedFilterValue,
    filters,
    setSelectedFilterField,
    setSelectedFilterValue,
    handleFilterAdd,
    handleFilterRemove,
  } = useTableFilter();

  return (
    <div className="flex flex-col mb-4 space-y-4">
      <div>
        <h4 className="mb-2 text-lg font-medium">Filter</h4>
        {filterFields && (
          <div className="grid grid-cols-1 gap-y-2 lg:flex items-center">
            <Select
              value={selectedFilterField!}
              onChange={(e) => setSelectedFilterField(e.currentTarget.value)}
              className="lg:rounded-r-none"
            >
              {filterFields.map((filterKey, i) => (
                <option key={`filter-opt-${i}`} value={filterKey.field}>
                  {filterKey.fieldLabel}
                </option>
              ))}
            </Select>
            <div className="px-3 py-2 rounded border border-primary text-center bg-gray-200 text-gray-500 lg:border-l-0 lg:border-r-0 lg:border-t lg:border-b lg:rounded-none dark:bg-gray-600 dark:text-gray-400">
              CONTAINS
            </div>
            <TextField
              value={selectedFilterValue ?? ""}
              onChange={(e) => setSelectedFilterValue(e.currentTarget.value)}
              className="lg:rounded-none"
            />
            <Button
              size="lg"
              onClick={handleFilterAdd}
              className="h-[42px] lg:rounded-l-none"
            >
              Add
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-start">
        {filters?.map((filter, i) => (
          <span className="flex items-center">
            {i !== 0 && <span className="text-lg font-medium">&</span>}
            <TableFilterPill
              key={i}
              {...filter}
              onRemove={() => handleFilterRemove(filter.field)}
            />
          </span>
        ))}
      </div>
    </div>
  );
}
