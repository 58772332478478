import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useReducer,
} from "react";

import { IHardware } from "../types/Hardware/Hardware";
import GranularityOption from "../types/system/GranularityOptions";
import {
  IGraphTimespan,
  IPredefinedTimespan,
} from "../types/system/GraphTimespan";
import {
  getGranularities,
  getTimespanValues,
  normalizeDateTime,
  predefinedTimespans,
} from "../utils/graphUtils";

interface GraphSettingsContextValue {
  hardware?: IHardware;
  timespan: IGraphTimespan;
  granularity: GranularityOption;
  granularityOptions: GranularityOption[];
  dispatch: Dispatch<GraphSettingsReducerActions>;
  currentValue: Omit<
    GraphSettingsContextValue,
    "dispatch" | "granularityOptions" | "currentValue"
  >;
}

const initialContextValue = (): GraphSettingsContextValue => {
  const ts = getTimespanValues(predefinedTimespans[0].duration);
  const granularities = getGranularities(predefinedTimespans[0].duration);

  return {
    timespan: ts,
    granularity: granularities[0],
    granularityOptions: granularities,
    dispatch: () => {},
    currentValue: {
      hardware: undefined,
      timespan: ts,
      granularity: granularities[0],
    },
  };
};

const GraphSettingsContext = createContext<GraphSettingsContextValue>(
  initialContextValue(),
);

type GraphSettingsReducerActions =
  | { type: "setTimespan"; payload: IPredefinedTimespan | IGraphTimespan }
  | { type: "setGranularity"; payload: GranularityOption }
  | { type: "setHardware"; payload: IHardware }
  | { type: "setCurrent" };

function graphSettingsReducer(
  state: GraphSettingsContextValue,
  action: GraphSettingsReducerActions,
) {
  switch (action.type) {
    case "setTimespan":
      if (Object.keys(action.payload).includes("duration")) {
        // Was predefined
        const ts = action.payload as IPredefinedTimespan;

        let timespan = getTimespanValues(ts.duration);
        let granularityOptions = getGranularities(ts.duration);

        return {
          ...state,
          granularity: granularityOptions[0],
          granularityOptions,
          timespan,
        };
      } else {
        // Was custom
        const ts = action.payload as IGraphTimespan;

        const timespan = {
          startDate: normalizeDateTime(ts.startDate),
          endDate: normalizeDateTime(ts.endDate),
        };

        const duration = timespan.endDate.diff(timespan.startDate);

        let granularityOptions = getGranularities(duration);

        return {
          ...state,
          granularity: granularityOptions[0],
          granularityOptions,
          timespan,
        };
      }
    case "setGranularity":
      return { ...state, granularity: action.payload as GranularityOption };
    case "setHardware":
      return { ...state, hardware: action.payload as IHardware };
    case "setCurrent":
      return {
        ...state,
        currentValue: {
          hardware: state.hardware,
          timespan: state.timespan,
          granularity: state.granularity,
        },
      };
    default:
      return state;
  }
}

interface GraphSettingsContextProviderProps {
  children: ReactElement | ReactElement[];
}

export function GraphSettingsContextProvider({
  children,
}: GraphSettingsContextProviderProps) {
  const [
    { hardware, timespan, granularity, granularityOptions, currentValue },
    dispatch,
  ] = useReducer(graphSettingsReducer, initialContextValue());

  return (
    <GraphSettingsContext.Provider
      value={{
        hardware,
        timespan,
        granularity,
        granularityOptions,
        dispatch,
        currentValue,
      }}
    >
      {children}
    </GraphSettingsContext.Provider>
  );
}

export function useGraphSettings() {
  const settingsCtx = useContext(GraphSettingsContext);

  if (!settingsCtx) {
    throw Error(
      "useGraphSettings() must be used within a GraphSettingsContextProvider",
    );
  }

  const setTimespan = (timespan: IPredefinedTimespan | IGraphTimespan) =>
    settingsCtx.dispatch({ type: "setTimespan", payload: timespan });

  const setGranularity = (granularity: GranularityOption) =>
    settingsCtx.dispatch({ type: "setGranularity", payload: granularity });

  const setHardware = (hardware: IHardware) =>
    settingsCtx.dispatch({ type: "setHardware", payload: hardware });

  const setCurrentValue = () => settingsCtx.dispatch({ type: "setCurrent" });

  return {
    hardware: settingsCtx.hardware,
    timespan: settingsCtx.timespan,
    granularity: settingsCtx.granularity,
    granularityOptions: settingsCtx.granularityOptions,
    currentValue: settingsCtx.currentValue,
    setTimespan,
    setGranularity,
    setHardware,
    setCurrentValue,
  };
}
