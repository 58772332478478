import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleDot,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import { useUserContext } from "../../../contexts/UserContext";
import { IReadingError } from "../../../types/DataPoint/Reading";

const errorLookup = (code: number, group?: number) =>
  ({
    1: {
      shortDescription: "No response from hardware",
      explanation:
        "It is likely that you have incorrect serial settings configured for the Atmo device or you have an incorrect or no connection between the hardware and the Atmo device",
    },
    2: {
      shortDescription: "Data corruption",
      explanation:
        "It is likely that the cable connecting Atmo to your hardware is of poor quality or is damaged. It is also possible this was caused by interference",
    },
    3: group
      ? {
          shortDescription: "Modbus error",
          explanation: `Illegal use of Modbus function in instruction group ${group}`,
        }
      : {
          shortDescription: "Modbus error",
          explanation:
            "Illegal use of Modbus function in response to a read/write command",
        },
    4: group
      ? {
          shortDescription: "Modbus error",
          explanation: `Attempt to read illegal address in instruction group ${group}`,
        }
      : {
          shortDescription: "Modbus error",
          explanation:
            "Attempt to access illegal address in response to a read/write command",
        },
    5: {
      shortDescription: "Modbus error",
      explanation: "Illegal value written in response to a write command",
    },
    6: group
      ? {
          shortDescription: "Modbus error",
          explanation: `Other Modbus protocol error in instruction group ${group}`,
        }
      : {
          shortDescription: "Modbus error",
          explanation:
            "Other Modbus protocol error in response to a read/write command",
        },
    7: {
      shortDescription: "No instruction found",
      explanation:
        "The Atmo device has no instruction. a setup command is required",
    },
    8: {
      shortDescription: "Buffer overflow",
      explanation: "Too much data has been requested",
    },
  })[code];

interface DeviceStatusPopoverProps {
  open: boolean;
  connected: boolean;
  error?: IReadingError;
}

export function DeviceStatusPopover({
  open,
  connected,
  error,
}: DeviceStatusPopoverProps) {
  const { isSuperAdmin } = useUserContext();

  if (error && error.code > 0) {
    const errorDetails = errorLookup(error.code, error.group);

    return (
      <div
        className={clsx(
          open ? "block " : "hidden ",
          "absolute top-8 w-64 border py-2 px-4 rounded border-amber-500 bg-amber-300/90 dark:border-amber-700 dark:bg-amber-600/90",
        )}
      >
        <div>
          {isSuperAdmin ? (
            <>
              <p className="text-base font-medium">Error Code: {error.code}</p>
              <p>Group: {error.group ?? "-"}</p>
            </>
          ) : (
            <div className="flex flex-row items-center space-x-4">
              <FontAwesomeIcon icon={faCircleDot} />
              <p className="text-base font-medium">Error</p>
            </div>
          )}
          <div className="my-2 py-1 border-gray-900 dark:border-white/30 border-y">
            <p className="leading-tight">
              <span className="text-xs">Short description:</span>
              <br />
              {errorDetails!.shortDescription}
            </p>
          </div>
          <div className="-mt-0.5">
            <p className="leading-tight">{errorDetails!.explanation}</p>
          </div>
        </div>
      </div>
    );
  } else if (!connected) {
    return (
      <div
        className={clsx(
          open ? "block " : "hidden ",
          "absolute top-8 w-64 border py-2 px-4 rounded border-red-500 bg-red-300/90 dark:border-red-700 dark:bg-red-600/90",
        )}
      >
        <div>
          <div className="flex flex-row items-center space-x-4">
            <FontAwesomeIcon icon={faCircleXmark} />
            <p className="text-base font-medium">Offline</p>
          </div>
          <div className="mt-2 pt-1.5 border-gray-900 dark:border-white/30 border-t">
            <p className="leading-tight">
              The device is currently offline, please ensure it is powered on
              and connected to the internet
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={clsx(
          open ? "block " : "hidden ",
          "absolute top-8 w-64 border py-2 px-4 rounded border-green-500 bg-green-300/90 dark:border-green-700 dark:bg-green-600/90",
        )}
      >
        <div>
          <div className="pt-0.5 flex flex-row items-center space-x-4">
            <FontAwesomeIcon icon={faCircleCheck} />
            <p className="text-base font-medium">Online</p>
          </div>
          <div className="mt-2 pt-1.5 border-gray-900 dark:border-white/30 border-t">
            <p className="leading-tight">
              The device is online, operation normal
            </p>
          </div>
        </div>
      </div>
    );
  }
}
