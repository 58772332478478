import { IBaseModel } from "../system/BaseModel";

export interface IAlert extends IBaseModel {
  severity: AlertSeverity;
  title: string;
  description: string;
  customerId: string;
  acknowledged: boolean;
  restore?: boolean;
}

export enum AlertSeverity {
  Info,
  Success,
  Warning,
  Error,
}
