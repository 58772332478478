import { Modal } from "../../layouts/Modal";
import { Button } from "../base/Button";

export interface ConfirmDeleteProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmDefaultSerialSettings({
  open,
  onCancel,
  onConfirm,
}: ConfirmDeleteProps) {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Override Default Serial Settings"
      description="This option is intended for advanced use cases only. Overriding the default serial settings is not required in the majority of cases. By pressing Confirm, you accept responsibility for altering the Modbus interface and Atmo serial settings synchronously."
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      <div className="mt-6 space-x-4">
        <Button onClick={onConfirm}>Confirm</Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
