import { MouseEventHandler } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";

export interface TableFilterPillProps {
  field: string;
  fieldLabel?: string;
  value: string;
  valueLabel?: string;
  criteria?: string;
  onRemove?: MouseEventHandler<SVGSVGElement>;
}

export function TableFilterPill({
  field,
  fieldLabel,
  value,
  valueLabel,
  criteria = "=",
  onRemove,
}: TableFilterPillProps) {
  return (
    <div className="max-w-fit px-3 py-1 mx-2 my-1 flex items-center rounded-full bg-primary/30">
      {fieldLabel ? fieldLabel : field} {criteria}{" "}
      {valueLabel ? valueLabel : value}
      <XMarkIcon
        onClick={onRemove}
        className="ml-3 w-5 h-5 text-gray-700 dark:text-gray-200 hover:cursor-pointer hover:text-gray-900 hover:dark:text-white"
      />
    </div>
  );
}
