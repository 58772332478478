import useHardwareTypes from "../data/hardware/useHardwareTypes";
import useHardwareModels from "../data/hardware/useHardwareModel";
import useHardwareManufacturers from "../data/hardware/useHardwareManufacturers";

const useHardwareTypeLookup = () => {
  const { hardwareTypes } = useHardwareTypes();

  const lookup = (typeId: string) =>
    hardwareTypes.data?.find((m) => m.id === typeId);

  return {
    isLoading: hardwareTypes.isLoading,
    lookup,
  };
};

const useHardwareManufacturerLookup = () => {
  const { hardwareManufacturers } = useHardwareManufacturers();

  const lookup = (manufacturerId: string) =>
    hardwareManufacturers.data?.find((m) => m.id === manufacturerId);

  return {
    isLoading: hardwareManufacturers.isLoading,
    lookup,
  };
};

const useHardwareModelLookup = () => {
  const { hardwareModels } = useHardwareModels();

  const lookup = (modelId: string) =>
    hardwareModels.data?.find((m) => m.id === modelId);

  return {
    isLoading: hardwareModels.isLoading,
    lookup,
  };
};

export const useHardwareLookups = () => {
  const { isLoading: typesLoading, lookup: typeLookup } =
    useHardwareTypeLookup();

  const { isLoading: manufacturersLoading, lookup: manufacturerLookup } =
    useHardwareManufacturerLookup();

  const { isLoading: modelsLoading, lookup: modelLookup } =
    useHardwareModelLookup();

  const isLoading = manufacturersLoading || typesLoading || modelsLoading;

  return {
    isLoading,
    manufacturerLookup,
    typeLookup,
    modelLookup,
  };
};
