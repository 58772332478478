import { useMutation, useQueryClient } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import {
  IStatisticConfiguration,
  StatisticConfigurationCreateUpdate,
} from "../../types/Statistic/Statistic";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useStatisticConfigurationsMutations() {
  const queryClient = useQueryClient();
  const { put, post } = useApiHelper();

  const createConfiguration = useMutation({
    mutationFn: (config: StatisticConfigurationCreateUpdate) =>
      post<
        StatisticConfigurationCreateUpdate,
        IDefaultResponseWithData<IStatisticConfiguration>
      >("/v1/organisation/configurations", config).then((res) => res.data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.configurations(1));
    },
  });

  const updateConfiguration = useMutation({
    mutationFn: (config: IStatisticConfiguration) =>
      put<
        IStatisticConfiguration,
        IDefaultResponseWithData<IStatisticConfiguration>
      >(`/v1/organisation/configurations/${config.id}`, config).then(
        (res) => res.data.data
      ),
  });

  return {
    createConfiguration,
    updateConfiguration,
  };
}
