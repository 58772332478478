import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IAlert } from "../../types/Alerts/Alerts";

export default function useAlert() {
  const { get } = useApiHelper();

  const alerts = useQuery({
    queryKey: ["alert"],
    queryFn: () =>
      get<IDefaultResponseWithData<IAlert>>("/v1/alert").then(
        (res) => res.data.data,
      ),
  });

  return {
    alerts,
  };
}
