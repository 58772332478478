import React from "react";

import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  className?: string;
  defaultValues?: DeepPartial<TFormValues>;
};

export function Form<TFormValues extends FieldValues>({
  children,
  onSubmit,
  className,
  defaultValues,
}: FormProps<TFormValues>) {
  const methods = useForm<TFormValues>({ defaultValues });

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
        {children(methods)}
      </form>
    </FormProvider>
  );
}
