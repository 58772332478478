import { useEffect, useState } from "react";

import { Card } from "../base/Card";
import { IDevice } from "../../types/Device/Device";
import useDevices from "../../data/device/useDevices";
import useHardware from "../../data/hardware/useHardware";
import { SectionLoading } from "../shared/SectionLoading";

export function Devices() {
  const { devices } = useDevices();
  const { hardware } = useHardware();
  const [deviceHardware, setDeviceHardware] = useState<IDevice[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      !devices.isLoading &&
      !hardware.isLoading &&
      devices.data &&
      hardware.data
    ) {
      setDeviceHardware(
        devices.data.filter((dev) =>
          hardware.data.some((hw) => hw.deviceId === dev.id),
        ),
      );

      setLoading(false);
    }
  }, [devices.isLoading, hardware.isLoading, devices.data, hardware.data]);

  return (
    <Card title="Devices">
      {loading ? (
        <SectionLoading />
      ) : (
        <div className="mt-4">
          <div className="flex flex-row space-x-3">
            <div className="text-6xl font-light tracking-widest">
              {
                deviceHardware.filter((dev) => dev.device.cloud.connected)
                  .length
              }
              /{deviceHardware.length}
            </div>
            <div className="flex items-end text-xl font-light">
              Devices Online
            </div>
          </div>
          <div className="mt-2 flex flex-row space-x-3">
            <div className="text-4xl font-light tracking-widest">
              {devices!.data!.length - deviceHardware.length}
            </div>
            <div className="flex items-end text-xl font-light">
              Unassigned Atmo devices
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
