import { isRefreshing } from "../hooks/useApiHelper";

export interface QueueItem {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}

export default class Queue {
  static queue: QueueItem[] = [];
  static pendingPromise = false;

  static enqueue(item: QueueItem) {
    this.queue.push(item);
  }

  static dequeue(error: any, token: string | null = null) {
    if (isRefreshing) {
      return false;
    }

    this.queue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.queue = [];
  }
}
