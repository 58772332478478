import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IHardwareSelection } from "../../types/Hardware/Hardware";

export default function useHardwareTypes() {
  const { get } = useApiHelper();

  const hardwareTypes = useQuery({
    queryKey: ["hardware", "types"],
    queryFn: () =>
      get<IDefaultResponseWithData<IHardwareSelection>>(
        `/v1/hardware/types`,
      ).then((res) => res.data.data),
  });

  return {
    hardwareTypes,
  };
}
