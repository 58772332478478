import { Login } from "./pages/public";
import { DashboardPage } from "./pages";
import { JobsPage } from "./pages/Jobs";
import { SystemPage } from "./pages/System";
import { AlertsPage } from "./pages/Alerts";
import { HistoryPage } from "./pages/History";
import { Invite } from "./pages/public/invite";
import { HardwarePage } from "./pages/Hardware";
import { LiveViewPage } from "./pages/LiveView";
import { ErrorPage } from "./pages/Alerts/error";
import { CustomersPage } from "./pages/Customers";
import { Register } from "./pages/public/register";
import NotFoundPage from "./pages/public/NotFound";
import { InstallersPage } from "./pages/Installers";
import AuthGuard from "./components/shared/AuthGuard";
import { EngineerViewPage } from "./pages/EngineerView";
import { SettingsLayout } from "./layouts/SettingsLayout";
import { UsersTab } from "./components/Settings/UsersTab";
import { ActionPage } from "./pages/System/action/action";
import { TariffTab } from "./components/Settings/TariffTab";
import { ResetPassword } from "./pages/public/resetPassword";
import { GeneralTab } from "./components/Settings/GeneralTab";
import { BillingTab } from "./components/Settings/BillingTab";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import { SecurityTab } from "./components/Settings/SecurityTab";
import { BrandingTab } from "./components/Settings/BrandingTab";
import { HardwareTypesPage } from "./pages/System/hardware/types";
import { JobCategoriesPage } from "./pages/System/job/categories";
import { InstallerTab } from "./components/Settings/InstallerTab";
import { TemplatesTab } from "./components/Settings/TemplatesTab";
import { HardwareModelsPage } from "./pages/System/hardware/models";
import { DataPointsPage } from "./pages/System/datapoint/dataPoints";
import { ForgottenPassword } from "./pages/public/forgottenPassword";
import { UnauthenticatedLayout } from "./layouts/UnauthenticatedLayout";
import { ActionProfilesPage } from "./pages/System/action/actionProfiles";
import { DataPointOptionsPage } from "./pages/System/datapoint/dataPointOptions";
import { HardwareManufacturersPage } from "./pages/System/hardware/manufacturers";
import { DataPointProfilesPage } from "./pages/System/datapoint/dataPointProfiles";
import { DataPointFunctionsPage } from "./pages/System/datapoint/dataPointFunctions";
import { ActionTransformationsPage } from "./pages/System/action/actionTransformations";
import { GlobalHardwareModelFilterProvider } from "./contexts/GlobalHardwareFilterContext";
import { DataPointProfileAddEditPage } from "./pages/System/datapoint/dataPointProfileAddEdit";
import { DataPointTransformationsPage } from "./pages/System/datapoint/dataPointTransformations";

const routes = [
  {
    path: "",
    element: <AuthenticatedLayout />,
    children: [
      {
        index: true,
        element: (
          <AuthGuard component={<LiveViewPage />} requiredRole="customer" />
        ),
      },
      {
        path: "history",
        element: (
          <AuthGuard component={<HistoryPage />} requiredRole="customer" />
        ),
      },
      {
        path: "alerts",
        element: (
          <AuthGuard component={<AlertsPage />} requiredRole="customer" />
        ),
      },
      {
        path: "hardware",
        element: (
          <AuthGuard component={<HardwarePage />} requiredRole="customer" />
        ),
      },
      {
        path: "settings",
        element: (
          <AuthGuard component={<SettingsLayout />} requiredRole="customer" />
        ),
        children: [
          {
            index: true,
            element: <GeneralTab />,
          },
          {
            path: "tariffs",
            element: <TariffTab />,
          },
          {
            path: "security",
            element: <SecurityTab />,
          },
          {
            path: "users",
            element: <UsersTab />,
          },
          {
            path: "branding",
            element: <BrandingTab />,
          },
          {
            path: "billing",
            element: <BillingTab />,
          },
          {
            path: "installer",
            element: <InstallerTab />,
          },
          {
            path: "templates",
            element: <TemplatesTab />,
          },
        ],
      },
      {
        path: "dashboard",
        element: (
          <AuthGuard
            component={<DashboardPage />}
            requiredRole={["installer", "superadmin"]}
          />
        ),
      },
      {
        path: "error",
        element: (
          <AuthGuard
            component={<ErrorPage />}
            requiredRole={["installer", "superadmin"]}
          />
        ),
      },
      {
        path: "jobs",
        element: (
          <AuthGuard
            component={<JobsPage />}
            requiredRole={["installer", "superadmin"]}
          />
        ),
      },
      {
        path: "customers",
        element: (
          <AuthGuard
            component={<CustomersPage />}
            requiredRole={["installer", "superadmin"]}
          />
        ),
      },
      {
        path: "engineer-view",
        element: (
          <AuthGuard
            component={<EngineerViewPage />}
            requiredRole={[
              "installer",
              "superadmin",
              "installerActingAsCustomer",
            ]}
          />
        ),
      },
      {
        path: "installers",
        element: (
          <AuthGuard component={<InstallersPage />} requiredRole="superadmin" />
        ),
      },
      {
        path: "system",
        element: (
          <AuthGuard
            component={<GlobalHardwareModelFilterProvider />}
            requiredRole="superadmin"
          />
        ),
        children: [
          {
            index: true,
            element: <SystemPage />,
          },
          {
            path: "hardware",
            children: [
              {
                path: "types",
                element: <HardwareTypesPage />,
              },
              {
                path: "models",
                element: <HardwareModelsPage />,
              },
              {
                path: "manufacturers",
                element: <HardwareManufacturersPage />,
              },
            ],
          },
          {
            path: "job",
            children: [
              {
                path: "categories",
                element: <JobCategoriesPage />,
              },
            ],
          },
          {
            path: "action",
            children: [
              {
                index: true,
                element: <ActionPage />,
              },
              {
                path: "profiles",
                element: <ActionProfilesPage />,
              },
              {
                path: "transformations",
                element: <ActionTransformationsPage />,
              },
            ],
          },
          {
            path: "datapoint",
            children: [
              {
                index: true,
                element: <DataPointsPage />,
              },
              {
                path: "options",
                element: <DataPointOptionsPage />,
              },
              {
                path: "functions",
                element: <DataPointFunctionsPage />,
              },
              {
                path: "transformations",
                element: <DataPointTransformationsPage />,
              },
              {
                path: "profiles",
                children: [
                  {
                    index: true,
                    element: <DataPointProfilesPage />,
                  },
                  {
                    path: "add",
                    element: <DataPointProfileAddEditPage />,
                  },
                  {
                    path: ":dataPointProfileId",
                    element: <DataPointProfileAddEditPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
  {
    path: "forgotten-password",
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <ForgottenPassword />,
      },
    ],
  },
  {
    path: "reset-password",
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "invite",
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <Invite />,
      },
    ],
  },
  {
    path: "register",
    element: <UnauthenticatedLayout />,
    children: [
      {
        index: true,
        element: <Register />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
