import { useMutation, useQueryClient } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IViewConfiguration } from "../../types/Tile/Tile";
import { dataPointQueryKeys } from "../datapoint/QueryKey";

export default function useTileViewConfigurationsMutations() {
  const queryClient = useQueryClient();
  const { put, post } = useApiHelper();

  const createConfiguration = useMutation({
    mutationFn: (config: IViewConfiguration) =>
      post<IViewConfiguration, IDefaultResponseWithData<IViewConfiguration>>(
        "/v1/user/configurations",
        config
      ).then((res) => res.data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.configurations(3));
      queryClient.invalidateQueries([
        ...dataPointQueryKeys.all(),
        "byProfileId",
      ]);
    },
  });

  const updateConfiguration = useMutation({
    mutationFn: (config: IViewConfiguration) =>
      put<IViewConfiguration, IDefaultResponseWithData<IViewConfiguration>>(
        `/v1/user/configurations/${config.id}`,
        config
      ).then((res) => res.data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.configurations(3));
      queryClient.invalidateQueries([
        ...dataPointQueryKeys.all(),
        "byProfileId",
      ]);
    },
  });

  return {
    createConfiguration,
    updateConfiguration,
  };
}
