import {
  useState,
  ReactElement,
  Dispatch,
  createContext,
  useContext,
  SetStateAction,
} from "react";

interface PageTitleContextValue {
  pageTitle: string;
  subTitle?: string;
  setPageTitle: (value: string, resetSubtitle?: boolean) => void;
  setSubTitle: Dispatch<SetStateAction<string | undefined>>;
}

const PageTitleContext = createContext<PageTitleContextValue>({
  pageTitle: "Dashboard",
  subTitle: undefined,
  setPageTitle: () => {},
  setSubTitle: () => {},
});

interface PageTitleContextProviderProps {
  children: ReactElement | ReactElement[];
}

export function PageTitleContextProvider({
  children,
}: PageTitleContextProviderProps) {
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [subTitle, setSubTitle] = useState<string | undefined>(undefined);

  const setPT = (value: string, resetSubtitle = true) => {
    resetSubtitle && setSubTitle(undefined);
    setPageTitle(value);
  };

  return (
    <PageTitleContext.Provider
      value={{ pageTitle, subTitle, setPageTitle: setPT, setSubTitle }}
    >
      {children}
    </PageTitleContext.Provider>
  );
}

export function usePageTitle() {
  const pageTitleCtx = useContext(PageTitleContext);

  if (!pageTitleCtx) {
    throw Error("usePageTitle() must be used within a ThemeContextProvider");
  }

  return pageTitleCtx;
}
