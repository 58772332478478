export const averagedDataPoints = [
  "b810baf0-5deb-46de-0845-08db79626cfc",
  "e86d7b09-fac6-4d71-2b7c-08db6c06bdf6",
  "8b949638-b4b6-4241-4522-08db6c129897",
  "4b43de48-11c7-4eaf-0f2f-08db799086d4",
  "02741e44-81e6-4715-4372-08dc180a3dc2",
  "667e300a-dc19-4b6c-a62f-08dc1ddfd8eb",
  "573db001-9670-41b0-157d-08db929a6792",
  "4477153d-ab74-40d5-0f39-08db799086d4",
  "2ae0c4c6-11d3-4dab-5522-08dba4cbb1ee",
  "fdd89542-8784-4e15-befd-08dbc325e8e0",
  "d52ac9c7-c3fb-4ce0-40a6-08dbbaa06d23",
  "02df7f57-612f-4d52-40a7-08dbbaa06d23",
  "0d6db601-588e-4dd6-1a26-08dcf43e360f",
];

export const accumulatedDataPoints = [
  "226c14ad-5f72-4d55-58b3-08dc8bad07b1",
  "a1dc817c-be82-4c6a-58b2-08dc8bad07b1",
  "bc9d2141-4b4d-4be5-58b5-08dc8bad07b1",
];
