import { useState } from "react";

import { useNavigate } from "react-router";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { IDataPointProfile } from "../../../types/DataPoint/DataPointProfile";
import { useGlobalHardwareModelFilter } from "../../../contexts/GlobalHardwareFilterContext";
import { CreateEditDataPointProfile } from "../../../components/DataPoint/CreateEditDataPointProfile";
import useSuperAdminDataPointProfileMutations from "../../../data/datapoint/superadmin/useSuperAdminDataPointProfileMutation";
import { useDataPointLookups } from "../../../hooks/useDataPointLookups";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";
import { SectionLoading } from "../../../components/shared/SectionLoading";
import { useClipboardCopy } from "../../../hooks/useClipboardCopy";

const colHelper = createColumnHelper<IDataPointProfile>();

export function DataPointProfilesPage() {
  const { CopyToClipboardButton } = useClipboardCopy();
  const navigate = useNavigate();
  const { delDataPointProfile, updateDataPointProfile } =
    useSuperAdminDataPointProfileMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editDataPointProfile, setEditDataPointProfile] = useState<
    IDataPointProfile | undefined
  >(undefined);
  const [deleteDataPointProfile, setDeleteDataPointProfile] = useState<
    IDataPointProfile | undefined
  >(undefined);
  const {
    isLoading: isHardwareLookupLoading,
    manufacturerLookup,
    modelLookup,
    typeLookup,
  } = useSuperAdminHardwareLookups();
  const { isLoading: isDataPointLookupLoading, dataPointLookup } =
    useDataPointLookups(globalHardwareModelFilter?.id);

  const handleDeleteDataPointProfile = (decodeProfile: IDataPointProfile) => {
    setDeleteDataPointProfile(decodeProfile);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteDataPointProfile = () => {
    delDataPointProfile.mutate(deleteDataPointProfile!);
    setDeleteOpen(false);
  };

  const handleRestoreDataPointProfile = (decodeProfile: IDataPointProfile) => {
    updateDataPointProfile.mutate({ ...decodeProfile, restore: true });
  };

  const handleEditDataPointProfile = (decodeProfile: IDataPointProfile) => {
    setEditDataPointProfile(decodeProfile);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editDataPointProfile &&
      setTimeout(() => setEditDataPointProfile(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor(
      (row) => manufacturerLookup(row.hardwareManufacturerId)?.name,
      { header: "Manufacturer" }
    ),
    colHelper.accessor((row) => modelLookup(row.hardwareModelId)?.name, {
      header: "Model",
    }),
    colHelper.accessor((row) => dataPointLookup(row.dataPointId)?.name, {
      header: "Data Point",
    }),
    colHelper.accessor("index", { header: "Index" }),
    colHelper.accessor("length", { header: "Length" }),
    colHelper.accessor("bit", {
      header: "Bit",
      cell: (info) =>
        info.getValue() === null ? (
          <span className="opacity-70 dark:text-white italic">null</span>
        ) : (
          info.getValue()
        ),
    }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreDataPointProfile(info.row.original)}
            />
          ) : (
            <>
              <CopyToClipboardButton iconOnly copyText={info.row.original.id} />
              <PencilSquareIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => navigate(info.row.original.id)}
              />
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditDataPointProfile(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteDataPointProfile(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditDataPointProfile
        open={editOpen}
        onClose={handleCreateEditClose}
        dataPointProfile={editDataPointProfile}
      />
      <ConfirmDelete
        itemName={"Data Point Profile"}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteDataPointProfile()}
      />
      <Card title="Data Point Profiles">
        <div className="my-6 space-x-4">
          <Button onClick={() => setEditOpen(true)}>
            Add Data Point Profile
          </Button>
          <Button onClick={() => navigate("add")}>Data Point MEGA FORM</Button>
        </div>
        {isHardwareLookupLoading || isDataPointLookupLoading ? (
          <SectionLoading />
        ) : (
          <div>
            <Table<IDataPointProfile>
              url="/superadmin/datapoint/profile"
              queryKey={["superadmin", "datapoint", "profiles"]}
              columns={cols}
              predefinedFilterFields={
                globalHardwareModelFilter
                  ? [
                      {
                        field: "hardwareModelId",
                        value: globalHardwareModelFilter.id,
                        fieldLabel: "Hardware Model",
                        valueLabel: globalHardwareModelFilter.name,
                      },
                    ]
                  : undefined
              }
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
