import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { Toggle, ToggleProps } from "../Toggle";

export interface FormToggleProps<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  toggleProps: Omit<ToggleProps, "onChange" | "checked">;
}

export function FormToggle<T extends FieldValues>({
  control,
  name,
  toggleProps,
}: FormToggleProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Toggle checked={value} onChange={onChange} {...toggleProps} />
      )}
    />
  );
}
