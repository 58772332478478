import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../../hooks/useApiHelper";

import { useDefaultMutateResponseHandlers } from "../../../hooks/useDefaultMutateResponeHandlers";
import { IDataPoint } from "../../../types/DataPoint/DataPoint";

export default function useSuperAdminDataPointMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Data Point");

  const createDataPoint = useMutation({
    mutationFn: (dataPoint) => post("/v1/superadmin/datapoint", dataPoint),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => { 
      queryClient.invalidateQueries(["superadmin", "datapoint"])
      queryClient.resetQueries(["reading", "datapoints"])
    }
  });

  const updateDataPoint = useMutation({
    mutationFn: (dataPoint: IDataPoint) =>
      put<IDataPoint>(`/v1/superadmin/datapoint/${dataPoint.id}`, dataPoint),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled:() => { 
      queryClient.invalidateQueries(["superadmin", "datapoint"])
      queryClient.resetQueries(["reading", "datapoints"])
    }
  });

  const delDataPoint = useMutation({
    mutationFn: (dataPoint: IDataPoint) =>
      del(`/v1/superadmin/datapoint/${dataPoint.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => { 
      queryClient.invalidateQueries(["superadmin", "datapoint"])
      queryClient.resetQueries(["reading", "datapoints"])
    }
  });

  return {
    createDataPoint,
    updateDataPoint,
    delDataPoint,
  };
}
