import { useFormContext } from "react-hook-form";

import { FormSelect } from "../../base/Form/FormSelect";
import { FormTextField } from "../../base/Form/FormTextField";
import { IHardwareCreateUpdateFormFields } from "../../../types/Hardware/Hardware";

export function PowerSettings() {
  const frequencyOpts = [50, 60];
  const phaseOpts: Record<number, string> = {
    1: "Single Phase",
    3: "Three Phase",
  };
  const { watch, register } = useFormContext<IHardwareCreateUpdateFormFields>();

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <div className="flex items-center justify-between mb-2">
        <p className="text-base font-medium">Power Settings</p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {!watch("peripheralSettings.voltmeter.enabled") && (
          <FormTextField
            {...register("powerSettings.measuredVoltage", {
              min:
                watch("powerSettings.phase") === "3"
                  ? {
                      value: 208,
                      message: "Measured Voltage (V) cannot be less than 208",
                    }
                  : {
                      value: 110,
                      message: "Measured Voltage (V) cannot be less than 110",
                    },
              max:
                watch("powerSettings.phase") === "3"
                  ? {
                      value: 480,
                      message:
                        "Measured Voltage (V) cannot be greater than 480",
                    }
                  : {
                      value: 260,
                      message:
                        "Measured Voltage (V) cannot be greater than 260",
                    },
            })}
            label="Measured Voltage (V)"
          />
        )}
        <FormSelect
          {...register("powerSettings.frequency")}
          label="Frequency (Hz)"
        >
          <option disabled hidden value={0}>
            Please Select
          </option>
          {frequencyOpts.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </FormSelect>
        <FormSelect {...register("powerSettings.phase")} label="Phase">
          <option disabled hidden value={0}>
            Please Select
          </option>
          {Object.keys(phaseOpts).map((x, i) => (
            <option key={i} value={x}>
              {phaseOpts[Number(x)]}
            </option>
          ))}
        </FormSelect>
      </div>
    </div>
  );
}
