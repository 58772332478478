import { useEffect, useState } from "react";

import { Card } from "../../components/base/Card";
import { Select } from "../../components/base/Select";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { AllReadingsTable } from "../../components/EngineerView/ReadingsTable";
import { AllReadingsDisplay } from "../../components/EngineerView/AllReadingsDisplay";

import { usePageTitle } from "../../contexts/PageTitleContext";

import useHardware from "../../data/hardware/useHardware";

import { IHardware } from "../../types/Hardware/Hardware";
import { useHardwareLookups } from "../../hooks/useHardwareLookups";
import { DeviceStatusBar } from "../../components/LiveView/LiveViewSettings/DeviceStatusBar";

export function EngineerViewPage() {
  const { setPageTitle } = usePageTitle();
  const { hardware: allHardware } = useHardware();
  const { isLoading, manufacturerLookup, modelLookup } = useHardwareLookups();
  const [selectedHardware, setSelectedHardware] = useState<
    IHardware | undefined
  >(undefined);
  const [noHardware, setNoHardware] = useState(false);
  const [tableView, setTableView] = useState(true);

  const handleHardwareChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const hardwareId = e.currentTarget.value;
    const foundHardware = allHardware.data?.find(
      (hardware) => hardware.id === hardwareId,
    );

    setSelectedHardware(foundHardware);
  };

  useEffect(() => {
    setPageTitle("Engineer View", false);
  }, [setPageTitle]);

  useEffect(() => {
    if (!allHardware.isLoading && allHardware.data?.length) {
      if (!selectedHardware) {
        setSelectedHardware(allHardware.data[0]);
      }
    } else if (!allHardware.isLoading) {
      setNoHardware(true);
    }
  }, [selectedHardware, allHardware.isLoading, allHardware.data]);

  return noHardware ? (
    <Card>
      <div className="h-24 flex items-center justify-center">
        <p>No Atmo devices registered to this account</p>
      </div>
    </Card>
  ) : !selectedHardware || isLoading ? (
    <SectionLoading />
  ) : (
    <>
      <Card className="mb-6 block space-y-3 md:flex md:flex-row md:items-center md:space-between md:space-y-0">
        <div className="w-full block mb-4 md:mb-0 md:flex md:space-x-4 md:items-center">
          <p className="text-sm mb-2 md:mb-0">Atmo Device</p>
          <Select className="w-full md:w-64" onChange={handleHardwareChange}>
            {allHardware.data?.map((hardware) => (
              <option key={hardware.id} value={hardware.id}>
                {hardware.name}
              </option>
            ))}
          </Select>
          <p className="my-4 md:my-0">
            {`${
              manufacturerLookup(selectedHardware.hardwareManufacturerId)?.name
            } |
            ${modelLookup(selectedHardware.hardwareModelId)?.name}`}
          </p>
          <div className="w-full md:w-64 border-primary border rounded-lg flex flex-row">
            <div
              onClick={() => setTableView(false)}
              className={`${
                !tableView
                  ? "bg-primary cursor-default"
                  : "hover:bg-primary/60 hover:cursor-pointer"
              } py-2 px-3 w-1/2 text-center rounded-s-md`}
            >
              Card View
            </div>
            <div
              onClick={() => setTableView(true)}
              className={`${
                tableView
                  ? "bg-primary cursor-default"
                  : "hover:bg-primary/60 hover:cursor-pointer"
              } py-2 px-3 w-1/2 text-center rounded-e-md`}
            >
              Table View
            </div>
          </div>
        </div>
        <DeviceStatusBar hardware={selectedHardware} />
      </Card>
      {tableView ? (
        <Card>
          <AllReadingsTable hardware={selectedHardware} />
        </Card>
      ) : (
        <AllReadingsDisplay hardware={selectedHardware} />
      )}
    </>
  );
}
