import { Alert, AlertProps } from "../base/Alert";

export interface NotificationProps extends Omit<AlertProps, "severity"> {}

export function InfoNotification({
  title = "Information",
  message,
}: NotificationProps) {
  return (
    <Alert
      severity="info"
      title={title}
      message={message}
      className="!bg-blue-600 border-none max-w-sm w-full sm:w-80"
    />
  );
}

export function SuccessNotification({
  title = "Success",
  message,
}: NotificationProps) {
  return (
    <Alert
      severity="success"
      title={title}
      message={message}
      className="!bg-green-700 border-none max-w-sm w-full sm:w-80"
    />
  );
}

export function WarningNotification({
  title = "Warning",
  message,
}: NotificationProps) {
  return (
    <Alert
      severity="warn"
      title={title}
      message={message}
      className="!bg-yellow-600 border-none max-w-sm w-full sm:w-80"
    />
  );
}

export function ErrorNotification({
  title = "Error",
  message,
}: NotificationProps) {
  return (
    <Alert
      severity="error"
      title={title}
      message={message}
      className="!bg-red-600 border-none max-w-sm w-full sm:w-80"
    />
  );
}
