import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import { SectionLoading } from "../../components/shared/SectionLoading";

import useApiHelper from "../../hooks/useApiHelper";
import { IUser } from "../../types/User/User";
import { Alert } from "../../components/base/Alert";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { Button } from "../../components/base/Button";

export function Invite() {
  const navigate = useNavigate();
  const { get, post } = useApiHelper({ addToken: false });
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const processInvite = async () => {
      const isRegistered = async (token: string) => {
        let registered = false;

        try {
          const user = await get<IDefaultResponseWithData<IUser>>(
            `/v1/user?token=${token}`,
          );
          if (user.data.data[0] && user.data.data[0].dateRegistered !== null) {
            registered = true;
          }
        } catch (err: any) {
          setError("Error retrieving invite details");
        } finally {
          setLoading(false);
        }

        return registered;
      };

      const acceptInvite = async (token: string) => {
        let success = false;

        try {
          await post(`/v1/user/invite?token=${token}`, {
            accept: true,
          });
        } catch (err: any) {
          setError("Error accepting invite");
        } finally {
          setLoading(false);
        }

        return success;
      };

      if (searchParams.has("token")) {
        const token = searchParams.get("token")!;
        if (await isRegistered(token)) {
          await acceptInvite(token);
        } else {
          navigate(`/register?token=${token}`);
        }
      } else {
        setError("Error retrieving invite details");
      }
    };

    processInvite();
  }, [searchParams]);

  return (
    <div className="w-10/12 xl:w-8/12 mx-auto">
      {loading ? (
        <SectionLoading />
      ) : error ? (
        <Alert message={error} severity="error" />
      ) : (
        <>
          <Alert
            className="mb-6"
            severity="success"
            title="Registration Successful!"
            message="Your account has been created and you can now log in"
          />
          <NavLink to="/login">
            <Button fullWidth>Login</Button>
          </NavLink>
        </>
      )}
    </div>
  );
}
