import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { IHardwareType } from "../../../types/Hardware/Hardware";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import useHardwareTypeMutations from "../../../data/hardware/useHardwareTypeMutations";
import { CreateEditHardwareType } from "../../../components/Hardware/CreateEditHardwareType";
import { useClipboardCopy } from "../../../hooks/useClipboardCopy";

const colHelper = createColumnHelper<IHardwareType>();

export function HardwareTypesPage() {
  const { CopyToClipboardButton } = useClipboardCopy();
  const { delHardwareType, updateHardwareType } = useHardwareTypeMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editHardwareType, setEditHardwareType] = useState<
    IHardwareType | undefined
  >(undefined);
  const [deleteHardwareType, setDeleteHardwareType] = useState<
    IHardwareType | undefined
  >(undefined);

  const handleDeleteHardwareType = (hardwareType: IHardwareType) => {
    setDeleteHardwareType(hardwareType);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteHardwareType = () => {
    delHardwareType.mutate(deleteHardwareType!);
    setDeleteOpen(false);
  };

  const handleRestoreHardwareType = (hardwareType: IHardwareType) => {
    updateHardwareType.mutate({ ...hardwareType, restore: true });
  };

  const handleEditHardwareType = (hardwareType: IHardwareType) => {
    setEditHardwareType(hardwareType);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editHardwareType && setTimeout(() => setEditHardwareType(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.display({
      header: "Status",
      cell: (info) =>
        info.row.original.disabled ? (
          <XMarkIcon className="w-5 h-5 opacity-70" />
        ) : (
          <CheckIcon className="w-5 h-5 opacity-70" />
        ),
    }),
    colHelper.accessor("name", { header: "Type" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() => handleRestoreHardwareType(info.row.original)}
            />
          ) : (
            <>
              <CopyToClipboardButton iconOnly copyText={info.row.original.id} />
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleEditHardwareType(info.row.original)}
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() => handleDeleteHardwareType(info.row.original)}
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditHardwareType
        open={editOpen}
        onClose={handleCreateEditClose}
        hardwareType={editHardwareType}
      />
      <ConfirmDelete
        itemName={deleteHardwareType?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteHardwareType()}
      />
      <Card title="Hardware Types">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Type</Button>
        </div>
        <div>
          <Table<IHardwareType>
            url="/superadmin/hardware/types"
            queryKey={["superadmin", "hardware", "types"]}
            columns={cols}
            enableShowDeleted
          />
        </div>
      </Card>
    </>
  );
}
