import { IBaseModel } from "../system/BaseModel";
import { ConfigurationType } from "../Configuration/Configuration";

export enum StatisticType {
  graph,
  average,
  accumulation,
}

export interface IStatistic
  extends Omit<IBaseModel, "id" | "createdOn" | "createdBy"> {
  id?: string;
  type: StatisticType;
  title: string;
  dataPointIds: string[];
}

export interface IStatisticConfiguration extends IBaseModel {
  type: ConfigurationType.statistic;
  ownerId: string;
  statistics: IStatistic[];
}

export interface StatisticConfigurationCreateUpdate
  extends Omit<IStatisticConfiguration, "id" | "createdOn" | "createdBy"> {
  id?: string;
  createdOn?: string;
  createdBy?: string;
}
