import { useState } from "react";

import { DateTime } from "luxon";

import { Alert } from "../base/Alert";
import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { TextField } from "../base/TextField";
import {
  isInFuture,
  predefinedDateRanges,
  predefinedTimespans,
} from "../../utils/graphUtils";
import GranularityOption from "../../types/system/GranularityOptions";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";

export function TimespanGranularity() {
  const {
    hardware,
    timespan,
    setTimespan,
    granularity,
    granularityOptions,
    setGranularity,
    setCurrentValue,
  } = useGraphSettings();
  const [timespanPickerValue, setTimespanPickerValue] = useState(0);
  const [disableTimespanPickers, setDisableTimespanPickers] = useState(true);
  const [timespanErrors, setTimespanErrors] = useState<string[]>([]);

  const handleTimespanPickerChange = (index: number) => {
    setTimespanPickerValue(index);

    if (index === predefinedTimespans.length + predefinedDateRanges.length) {
      // Custom timespan, enable pickers
      setDisableTimespanPickers(false);
    } else if (index > predefinedTimespans.length - 1) {
      // Predefined date range, disable pickers
      let offsetRemovedIndex = index - predefinedTimespans.length;
      !disableTimespanPickers && setDisableTimespanPickers(true);
      setTimespan(predefinedDateRanges[offsetRemovedIndex]);
    } else {
      // Predefined, disable pickers
      !disableTimespanPickers && setDisableTimespanPickers(true);
      setTimespan(predefinedTimespans[index]);
    }
  };

  const handleStartDateChange = (value: string) => {
    const date = DateTime.fromISO(value);
    let errors: string[] = [];

    setTimespan({ startDate: date, endDate: timespan.endDate });

    if (isInFuture(date)) {
      errors.push("Start date cannot be in the future");
    }

    if (date > timespan.endDate) {
      errors.push("Start date must be before End date");
    }

    if (Math.abs(date.diff(timespan.endDate, "years").years) > 5) {
      errors.push(
        "The difference between Start date and End date cannot be greater than 5 years",
      );
    }

    if (errors.length > 0) {
      setTimespanErrors(errors);
    } else {
      setTimespanErrors([]);
    }
  };

  const handleEndDateChange = (value: string) => {
    const date = DateTime.fromISO(value);
    let errors: string[] = [];

    setTimespan({ startDate: timespan.startDate, endDate: date });

    if (isInFuture(date)) {
      errors.push("End date cannot be in the future");
    }

    if (date < timespan.startDate) {
      errors.push("End date must be after Start date");
    }

    if (Math.abs(date.diff(timespan.startDate, "years").years) > 5) {
      errors.push(
        "The difference between Start date and End date cannot be greater than 5 years",
      );
    }

    if (errors.length > 0) {
      setTimespanErrors(errors);
    } else {
      setTimespanErrors([]);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-11 gap-4">
      {timespanErrors.length > 0 && (
        <div className="md:col-span-11">
          <Alert severity="error" message={timespanErrors} />
        </div>
      )}
      <div className="grid grid-cols-1 md:col-span-5 gap-4">
        {/* Timespan */}
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6">
          <div className="flex items-center">
            <p className="text-sm mb-2 md:mb-0">Timespan</p>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <Select
              className="w-full"
              value={timespanPickerValue}
              onChange={(e) =>
                handleTimespanPickerChange(parseInt(e.currentTarget.value))
              }
            >
              {[
                ...predefinedTimespans,
                ...predefinedDateRanges,
                { label: "Custom" },
              ].map((ts, i) => (
                <option key={`timespan-${i}`} value={i}>
                  {ts.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
        {/* End Timespan */}

        {/* Granularity */}
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6">
          <div className="flex items-center">
            <p className="text-sm mb-2 md:mb-0">Granularity</p>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <Select
              className="w-full"
              value={granularity}
              onChange={(e) =>
                setGranularity(e.currentTarget.value as GranularityOption)
              }
            >
              {[...granularityOptions].map((g, i) => (
                <option key={`timespan-${i}`} value={g}>
                  {g}
                </option>
              ))}
            </Select>
          </div>
        </div>
        {/* End Granularity */}
      </div>
      <div className="grid grid-cols-1 md:col-span-5 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6">
          <div className="flex items-center">
            <p className="text-sm mb-2 md:mb-0">Start date</p>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <TextField
              type="datetime-local"
              disabled={disableTimespanPickers}
              value={timespan.startDate.toFormat("yyyy-MM-dd'T'HH:mm")!}
              onChange={(e) => handleStartDateChange(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6">
          <div className="flex items-center">
            <p className="text-sm mb-2 md:mb-0">End date</p>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <TextField
              type="datetime-local"
              disabled={disableTimespanPickers}
              value={timespan.endDate.toFormat("yyyy-MM-dd'T'HH:mm")!}
              onChange={(e) => handleEndDateChange(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
      <div>
        <Button
          disabled={timespanErrors.length > 0 || !hardware}
          onClick={setCurrentValue}
          className="w-full h-full"
        >
          Set
        </Button>
      </div>
    </div>
  );
}
