import { useMutation, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

import { IAlert } from "../../types/Alerts/Alerts";

export default function useAlertMutations() {
  const queryClient = useQueryClient();
  const { post, put, del } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Alert");

  const createAlert = useMutation({
    mutationFn: (alert) => post("/v1/alert", alert),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => {
      queryClient.invalidateQueries(["alert"]);
    },
  });

  const updateAlert = useMutation({
    mutationFn: (alert: IAlert) => put<IAlert>(`/v1/alert/${alert.id}`, alert),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => {
      queryClient.invalidateQueries(["alert"]);
    },
  });

  const delAlert = useMutation({
    mutationFn: (alert: IAlert) => del(`/v1/alert/${alert.id}`),
    onError: (error) => handleError(error, "delete"),
    onSuccess: (data) => handleSuccess(data, "delete"),
    onSettled: () => {
      queryClient.invalidateQueries(["alert"]);
    },
  });

  return {
    createAlert,
    updateAlert,
    delAlert,
  };
}
