import {
  ReactNode,
  Dispatch,
  useReducer,
  createContext,
  useContext,
  useEffect,
} from "react";

import { darkThemeMixins, lightThemeMixins } from "../constants/ThemeMixins";

import { useUserContext } from "./UserContext";

import { FullscreenLoading } from "../components/shared/FullscreenLoading";

import useApiHelper from "../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../types/system/DefaultResponse";
import { IInstallerBrandingGetResponse } from "../types/responses/installer/branding/InstallerBrandingGet";

const themeColorsLsKey = "theme-colors";
const themeModeLsKey = "theme-mode";

export interface ColorValue {
  r: number;
  g: number;
  b: number;
}

interface ITheme {
  loading: boolean;
  light: {
    primary: ColorValue;
    secondary: ColorValue;
    primaryContrast: ColorValue;
    secondaryContrast: ColorValue;
  };
  dark: {
    primary: ColorValue;
    secondary: ColorValue;
    primaryContrast: ColorValue;
    secondaryContrast: ColorValue;
  };
  isDark: boolean;
  logo?: string;
}

const initialState: ITheme = {
  loading: true,
  light: {
    primary: { r: 0, g: 115, b: 142 },
    secondary: { r: 240, g: 18, b: 87 },
    primaryContrast: { r: 0, g: 0, b: 0 },
    secondaryContrast: { r: 0, g: 0, b: 0 },
  },
  dark: {
    primary: { r: 0, g: 115, b: 142 },
    secondary: { r: 240, g: 18, b: 87 },
    primaryContrast: { r: 0, g: 0, b: 0 },
    secondaryContrast: { r: 0, g: 0, b: 0 },
  },
  isDark: false,
  logo: undefined,
};

interface ThemeContextValue {
  theme: ITheme;
  setTheme: Dispatch<ThemeReducerAction>;
}

const ThemeContext = createContext<ThemeContextValue>({
  theme: initialState,
  setTheme: () => {},
});

interface ThemeContextProviderProps {
  children: ReactNode | ReactNode[];
}

type ThemeReducerAction =
  | {
      type: "loading";
      payload: boolean;
    }
  | {
      type: "colors";
      payload: {
        light: { primary: ColorValue; secondary: ColorValue };
        dark: { primary: ColorValue; secondary: ColorValue };
      };
    }
  | {
      type: "logo";
      payload: string;
    }
  | {
      type: "dark";
      payload: boolean;
    }
  | {
      type: "reset";
    };

function getContrastColor({ r, g, b }: ColorValue): ColorValue {
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  return brightness > 125 ? { r: 0, g: 0, b: 0 } : { r: 255, g: 255, b: 255 };
}

function findThemeCSS() {
  const sheets = document.styleSheets;

  for (let i = 0; i < sheets.length; i++) {
    const sheet = sheets.item(i);
    if (sheet?.href?.includes("theme.css")) {
      return sheet;
    }
  }

  return undefined;
}

function setThemeCSS(
  lp: ColorValue,
  ls: ColorValue,
  dp: ColorValue,
  ds: ColorValue,
  lpc: ColorValue,
  lsc: ColorValue,
  dpc: ColorValue,
  dsc: ColorValue,
) {
  // Construct new css strings
  let lightCssText = `:root { --color-primary: ${lp.r} ${lp.g} ${lp.b}; --color-secondary: ${ls.r} ${ls.g} ${ls.b}; --color-primaryContrast: ${lpc.r} ${lpc.g} ${lpc.b}; --color-secondaryContrast: ${lsc.r} ${lsc.g} ${lsc.b}; ${lightThemeMixins} }`;
  let darkCssText = `:root[class~="dark"] { --color-primary: ${dp.r} ${dp.g} ${dp.b}; --color-secondary: ${ds.r} ${ds.g} ${ds.b}; --color-primaryContrast: ${dpc.r} ${dpc.g} ${dpc.b}; --color-secondaryContrast: ${dsc.r} ${dsc.g} ${dsc.b}; ${darkThemeMixins} }`;

  // Get the <style> tag in the head
  let style: CSSStyleSheet | null | undefined;

  style = findThemeCSS();

  let numRules = style!.cssRules.length;

  let lightIndex;

  // Find the index of the rule that contains the light defs
  for (let i = 0; i < numRules; i++) {
    let item = style!.cssRules.item(i);
    if (item?.cssText.includes(":root ")) {
      lightIndex = i;
    }
  }

  // If we found them, remove them and add the new rules
  if (lightIndex !== undefined) {
    style!.deleteRule(lightIndex);
    style!.insertRule(lightCssText);
  }

  // Re-get the style tag again to reflect any updates we made and do it all again for dark
  style = findThemeCSS();

  let darkIndex;

  for (let i = 0; i < numRules + 2; i++) {
    let item = style!.cssRules.item(i);
    if (item?.cssText.includes(':root[class~="dark"] ')) {
      darkIndex = i;
    }
  }

  if (darkIndex !== undefined) {
    style!.deleteRule(darkIndex);
    style!.insertRule(darkCssText);
  }
}

function themeReducer(state: ITheme, action: ThemeReducerAction): ITheme {
  switch (action.type) {
    case "loading":
      return { ...state, loading: action.payload };
    case "colors":
      let lpc = getContrastColor(action.payload.light.primary);
      let lsc = getContrastColor(action.payload.light.secondary);
      let dpc = getContrastColor(action.payload.dark.primary);
      let dsc = getContrastColor(action.payload.dark.secondary);

      setThemeCSS(
        action.payload.light.primary,
        action.payload.light.secondary,
        action.payload.dark.primary,
        action.payload.dark.secondary,
        lpc,
        lsc,
        dpc,
        dsc,
      );

      let finalPayload = {
        light: {
          ...action.payload.light,
          primaryContrast: lpc,
          secondaryContrast: lsc,
        },
        dark: {
          ...action.payload.dark,
          primaryContrast: dpc,
          secondaryContrast: dsc,
        },
      };

      localStorage.setItem(themeColorsLsKey, JSON.stringify(finalPayload));

      return { ...state, ...finalPayload };
    case "logo":
      return { ...state, logo: action.payload };
    case "dark":
      let htmlTag = document.querySelector("html");

      if (action.payload) {
        if (!htmlTag?.classList.contains("dark")) {
          htmlTag?.classList.add("dark");
        }
      } else {
        if (htmlTag?.classList.contains("dark")) {
          htmlTag?.classList.remove("dark");
        }
      }

      localStorage.setItem(
        themeModeLsKey,
        JSON.stringify({ isDark: action.payload }),
      );

      return { ...state, isDark: action.payload };
    case "reset":
      setThemeCSS(
        initialState.light.primary,
        initialState.light.secondary,
        initialState.dark.primary,
        initialState.dark.secondary,
        initialState.light.primaryContrast,
        initialState.light.secondaryContrast,
        initialState.dark.primaryContrast,
        initialState.dark.secondaryContrast,
      );

      localStorage.setItem(
        themeColorsLsKey,
        JSON.stringify({ light: initialState.light, dark: initialState.dark }),
      );

      return { ...initialState, dark: state.dark, loading: false };
    default:
      return state;
  }
}

export function ThemeContextProvider({ children }: ThemeContextProviderProps) {
  const [theme, setTheme] = useReducer(themeReducer, initialState);
  const { token, organisationId, isLoggedIn, loading } = useUserContext();
  const { get } = useApiHelper();

  const getBranding = () => {
    get<IDefaultResponseWithData<IInstallerBrandingGetResponse>>("/v1/brand", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        let lightPrimary = res.data.data[0].lightPrimaryColour;
        let lightSecondary = res.data.data[0].lightSecondaryColour;
        let darkPrimary = res.data.data[0].darkPrimaryColour;
        let darkSecondary = res.data.data[0].darkSecondaryColour;

        setTheme({
          type: "colors",
          payload: {
            light: {
              primary: {
                r: lightPrimary[0],
                g: lightPrimary[1],
                b: lightPrimary[2],
              },
              secondary: {
                r: lightSecondary[0],
                g: lightSecondary[1],
                b: lightSecondary[2],
              },
            },
            dark: {
              primary: {
                r: darkPrimary[0],
                g: darkPrimary[1],
                b: darkPrimary[2],
              },
              secondary: {
                r: darkSecondary[0],
                g: darkSecondary[1],
                b: darkSecondary[2],
              },
            },
          },
        });

        setTheme({ type: "logo", payload: res.data.data[0].logo });

        setTheme({ type: "loading", payload: false });
      })
      .catch((err) => {
        setTheme({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        getBranding();
      } else {
        setTheme({ type: "loading", payload: false });
      }
    }
  }, [isLoggedIn, loading, organisationId]);

  useEffect(() => {
    let themeColors = localStorage.getItem(themeColorsLsKey);
    let themeMode = localStorage.getItem(themeModeLsKey);

    if (themeColors) {
      setTheme({ type: "colors", payload: JSON.parse(themeColors) });
    }

    if (themeMode) {
      setTheme({ type: "dark", payload: JSON.parse(themeMode).isDark });
    } else {
      setTheme({
        type: "dark",
        payload: document.documentElement.classList.contains("dark"),
      });
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {theme.loading ? <FullscreenLoading /> : children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const themeCtx = useContext(ThemeContext);

  if (!themeCtx) {
    throw Error("useTheme() must be used within a ThemeContextProvider");
  }

  return themeCtx;
}
