import { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import useApiHelper from "../../hooks/useApiHelper";
import { Button } from "../../components/base/Button";
import { useTheme } from "../../contexts/ThemeContext";
import { applicationId, authAPIURL } from "../../config";
import { TextField } from "../../components/base/TextField";
import { useUserContext } from "../../contexts/UserContext";
import { Alert, AlertProps } from "../../components/base/Alert";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { SSOTokenRequest } from "../../types/requests/sso/SSOTokenRequest";
import { SSOLoginResponse } from "../../types/responses/sso/SSOLoginResponse";
import { SSOTokenResponse } from "../../types/responses/sso/SSOTokenResponse";
import { MFAVerifyRepsonse as MFAVerifyResponse } from "../../types/responses/mfa/MFAVerifyResponse";
import {
  SSOLoginFormFields,
  SSOLoginRequest,
} from "../../types/requests/sso/SSOLoginRequest";
import {
  MFAVerifyFormFields,
  MFAVerifyRequest,
} from "../../types/requests/mfa/MFAVerifyRequest";

export function Login() {
  const navigate = useNavigate();
  const { post } = useApiHelper({
    apiUrl: authAPIURL,
    withCredentials: true,
    addToken: false,
  });
  const { loading, isLoggedIn, setUser } = useUserContext();
  const { setTheme } = useTheme();
  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    watch: watchLogin,
    setValue: setValueLogin,
  } = useForm<SSOLoginFormFields>();
  const { register: registerMFA, handleSubmit: handleSubmitMFA } =
    useForm<MFAVerifyFormFields>();
  const [submitting, setSubmitting] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [organisationId, setOrganisationId] = useState<string>();
  const [mfaRecovery, setMfaRecovery] = useState(false);
  const [error, setError] = useState<AlertProps | undefined>();

  const getToken = async (organisationId: string) => {
    try {
      let tokenResponse = await post<SSOTokenRequest, SSOTokenResponse>(
        "/v1/sso/token",
        {
          organisationId: organisationId,
          type: "portal",
        },
      );

      let decodedToken = jwtDecode<{ oid: string; uid: string }>(
        tokenResponse.data.token!,
      );

      setTheme({ type: "loading", payload: true });
      setUser({ type: "loading", payload: true });
      setUser({
        type: "login",
        payload: {
          token: tokenResponse.data.token!,
          userId: decodedToken.uid,
          organisationId: decodedToken.oid,
        },
      });
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError({ severity: "error", message: err.response.data.message });
      } else {
        setError({
          severity: "error",
          message: "An unknown error has occurred",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgottenPassword = () => {
    if (watchLogin("email") !== "") {
      navigate("/forgotten-password", {
        state: { email: watchLogin("email") },
      });
    } else {
      navigate("/forgotten-password");
    }
  };

  const handleVerifyMFA = async (mfaDetails: MFAVerifyFormFields) => {
    setError(undefined);
    setSubmitting(true);

    try {
      await post<MFAVerifyRequest, MFAVerifyResponse>(
        `/v1/mfa/${mfaRecovery ? "recover" : "verify"}`,
        {
          code: mfaDetails.code,
        },
      );

      getToken(organisationId!);
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError({ severity: "error", message: err.response.data.message });
      } else {
        setError({
          severity: "error",
          message: "An unknown error has occurred",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleLogin = async (loginDetails: SSOLoginFormFields) => {
    setError(undefined);
    setSubmitting(true);

    setValueLogin("email", loginDetails.email.toLowerCase());

    const data = {
      ...loginDetails,
      email: loginDetails.email.toLowerCase(),
    };

    try {
      const loginResponse = await post<SSOLoginRequest, SSOLoginResponse>(
        "/v1/sso/login",
        {
          ...data,
          applicationId: applicationId,
        },
      );

      if (loginResponse.data.mfaEnabled) {
        setOrganisationId(loginResponse.data.organisationId);
        setMfaRequired(true);
        setSubmitting(false);
      } else {
        getToken(loginResponse.data.organisationId ?? "");
      }
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError({ severity: "error", message: err.response.data.message });
      } else {
        setError({
          severity: "error",
          message: "An unknown error has occurred",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!loading && isLoggedIn) {
      navigate("/");
    }
  }, [loading, isLoggedIn, navigate]);

  return (
    <div className="w-10/12 xl:w-8/12 mx-auto">
      {submitting ? (
        <SectionLoading />
      ) : (
        <>
          <div className="mb-6 text-4xl font-medium dark:text-white">
            {mfaRequired ? "Multi-factor Verification" : "Login"}
          </div>
          {error && <Alert {...error} className="mb-6" />}
          {!mfaRequired ? (
            <form onSubmit={handleSubmitLogin(handleLogin)}>
              <div className="space-y-2">
                <TextField
                  {...registerLogin("email")}
                  fullWidth
                  type="email"
                  placeholder="Email"
                />
                <TextField
                  {...registerLogin("password")}
                  fullWidth
                  type="password"
                  placeholder="Password"
                />
              </div>
              <div className="space-y-4 mt-6">
                <Button fullWidth type="submit">
                  Login
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  size="sm"
                  onClick={handleForgottenPassword}
                >
                  Forgotten password?
                </Button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmitMFA(handleVerifyMFA)}>
              <div className="space-y-2">
                <TextField
                  {...registerMFA("code")}
                  fullWidth
                  placeholder={mfaRecovery ? "Recovery Code" : "OTP"}
                />
              </div>
              <div className="space-y-4 mt-6">
                <Button fullWidth type="submit">
                  Login
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  size="sm"
                  onClick={
                    mfaRecovery
                      ? () => setMfaRecovery(false)
                      : () => setMfaRecovery(true)
                  }
                >
                  Use {mfaRecovery ? "OTP" : "Recovery Code"}
                </Button>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
}
