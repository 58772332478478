import { useNotification } from "./useNotifications";

export function useFreeTierNotification() {
  const addNotification = useNotification();

  const showFreeTierMessage = () =>
    addNotification(
      "info",
      "Actions are not permitted on Free tier! Upgrade today",
    );

  return showFreeTierMessage;
}
