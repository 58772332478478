import { useQuery } from "react-query";

import { metaDataQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IMetadata } from "../../types/Metadata/Metadata";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useMetadata() {
  const { get } = useApiHelper();

  const defaultValues = useQuery({
    queryKey: metaDataQueryKeys.all(),
    queryFn: () =>
      get<IDefaultResponseWithData<IMetadata>>("/v1/utilities/defaults").then(
        (res) => res.data.data
      ),
  });

  return {
    defaultValues,
  };
}
