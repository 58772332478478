import { useEffect, useState } from "react";

import { UseMutationResult } from "react-query";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

import { Button } from "../../base/Button";
import { LoadingWheel } from "../../base/LoadingWheel";
import { LiveViewEdit } from "../../shared/LiveViewEdit";
import { Modal, ModalControlProps } from "../../../layouts/Modal";
import { DataPointConfiguration } from "./DataPointConfiguration";
import { ISingleTile, TileVisability } from "../../../types/Tile/Tile";
import { useTemplateEditContext } from "../../../contexts/TemplateEditContext";
import {
  ITemplateConfiguration,
  TemplateCreateUpdateRequest,
} from "../../../types/Template/Template";
import { TextField } from "../../base/TextField";
import useDataPointsByHardwareModel from "../../../data/datapoint/useDataPointsByHardwareModel";

interface CreateEditTemplateModalProps extends ModalControlProps {
  template: ITemplateConfiguration;
  updateMutationFn: UseMutationResult<
    ITemplateConfiguration[],
    unknown,
    TemplateCreateUpdateRequest,
    unknown
  >;
}

const keyInformation = [
  {
    icon: ["fas", "lock"],
    description:
      "Core datapoint - core datapoints can be removed from the template but cannot be outright disabled",
  },
  {
    icon: ["fas", "toggle-on"],
    description:
      "Control enabled - users will be able to use the control aspect of this datapoint",
  },
  {
    icon: ["fas", "toggle-off"],
    description:
      "Control disabled - users will not be able to use the control aspect of this datapoint",
  },
];

export function CreateEditTemplateModal({
  template,
  open,
  onClose,
  updateMutationFn,
}: CreateEditTemplateModalProps) {
  const { state: templateState, dispatch } = useTemplateEditContext();
  const { dataPoints } = useDataPointsByHardwareModel(
    template ? template.template.hardwareModelId : ""
  );
  const [templateName, setTemplateName] = useState(
    template ? template.template.name : ""
  );
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    dispatch({ type: "reset" });
    onClose(true);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    let tiles: ISingleTile[] = [];

    templateState.avaliableDataPoints.forEach((dp) => {
      tiles.push({
        dataPointId: dp.dataPointId,
        visability: dp.hide ? TileVisability.exclude : TileVisability.hide,
        control: dp.control,
      });
    });

    templateState.visibleDataPoints.forEach((dp) => {
      tiles.push({
        dataPointId: dp.dataPointId,
        visability: TileVisability.show,
        control: dp.control,
      });
    });

    updateMutationFn.mutate(
      {
        ...template,
        template: {
          ...template.template,
          name: templateName,
          tiles: tiles,
        },
      },
      {
        onSuccess: () => {
          handleClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  useEffect(() => {
    if (
      !dataPoints.isLoading &&
      dataPoints.data &&
      template.template.tiles.length > 0 &&
      templateState.avaliableDataPoints.length === 0
    ) {
      dispatch({
        type: "loadFromTemplate",
        dataPoints: dataPoints.data!,
        template: template,
      });
    } else if (
      !dataPoints.isLoading &&
      dataPoints.data &&
      templateState.avaliableDataPoints.length === 0
    ) {
      dispatch({
        type: "loadFromDataPoints",
        dataPoints: dataPoints.data!,
      });
    }

    setTemplateName(template ? template.template.name : "");
  }, [
    dataPoints.isLoading,
    dataPoints.data,
    templateState.avaliableDataPoints.length,
    template,
    dispatch,
  ]);

  return (
    <Modal
      dialogStatic
      title={`${template && template.template.tiles.length > 0 ? "Edit" : "Create"} Template`}
      open={open}
      onClose={onClose}
    >
      {dataPoints.isLoading || submitting ? (
        <div className="flex py-10 items-center justify-center">
          <LoadingWheel />
        </div>
      ) : (
        <>
          <div className="mt-6 md:w-5/6 mx-auto">
            <TextField
              label="Template Name"
              value={templateName}
              onChange={(e) => setTemplateName(e.currentTarget.value)}
            />
          </div>
          <LiveViewEdit />
          <DataPointConfiguration />
        </>
      )}
      <div className="md:w-5/6 my-6 mx-auto rounded bg-primary/10 border border-primary">
        <table className="w-full">
          <tbody className="divide-y divide-primary">
            {keyInformation.map((key, i) => (
              <tr>
                <td width="8%" className="py-2">
                  <div className="w-full h-full px-2 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={[
                        key.icon[0] as IconPrefix,
                        key.icon[1] as IconName,
                      ]}
                      fontSize={18}
                    />
                  </div>
                </td>
                <td className="py-2">{key.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center space-x-4">
        <Button onClick={handleSubmit}>Save</Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
}
