import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IDevice } from "../../types/Device/Device";

export default function useDevices() {
  const { get } = useApiHelper();

  const devices = useQuery({
    queryKey: ["devices"],
    queryFn: () =>
      get<IDefaultResponseWithData<IDevice>>("/v1/device").then(
        (res) => res.data.data,
      ),
  });

  return {
    devices,
  };
}
