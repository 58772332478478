import { useUserContext } from "../../contexts/UserContext";
import { CustomerTemplates } from "./Templates/CustomerTemplates";
import { InstallerTemplates } from "./Templates/InstallerTemplates";

export function TemplatesTab() {
  const { installerId } = useUserContext();

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-2xl font-semibold leading-7 text-primary">
            Templates
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-900 dark:text-gray-200">
            View, create and edit your templates.
          </p>
          <div className="mt-6 space-y-6 divide-gray-300 border-t border-gray-400 text-sm leading-6 dark:divide-gray-600 dark:border-gray-500">
            {installerId ? <CustomerTemplates /> : <InstallerTemplates />}
          </div>
        </div>
      </div>
    </>
  );
}
