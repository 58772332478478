import { useEffect } from "react";

import { Alerts } from "../components/Dashboard/Alerts";
import { Jobs } from "../components/Dashboard/Jobs";
import { Customers } from "../components/Dashboard/Customers";
import { Devices } from "../components/Dashboard/Devices";

import { usePageTitle } from "../contexts/PageTitleContext";

export function DashboardPage() {
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle("Dashboard");
  }, [setPageTitle]);

  return (
    <div className="w-full h-full grid gap-4 lg:grid-cols-2">
      <Alerts />
      <Jobs />
      <Customers />
      <Devices />
    </div>
  );
}
