import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/base/Button";
import { useNotification } from "./useNotifications";

export function useClipboardCopy() {
  const addNotification = useNotification();

  const handleCopy = (copyText: string) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        addNotification("success", "Text copied!");
      })
      .catch((_) => {
        addNotification("error", "Failed to copy text");
      });
  };

  const CopyToClipboardButton = ({
    copyText,
    iconOnly = false,
  }: {
    copyText: string;
    iconOnly?: boolean;
  }) =>
    !iconOnly ? (
      <Button size="xs" onClick={() => handleCopy(copyText)}>
        <ClipboardDocumentIcon className="w-4 h-4" />
      </Button>
    ) : (
      <ClipboardDocumentIcon
        className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
        onClick={() => handleCopy(copyText)}
      />
    );

  return { handleCopy, CopyToClipboardButton };
}
