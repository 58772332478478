import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { ICustomer } from "../../types/Customer/Customer";

export default function useCustomers() {
  const { get } = useApiHelper();

  const customers = useQuery({
    queryKey: ["installer", "customers"],
    queryFn: () =>
      get<IDefaultResponseWithData<ICustomer>>("/v1/installer/customers").then(
        (res) => res.data.data,
      ),
  });

  return {
    customers,
  };
}
