export function alphabeticalSort<T>(propertyExtractor: (item: T) => string) {
  return (a: T, b: T): number => {
    const propA = propertyExtractor(a).toLowerCase();
    const nameB = propertyExtractor(b).toLowerCase();

    if (propA < nameB) {
      return -1;
    } else if (propA > nameB) {
      return 1;
    } else {
      return 0;
    }
  };
}
