import { useEffect, useState } from "react";

import clsx from "clsx";
import { ActionSendButton } from "./ActionSendButton";
import { BaseActionProps } from "../../../types/Action/Action";
import { useFreeTierNotification } from "../../../hooks/useFreeTierNotification";

interface ToggleActionProps extends BaseActionProps {
  currentValue: number | boolean;
  leftLabel?: string;
  rightLabel?: string;
}

/**
 * A value input with simple up/down pickers, takes a variety of props to make it suitable for different situations
 *
 * @component
 * @param {number} currentValue required - gives a starting point for the control.
 * @param {string} leftLabel defaults to "on" - label for the left side of the toggle.
 * @param {string} rightLabel defaults to "off" - label for the right side of the toggle.
 * @param {function} onApply required - function to run when the user clicks apply.
 * @param {string} actionId required - ID of this action.
 * @param {boolean} pendingAction required - if true disables send button
 */

export function ToggleAction({
  currentValue,
  leftLabel = "On",
  rightLabel = "Off",
  onApply,
  actionId,
  pendingAction,
  disabled = false,
  paidTier = false,
}: ToggleActionProps) {
  const showFreeTierMessage = useFreeTierNotification();
  const initialValue = currentValue === 1 ? true : false;
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleApply = () => {
    onApply(value ? "1" : "0", actionId);
    setBlockUpdate(false);
  };

  const handleToggle = () => {
    setValue((cv) => !cv);
    setBlockUpdate(true);
  };

  useEffect(() => {
    if (!blockUpdate) {
      setValue(initialValue);
    }
  }, [blockUpdate, initialValue]);

  const isCurrentValue = value === initialValue;

  return (
    <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
      <div className="md:col-span-6">
        <div className="w-full h-14 flex flex-row border bg-primary border-primary rounded-sm">
          <div
            onClick={
              Boolean(!value || pendingAction !== "idle" || disabled)
                ? () => {}
                : !paidTier
                  ? showFreeTierMessage
                  : handleToggle
            }
            className={clsx(
              "flex pt-1 items-center justify-center w-1/2 text-4xl font-medium select-none",
              pendingAction !== "idle" || disabled || !paidTier
                ? "bg-gray-200 hover:curser-default hover:bg-gray-200 text-gray-400"
                : !value
                  ? "bg-primary text-white"
                  : "bg-white text-gray-400 hover:cursor-pointer hover:bg-white/10 hover:text-white"
            )}
          >
            {leftLabel}
          </div>
          <div
            onClick={
              Boolean(value || pendingAction !== "idle" || disabled)
                ? () => {}
                : !paidTier
                  ? showFreeTierMessage
                  : handleToggle
            }
            className={clsx(
              "flex pt-1 items-center justify-center w-1/2 text-4xl font-medium select-none",
              pendingAction !== "idle" || disabled || !paidTier
                ? "bg-gray-200 hover:curser-default hover:bg-gray-200 text-gray-400"
                : value
                  ? "bg-primary text-white"
                  : "bg-white text-gray-400 hover:cursor-pointer hover:bg-white/10 hover:text-white"
            )}
          >
            {rightLabel}
          </div>
        </div>
      </div>
      <div className="md:col-span-2">
        <ActionSendButton
          onClick={handleApply}
          pendingAction={pendingAction}
          disabled={
            pendingAction === "timedOut" ? false : isCurrentValue || disabled
          }
          paidTier={paidTier}
        />
      </div>
    </div>
  );
}
