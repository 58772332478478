import useDataPointFunctions from "../data/datapoint/useDataPointFunctions";
import useDataPointsByHardwareModel from "../data/datapoint/useDataPointsByHardwareModel";

const useDataPointLookup = (hardwareModelId?: string) => {
  const { dataPoints } = useDataPointsByHardwareModel(hardwareModelId);

  const lookup = (dataPointId: string) =>
    dataPoints.data?.find((d) => d.id === dataPointId);

  return {
    isLoading: dataPoints.isLoading,
    lookup,
  };
};

const useDataPointFunctionLookup = () => {
  const { dataPointFunctions } = useDataPointFunctions();

  const lookup = (functionId: string) =>
    dataPointFunctions.data?.find((f) => f.id === functionId);

  return {
    isLoading: dataPointFunctions.isLoading,
    lookup,
  };
};

export const useDataPointLookups = (hardwareModelId?: string) => {
  const { isLoading: dataPointLoading, lookup: dataPointLookup } =
    useDataPointLookup(hardwareModelId);

  const { isLoading: functionsLoading, lookup: functionsLookup } =
    useDataPointFunctionLookup();

  const isLoading = dataPointLoading || functionsLoading;

  return {
    isLoading,
    dataPointLookup,
    functionsLookup,
  };
};
