import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IJobCategory } from "../../types/JobCategory/JobCategory";

export default function useJobCategories() {
  const { get } = useApiHelper();

  const jobCategories = useQuery({
    queryKey: ["job", "categories"],
    queryFn: () =>
      get<IDefaultResponseWithData<IJobCategory>>("/v1/job/categories").then(
        (res) => res.data.data,
      ),
  });

  return {
    jobCategories,
  };
}
