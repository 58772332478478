import { useMemo } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { ITemplateConfiguration } from "../../../types/Template/Template";
import { useHardwareLookups } from "../../../hooks/useHardwareLookups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface UserRowProps {
  template: ITemplateConfiguration;
  onEdit: (template: ITemplateConfiguration) => void;
  onDelete: (template: ITemplateConfiguration) => void;
}

export function TemplateListRow({ template, onEdit, onDelete }: UserRowProps) {
  const { manufacturerLookup, modelLookup } = useHardwareLookups();

  const makeModelString = useMemo(
    () => `${manufacturerLookup(template.template.hardwareManufacturerId)?.name}
  - ${modelLookup(template.template.hardwareModelId)?.name}`,
    [
      manufacturerLookup,
      modelLookup,
      template.template.hardwareManufacturerId,
      template.template.hardwareModelId,
    ]
  );

  return (
    <div
      key={template.id}
      className="relative flex items-center space-x-4 px-6 py-5"
    >
      <div className="flex-shrink-0">
        <FontAwesomeIcon icon={["fas", "vault"]} fontSize={32} />
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
          {makeModelString}
        </p>
        <p className="truncate text-sm text-gray-500">
          {template.template.name}
        </p>
      </div>
      <div className="block">
        <PencilIcon
          className="w-6 h-6 opacity-70 hover:opacity-100 hover:cursor-pointer"
          onClick={() => onEdit(template)}
        />
      </div>
      <div className="block">
        <TrashIcon
          className="w-6 h-6 opacity-70 hover:opacity-100 hover:cursor-pointer"
          onClick={() => onDelete(template)}
        />
      </div>
    </div>
  );
}
