import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IOrganisation } from "../../types/Organisation/Organisation";

export default function useOrganisation() {
  const { get } = useApiHelper();

  const organisation = useQuery({
    queryKey: ["organisation"],
    queryFn: () =>
      get<IDefaultResponseWithData<IOrganisation>>("/v1/organisation").then(
        (res) => res.data.data[0],
      ),
  });

  return {
    organisation,
  };
}
