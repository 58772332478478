import React from "react";

import clsx from "clsx";
import {
  FieldError,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import ThemeColorOptions from "../../../types/system/ThemeColorOption";
import { getErrorTypeText } from "./getErrorTypeText";
import { error } from "console";
import { getByPath } from "../../../utils/getByPatth";

interface FormTextFieldProps extends React.HTMLProps<HTMLInputElement> {
  fullWidth?: boolean;
  small?: boolean;
  color?: ThemeColorOptions;
  label?: string;
}

export const FormTextField = React.forwardRef<any, FormTextFieldProps>(
  (
    {
      fullWidth = false,
      className,
      small = false,
      color = "primary",
      label,
      disabled,
      name,
      ...rest
    },
    ref,
  ) => {
    const {
      formState: { errors },
    } = useFormContext();

    const errorKey = name as string;
    const extractedError = getByPath(errorKey, errors);

    const getErrorText = () => {
      if (extractedError) {
        if (extractedError?.message) {
          return extractedError?.message as string;
        } else {
          return `${label} is ${getErrorTypeText(
            extractedError?.type as string,
          )}`;
        }
      } else {
        return `${label} is ${getErrorTypeText(
          errors[errorKey]?.type as string,
        )}`;
      }
    };

    return (
      <div className="flex flex-col space-y-1">
        {label && (
          <label className="text-sm text-black font-medium opacity-80 dark:text-white">
            {label}
          </label>
        )}
        <input
          className={clsx(
            "rounded border outline-none focus:drop-shadow-none focus:ring-2",
            fullWidth && "w-full",
            disabled
              ? "bg-gray-200 text-gray-800 dark:bg-gray-500 dark:text-gray-300 cursor-not-allowed"
              : "bg-white text-black dark:bg-gray-700 dark:text-white",
            color === "primary"
              ? "!border-primary focus:ring-primary"
              : "!border-secondary focus:ring-secondary",
            small ? "px-1 py-0.5" : "px-3 py-2",
            className,
            extractedError && "!border-red-600 focus:!ring-red-600",
          )}
          disabled={disabled}
          ref={ref}
          name={name}
          aria-label={label}
          {...rest}
        />
        {extractedError && (
          <p className="text-xs text-red-500">{getErrorText()}</p>
        )}
      </div>
    );
  },
);
