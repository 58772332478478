import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Select } from "../base/Select";
import { Button } from "../base/Button";
import { Modal } from "../../layouts/Modal";
import { TextField } from "../base/TextField";
import { DataPointSelect } from "./DataPointSelect";
import { ModelSelect } from "../Hardware/ModelSelect";
import { SectionLoading } from "../shared/SectionLoading";
import useHardwareTypes from "../../data/hardware/useHardwareTypes";
import { ManufacturerSelect } from "../Hardware/ManufacturerSelect";
import { IDataPointTransformation } from "../../types/DataPoint/DataPointTransformation";
import useSuperAdminDataPointTransformationMutations from "../../data/datapoint/superadmin/useSuperAdminDataPointTransformationMutation";
import useDataPointFunctions from "../../data/datapoint/useDataPointFunctions";
import { useGlobalHardwareModelFilter } from "../../contexts/GlobalHardwareFilterContext";
import { GlobalHardwareModelFilterBanner } from "../shared/GlobalHardwareModelFilterBanner";
import { Toggle } from "../base/Toggle";

export interface CreateEditDataPointTransformationProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  dataPointTransformation?: IDataPointTransformation;
}

export function CreateEditDataPointTransformation({
  open,
  onClose,
  dataPointTransformation,
}: CreateEditDataPointTransformationProps) {
  const { register, reset, handleSubmit, watch, resetField } =
    useForm<IDataPointTransformation>();
  const { hardwareTypes } = useHardwareTypes();
  const { dataPointFunctions } = useDataPointFunctions();
  const { createDataPointTransformation, updateDataPointTransformation } =
    useSuperAdminDataPointTransformationMutations();
  const { globalHardwareModelFilter } = useGlobalHardwareModelFilter();
  const [backend, setBackend] = useState(false);

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createDataPointTransformation.mutate({
      ...data,
      bit: data.bit !== "" ? data.bit : null,
      backend: backend,
    });
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateDataPointTransformation.mutate({
      ...dataPointTransformation,
      ...data,
      bit: data.bit !== "" ? data.bit : null,
      backend: backend,
    });
    handleClose();
  };

  useEffect(() => {
    if (dataPointTransformation) {
      reset({
        hardwareTypeId: dataPointTransformation.hardwareTypeId,
        hardwareManufacturerId: dataPointTransformation.hardwareManufacturerId,
        hardwareModelId: dataPointTransformation.hardwareModelId,
        dataPointId: dataPointTransformation.dataPointId,
        arguments: dataPointTransformation.arguments,
        order: dataPointTransformation.order,
        hardwareModel: dataPointTransformation.hardwareModel,
      });
      setBackend(dataPointTransformation.backend);
    } else if (globalHardwareModelFilter) {
      reset({
        hardwareModelId: globalHardwareModelFilter.id,
        hardwareManufacturerId:
          globalHardwareModelFilter.hardwareManufacturerId,
        hardwareTypeId: globalHardwareModelFilter.hardwareTypeId,
        order: 0,
      });
    } else {
      reset({ order: 0 });
    }
  }, [
    open,
    reset,
    dataPointTransformation,
    hardwareTypes.data,
    hardwareTypes.isLoading,
    globalHardwareModelFilter,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${
        dataPointTransformation ? "Edit" : "Create"
      } Data Point Transformation`}
      description={`Use this form to ${
        dataPointTransformation ? "edit" : "create"
      } a data point transformation`}
    >
      <GlobalHardwareModelFilterBanner />
      {hardwareTypes.isLoading || dataPointFunctions.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(
            dataPointTransformation ? handleUpdate : handleCreate
          )}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <Select
            {...register("hardwareTypeId", {
              value: "",
            })}
            label="Type"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {hardwareTypes.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <ManufacturerSelect
            {...register("hardwareManufacturerId", {
              onChange: () => {
                resetField("hardwareModelId");
                resetField("dataPointId");
              },
            })}
            type={watch("hardwareTypeId")}
          />
          <ModelSelect
            {...register("hardwareModelId", {
              onChange: () => {
                resetField("dataPointId");
              },
            })}
            manufacturer={watch("hardwareManufacturerId")}
          />
          <DataPointSelect
            filterForDataPointTransformations
            {...register("dataPointId")}
            type={watch("hardwareTypeId")}
            model={watch("hardwareModelId")}
            selected={dataPointTransformation?.dataPointId}
          />
          <Select
            {...register("functionId", {
              value: dataPointTransformation
                ? dataPointTransformation.functionId
                : "",
            })}
            label="Function"
          >
            <option disabled hidden value="">
              Please Select
            </option>
            {dataPointFunctions.data?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Select>
          <TextField {...register("arguments")} label="Arguments" />
          <TextField {...register("order")} label="Order" />
          <div className="flex items-center">
            <Toggle
              className="mt-1"
              label="Backend only"
              checked={backend}
              onChange={(val) => setBackend(val)}
            />
          </div>
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">
              {dataPointTransformation ? "Update" : "Create"}
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
