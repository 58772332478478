import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { cookieSettings, pdOrgId } from "../config";
import { userLsKey } from "../constants/common";
import useApiHelper from "../hooks/useApiHelper";
import { IOrganisation } from "../types/Organisation/Organisation";
import { IDefaultResponseWithData } from "../types/system/DefaultResponse";
import { Cookies } from "react-cookie";

interface IUserContext {
  token?: string;
  userId?: string;
  organisationId?: string;
  organisationName?: string;
  partnerId?: string;
  installerId?: string;
  installerName?: string;
  superAdminId?: string;
  superAdminName?: string;
  isLoggedIn: boolean;
  isInstaller: boolean;
  isSuperAdmin: boolean;
  loading: boolean;
}

const initialState: IUserContext = {
  token: undefined,
  userId: undefined,
  organisationId: undefined,
  organisationName: undefined,
  partnerId: undefined,
  installerId: undefined,
  installerName: undefined,
  superAdminId: undefined,
  superAdminName: undefined,
  isLoggedIn: false,
  isInstaller: false,
  isSuperAdmin: false,
  loading: true,
};

interface UserContextValue {
  user: IUserContext;
  setUser: Dispatch<UserContextReducerAction>;
  reload: VoidFunction;
}

export const UserContext = createContext<UserContextValue>({
  user: initialState,
  setUser: () => {},
  reload: () => {},
});

interface UserContextProviderProps {
  children: ReactElement | ReactElement[];
}

type UserContextReducerAction =
  | {
      type: "login";
      payload: { token: string; userId: string; organisationId: string };
    }
  | {
      type: "setToken";
      payload: { token: string };
    }
  | {
      type: "orgInfo";
      payload: {
        organisationId: string;
        organisationName: string;
        isInstaller: boolean;
        partnerId?: string;
      };
    }
  | { type: "logout" }
  | {
      type: "installerActAs";
      payload: { token: string; organisationId: string };
    }
  | {
      type: "installerSwitchBack";
      payload: { token: string; organisationId: string };
    }
  | {
      type: "installerLoginAs";
      payload: {
        token: string;
        organisationId: string;
        installerId: string;
        installerName: string;
      };
    }
  | {
      type: "superAdminActAs";
      payload: { token: string; organisationId: string };
    }
  | { type: "superAdminSwitchBack" }
  | { type: "loading"; payload: boolean }
  | { type: "restore"; payload: Omit<IUserContext, "token"> };

function userContextReducer(
  state: IUserContext,
  action: UserContextReducerAction,
): IUserContext {
  const cookies = new Cookies(undefined, cookieSettings);
  switch (action.type) {
    case "loading":
      let loadingState = {
        ...state,
        loading: action.payload,
      };

      cookies.set(userLsKey, loadingState);

      // localStorage.setItem(userLsKey, JSON.stringify(loadingState));

      return loadingState;
    case "login":
      let loginState = {
        ...state,
        userId: action.payload.userId,
        organisationId: action.payload.organisationId,
        isLoggedIn: true,
        isSuperAdmin: action.payload.organisationId === pdOrgId,
        token: action.payload.token,
      };

      cookies.set(userLsKey, loginState);
      // localStorage.setItem(userLsKey, JSON.stringify(loginState));

      return loginState;
    case "setToken":
      let updatedState = {
        ...state,
        token: action.payload.token,
      };

      cookies.set(userLsKey, updatedState);
      // localStorage.setItem(userLsKey, JSON.stringify(updatedState));

      return updatedState;
    case "orgInfo":
      return { ...state, ...action.payload, loading: false };
    case "installerActAs":
      let installerActAsState = {
        ...state,
        installerId: state.organisationId,
        installerName: state.organisationName,
        token: action.payload.token,
        organisationId: action.payload.organisationId,
      };

      cookies.set(userLsKey, installerActAsState);
      // localStorage.setItem(userLsKey, JSON.stringify(installerActAsState));

      return installerActAsState;
    case "installerSwitchBack":
      let installerSwitchBackState = {
        ...state,
        organisationId: action.payload.organisationId,
        installerId: undefined,
        installerName: undefined,
        token: action.payload.token,
      };

      cookies.set(userLsKey, installerSwitchBackState);
      // localStorage.setItem(userLsKey, JSON.stringify(installerSwitchBackState));

      return installerSwitchBackState;
    case "installerLoginAs":
      let installerLoginAs = {
        ...state,
        installerId: action.payload.installerId,
        installerName: action.payload.installerName,
        token: action.payload.token,
        organisationId: action.payload.organisationId,
      };

      return installerLoginAs;
    case "superAdminActAs":
      let superAdminActAsState = {
        ...state,
        superAdminId: state.organisationId,
        superAdminName: state.organisationName,
        token: action.payload.token,
        organisationId: action.payload.organisationId,
      };

      cookies.set(userLsKey, superAdminActAsState);
      // localStorage.setItem(userLsKey, JSON.stringify(superAdminActAsState));

      return superAdminActAsState;
    case "superAdminSwitchBack":
      let superAdminSwitchBackState = {
        ...state,
        superAdminId: undefined,
        superAdminName: undefined,
        installerId: undefined,
        installerName: undefined,
      };

      cookies.set(userLsKey, superAdminSwitchBackState);
      // localStorage.setItem(
      //   userLsKey,
      //   JSON.stringify(superAdminSwitchBackState)
      // );

      return superAdminSwitchBackState;
    case "logout":
      cookies.remove(userLsKey);
      // localStorage.removeItem(userLsKey);
      return { ...initialState, loading: false };
    case "restore":
      return { ...state, ...action.payload, loading: true };
    default:
      return state;
  }
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useReducer(userContextReducer, initialState);
  const { get } = useApiHelper();
  const cookies = new Cookies(undefined, cookieSettings);

  const loadOrg = () => {
    if (user.organisationId) {
      get<IDefaultResponseWithData<IOrganisation>>("/v1/organisation", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => {
          let org = res.data.data[0];
          setUser({
            type: "orgInfo",
            payload: {
              organisationId: org.id,
              organisationName: org.organisationName,
              isInstaller: org.isPartner,
              partnerId: org.partnerId,
            },
          });
          setUser({ type: "loading", payload: false });
        })
        .catch((err) => {
          console.error(err);
          setUser({ type: "loading", payload: false });
        });
    }
  };

  useEffect(() => {
    if (user.loading) {
      let userState = cookies.get(userLsKey);

      //If user state was present in LS and user isn't already loaded
      if (userState && !user.organisationId) {
        if (userState.isLoggedIn) {
          setUser({ type: "restore", payload: userState });
        } else {
          setUser({ type: "loading", payload: false });
        }
      } else {
        //No user, loading needs setting to false to show login
        setUser({ type: "loading", payload: false });
      }
    }
  }, []);

  useEffect(() => {
    loadOrg();
  }, [user.organisationId]);

  return (
    <UserContext.Provider value={{ user, setUser, reload: loadOrg }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const userCtx = useContext(UserContext);

  if (!userCtx) {
    throw Error("useUserContext() must be used within a UserContextProvider");
  }

  return {
    loading: userCtx.user.loading,
    isLoggedIn: userCtx.user.isLoggedIn,
    isInstaller: userCtx.user.isInstaller,
    isSuperAdmin: userCtx.user.isSuperAdmin,
    token: userCtx.user.token,
    userId: userCtx.user.userId,
    organisationId: userCtx.user.organisationId,
    organisationName: userCtx.user.organisationName,
    partnerId: userCtx.user.partnerId,
    installerId: userCtx.user.installerId,
    installerName: userCtx.user.installerName,
    superAdminId: userCtx.user.superAdminId,
    superAdminName: userCtx.user.superAdminName,
    setUser: userCtx.setUser,
    reload: userCtx.reload,
  };
}
