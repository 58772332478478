import { Card } from "../base/Card";
import { HardwareSelection } from "./HardwareSelection";
import { TimespanGranularity } from "./TimespanGranularity";

export function GraphSettings() {
  return (
    <Card className="mb-6">
      <div className="divide-y dark:divide-gray-500">
        <div className="pb-4">
          <HardwareSelection />
        </div>
        <div className="pt-3 md:pt-4">
          <TimespanGranularity />
        </div>
      </div>
    </Card>
  );
}
