import clsx from "clsx";
import { IJobCategory } from "../../types/JobCategory/JobCategory";
import { useCategoryLookups } from "../../hooks/useCategoryLookups";
import { useEffect, useState } from "react";

interface CategoryPillFromCategoryID {
  categoryId: string;
  category?: never;
}

interface CategoryPillFromCategory {
  categoryId?: never;
  category: IJobCategory;
}

type CategoryPillProps = CategoryPillFromCategory | CategoryPillFromCategoryID;

export function CategoryPill({ categoryId, category }: CategoryPillProps) {
  const { categoryLookup, isLoading } = useCategoryLookups();
  const [resolvedCategory, setResolvedCategory] = useState<
    IJobCategory | undefined
  >(undefined);

  useEffect(() => {
    if (category) {
      setResolvedCategory(category);
    } else if (!isLoading && !resolvedCategory) {
      setResolvedCategory(categoryLookup(categoryId)!);
    }
  }, [category, categoryId, isLoading]);

  if (isLoading || !resolvedCategory) {
    return <></>;
  } else {
    const brightness = Math.round(
      (resolvedCategory.rgb[0] * 299 +
        resolvedCategory.rgb[1] * 587 +
        resolvedCategory.rgb[2] * 114) /
        1000,
    );
    const textColour = brightness > 125 ? "text-black" : "text-white";
    return (
      <span
        style={{
          background: `rgb(${resolvedCategory.rgb[0]}, ${resolvedCategory.rgb[1]}, ${resolvedCategory.rgb[2]})`,
        }}
        className={clsx(
          "text-xs text-center font-semibold inline-block py-1 px-2 rounded uppercase last:mr-0 mr-1 min-w-full",
          textColour,
        )}
      >
        {resolvedCategory.name}
      </span>
    );
  }
}
