import { useState } from "react";

import { ITemplateConfiguration } from "../../../types/Template/Template";
import { TemplateEditContextProvider } from "../../../contexts/TemplateEditContext";
import useInstallerTemplateConfigurations from "../../../data/configuration/useInstallerTemplateConfiguration";
import { LoadingWheel } from "../../base/LoadingWheel";
import useInstallerTemplateConfigurationsMutations from "../../../data/configuration/useInstallerTemplateConfigurationsMutations";
import { Button } from "../../base/Button";
import { SectionLoading } from "../../shared/SectionLoading";
import { useHardwareLookups } from "../../../hooks/useHardwareLookups";
import { TemplateListRow } from "./TemplateListRow";
import { AddTemplateModal } from "./AddTemplate";
import { CreateEditTemplateModal } from "./CreateEditTemplateModal";
import { ConfirmDelete } from "../../shared/ConfirmDelete";

export function InstallerTemplates() {
  const { isLoading: isLookupsLoading } = useHardwareLookups();
  const { templateConfigurations } = useInstallerTemplateConfigurations();
  const { updateTemplate, delTemplate } =
    useInstallerTemplateConfigurationsMutations();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editTemplate, setEditTemplate] = useState<ITemplateConfiguration>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteTemplate, setDeleteTemplate] =
    useState<ITemplateConfiguration>();

  const handleEditOpen = (template: ITemplateConfiguration) => {
    addOpen && setAddOpen(false);
    setEditTemplate(template);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditTemplate(undefined);
  };

  const handleDeleteTemplate = (template: ITemplateConfiguration) => {
    setDeleteTemplate(template);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteTemplate = () => {
    delTemplate.mutate(deleteTemplate!, {
      onSettled: () => {
        setDeleteTemplate(undefined);
        setDeleteOpen(false);
      },
    });
  };

  return templateConfigurations.isLoading || isLookupsLoading ? (
    <div className="flex items-center justify-center py-10">
      <LoadingWheel />
    </div>
  ) : (
    <>
      {editTemplate && (
        <TemplateEditContextProvider>
          <CreateEditTemplateModal
            updateMutationFn={updateTemplate}
            open={editOpen}
            onClose={handleEditClose}
            template={editTemplate!}
          />
        </TemplateEditContextProvider>
      )}
      <AddTemplateModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        setEditTemplate={handleEditOpen}
      />
      <ConfirmDelete
        itemName={`template ${deleteTemplate?.template.name}`}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleConfirmDeleteTemplate}
      />
      <div className="mt-6">
        <p>
          Templates allow you to customize the Live Readings view by specifying
          which tiles users can see and in what order. You can also hide certain
          tiles entirely, preventing users from accessing them, and disable
          control options for specific tiles.
        </p>
        <br />
        <p>
          Since the available data points vary by appliance model, templates are
          created for each specific model. Once a template is created, you can
          apply it by navigating to the Live View page on your account or any of
          your customers' accounts.
        </p>
      </div>

      <div>
        <Button onClick={() => setAddOpen(true)}>Add Template</Button>
      </div>

      <div>
        {templateConfigurations.isLoading ? (
          <SectionLoading />
        ) : (
          <ul className="rounded border divide-y bg-gray-100 divide-gray-400 border-gray-400 dark:bg-gray-700 dark:divide-gray-600 dark:border-gray-600">
            {templateConfigurations.data?.length ? (
              templateConfigurations.data?.map((template) => (
                <TemplateListRow
                  key={template.id}
                  template={template}
                  onEdit={handleEditOpen}
                  onDelete={handleDeleteTemplate}
                />
              ))
            ) : (
              <div className="flex items-center justify-center py-6">
                <p className="italic text-gray-600 dark:text-gray-300">
                  No templates
                </p>
              </div>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
