import { useHardwareLookups } from "../../../hooks/useHardwareLookups";
import { Select } from "../../base/Select";
import { IHardware } from "../../../types/Hardware/Hardware";
import { DeviceStatusBar } from "./DeviceStatusBar";

export type LiveReadingsViewAs = "customer" | "installer" | "superadmin";

interface HardwareSettingsProps {
  hardware: IHardware[];
  selectedHardware: IHardware;
  onHardwareChange: (e: React.FormEvent<HTMLSelectElement>) => void;
}

export function HardwareSettings({
  hardware,
  selectedHardware,
  onHardwareChange,
}: HardwareSettingsProps) {
  const { manufacturerLookup, modelLookup, typeLookup } = useHardwareLookups();

  return (
    <div>
      <div className="w-full block space-y-4 md:space-y-0 md:mb-0 md:flex md:space-x-4 md:items-center">
        <div className="w-full block md:mb-0 md:flex md:space-x-4 md:items-center">
          <p className="text-sm mb-2 md:mb-0">Appliance</p>
          <Select className="w-full md:w-64" onChange={onHardwareChange}>
            {hardware.map((hardware) => (
              <option key={hardware.id} value={hardware.id}>
                {hardware.name}
              </option>
            ))}
          </Select>
          <p className="my-4 text-center md:my-0">
            {`${typeLookup(selectedHardware.hardwareTypeId)?.name} | ${
              manufacturerLookup(selectedHardware.hardwareManufacturerId)?.name
            } |
    ${modelLookup(selectedHardware.hardwareModelId)?.name}`}
          </p>
        </div>
        <DeviceStatusBar hardware={selectedHardware} />
      </div>
    </div>
  );
}
