import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Modal } from "../../layouts/Modal";

import { TextField } from "../base/TextField";
import { Select } from "../base/Select";
import { TextArea } from "../base/TextArea";
import { Button } from "../base/Button";
import { SectionLoading } from "../shared/SectionLoading";

import useJobCategories from "../../data/job/useJobCategories";
import useJobs from "../../data/job/useJobs";

import { IJob } from "../../types/Job/Job";

export interface CreateEditJobProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  job?: IJob;
}

export function CreateEditJob({ open, onClose, job }: CreateEditJobProps) {
  const { register, handleSubmit, reset } = useForm();
  const { createJob, updateJob } = useJobs();
  const { jobCategories } = useJobCategories();

  const handleClose = () => {
    onClose(false);
    setTimeout(() => reset({}), 200);
  };

  const handleCreate = (data: any) => {
    createJob.mutate(data);
    handleClose();
  };

  const handleUpdate = (data: any) => {
    updateJob.mutate({ ...job, ...data });
    handleClose();
  };

  useEffect(() => {
    if (job) {
      reset({
        title: job.title,
        categoryId: job.categoryId,
        description: job.description,
      });
    } else {
      reset({});
    }
  }, [reset, job]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${job ? "Edit" : "Create"} Job`}
      description={`Use this form to ${job ? "edit" : "create"} a job`}
    >
      {jobCategories.isLoading ? (
        <SectionLoading />
      ) : (
        <form
          onSubmit={handleSubmit(job ? handleUpdate : handleCreate)}
          className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-6 sm:gap-y-4"
        >
          <TextField {...register("title")} label="Title" />
          <Select {...register("categoryId", { value: "" })} label="Category">
            <option disabled hidden value="">
              Please Select
            </option>
            {jobCategories.data?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <div className="sm:col-span-2">
            <TextArea
              rows={4}
              {...register("description")}
              label="Description"
            />
          </div>
          <div className="mt-6 sm:col-span-2 space-x-4">
            <Button type="submit">{job ? "Update" : "Create"}</Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}
