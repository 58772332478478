import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IHardwareSelection } from "../../types/Hardware/Hardware";

export default function useHardwareModels(manufacturer?: string) {
  const { get } = useApiHelper();

  const hardwareModels = useQuery({
    queryKey: ["hardware", "models", manufacturer],
    queryFn: () =>
      get<IDefaultResponseWithData<IHardwareSelection>>(
        `/v1/hardware/models${manufacturer !== undefined ? `/${manufacturer}` : ""}`,
      ).then((res) => res.data.data),
  });

  return {
    hardwareModels,
  };
}
