import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Card } from "../../components/base/Card";
import { Button } from "../../components/base/Button";

import { usePageTitle } from "../../contexts/PageTitleContext";

export function SystemPage() {
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle("System");
  }, [setPageTitle]);

  return (
    <div className="grid grid-cols-1 gap-y-6">
      <>
        <Card title="Action">
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-6 sm:gap-y-4">
            <NavLink to="action">
              <Button fullWidth size="lg">
                Actions
              </Button>
            </NavLink>
            <NavLink to="action/profiles">
              <Button fullWidth size="lg">
                Profiles
              </Button>
            </NavLink>
            <NavLink to="action/transformations">
              <Button fullWidth size="lg">
                Transformations
              </Button>
            </NavLink>
          </div>
        </Card>

        <Card title="Data">
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-6 sm:gap-y-4">
            <NavLink to="datapoint">
              <Button fullWidth size="lg">
                Data Points
              </Button>
            </NavLink>
            <NavLink to="datapoint/profiles">
              <Button fullWidth size="lg">
                Profiles
              </Button>
            </NavLink>
            <NavLink to="datapoint/options">
              <Button fullWidth size="lg">
                Options
              </Button>
            </NavLink>
            <NavLink to="datapoint/functions">
              <Button fullWidth size="lg">
                Functions
              </Button>
            </NavLink>
            <NavLink to="datapoint/transformations">
              <Button fullWidth size="lg">
                Transformations
              </Button>
            </NavLink>
          </div>
        </Card>

        <Card title="Hardware">
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-6 sm:gap-y-4">
            <NavLink to="hardware/types">
              <Button fullWidth size="lg">
                Types
              </Button>
            </NavLink>
            <NavLink to="hardware/manufacturers">
              <Button fullWidth size="lg">
                Manufacturers
              </Button>
            </NavLink>
            <NavLink to="hardware/models">
              <Button fullWidth size="lg">
                Models
              </Button>
            </NavLink>
          </div>
        </Card>

        <Card title="Job">
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-6 sm:gap-y-4">
            <NavLink to="job/categories">
              <Button fullWidth size="lg">
                Categories
              </Button>
            </NavLink>
          </div>
        </Card>
      </>
    </div>
  );
}
