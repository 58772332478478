export default function useQSBuilder() {
  function buildQS(input: any) {
    if (!input) {
      return "";
    }

    const qs = Object.keys(input)
      .filter((key) => input[key] !== undefined) // Filter out undefined values
      .map(
        (key) =>
          Array.isArray(input[key])
            ? input[key].map((el: string) => `${key}=${el}`).join("&") // Handle arrays
            : `${key}=${input[key]}` // Handle single values
      )
      .join("&");

    return qs ? `?${qs}` : ""; // Add "?" if query string is not empty
  }

  return buildQS;
}
