import clsx from "clsx";

import ThemeColorOptions from "../../types/system/ThemeColorOption";
import React from "react";

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  color?: ThemeColorOptions;
  label?: string;
}

export const TextArea = React.forwardRef<any, TextAreaProps>(
  ({ className, color = "primary", label, ...rest }, ref) => {
    return (
      <div className="flex flex-col space-y-1">
        {label && (
          <label className="text-sm font-medium opacity-80">{label}</label>
        )}
        <textarea
          className={clsx(
            "rounded bg-white border outline-none focus:drop-shadow-none focus:ring-2 dark:bg-gray-700 dark:text-white",
            color === "primary"
              ? "!border-primary focus:ring-primary"
              : "!border-secondary focus:ring-secondary",
            className,
          )}
          ref={ref}
          {...rest}
        />
      </div>
    );
  },
);
