export function isValidSigned16BitInteger(value: number) {
  // Check if the value is within the range of a signed 16-bit integer
  return value >= -32768 && value <= 32767 && Number.isInteger(value);
}

export function isValidUnsigned16BitInteger(value: number) {
  // Check if the value is within the range of a unsigned 16-bit integer
  return value >= 0 && value <= 65535 && Number.isInteger(value);
}

export function isValidCTOffsetValue(value: string) {
  const matches = value.match(/^-?(?:0|[1-9]\d{0,2})(?:\.\d{1,2})?$/g);

  if (matches && matches?.length > 0 && !isNaN(value as any)) {
    // Multiply the value by 10, check to see if valid int16
    return isValidSigned16BitInteger(parseInt(`${parseFloat(value) * 100}`));
  } else {
    return false;
  }
}

export function isValidVMOffsetValue(value: string) {
  // Check to see input is xx.x
  const matches = value.match(/^-?\d{1,4}(\.\d)?$/);

  if (matches && matches?.length > 0 && !isNaN(value as any)) {
    // Multiply the value by 10, check to see if valid int16
    return isValidSigned16BitInteger(parseFloat(value) * 10);
  } else {
    return false;
  }
}

export function isValidScaleValue(value: string) {
  // Check to see input is xx.x
  const matches = value.match(
    /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
  );

  if (matches && matches?.length > 0) {
    // Remove leading zeros
    value = value.replace(/^0+/, "");

    // If value is nothing, it was 0
    if (value === "") {
      return false;
    }

    let valAsFloat = parseFloat(value);

    if (valAsFloat === 0) {
      return false;
    }

    // Multiply the value by 10, check to see if valid uint16
    return isValidUnsigned16BitInteger(valAsFloat * 10);
  } else {
    return false;
  }
}
