import { Modal } from "../../layouts/Modal";
import { Button } from "../base/Button";

export interface ConfirmSubscriptionUpdateProps {
  tier: "standard";
  currentSeats: number;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmSubscriptionUpdate({
  tier,
  currentSeats,
  open,
  onCancel,
  onConfirm,
}: ConfirmSubscriptionUpdateProps) {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Confirm Subscription Change"
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      <div>
        <p>
          To assign a {tier} subscription to this device, we need to increase
          the quantity of {tier} subscriptions on your account.
          <br />
          <br />
          Your total {tier} subscriptions will increase from {currentSeats} to{" "}
          {currentSeats + 1}.
          <br />
          <br />
          By pressing Confirm, you agree to amend your subscription. The price
          increase will be reflected in your monthly invoice.
        </p>
      </div>
      <div className="mt-6 space-x-4">
        <Button onClick={onConfirm}>Confirm</Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
