export const dataPointQueryKeys = {
  all: () => ["reading", "datapoints"],
  byHardwareProfileId: (
    hardwareId?: string,
    templateId?: string,
    organisationId?: string
  ) => [
    ...dataPointQueryKeys.all(),
    "byProfileId",
    hardwareId,
    templateId,
    organisationId,
  ],
};
