import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import useQSBuilder from "../../hooks/useQSBuilder";
import { IReadingPartial } from "../../types/DataPoint/Reading";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useReadingsHistory(
  hardwareId: string,
  dataPointIds: string | string[],
  startDate: string,
  endDate: string,
) {
  const { get } = useApiHelper();
  const buildQS = useQSBuilder();

  const readings = useQuery({
    queryKey: ["readings", hardwareId, { startDate, endDate, dataPointIds }],
    queryFn: () =>
      get<IDefaultResponseWithData<IReadingPartial>>(
        `/v1/reading/${hardwareId}${buildQS({
          start: startDate,
          end: endDate,
          dataPoint: dataPointIds,
        })}`,
      ).then((res) => res.data.data),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return {
    readings,
  };
}
