import { Select } from "../base/Select";
import useHardware from "../../data/hardware/useHardware";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";

export function HardwareSelection() {
  const { hardware: allHardware } = useHardware();
  const { hardware, setHardware } = useGraphSettings();

  const handleHardwareChange = (value: string) => {
    let hw = allHardware.data?.find((h) => h.id === value);

    if (!hw) {
      return;
    }

    setHardware(hw);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-11 gap-4">
      <div className="grid grid-cols-1 md:col-span-5 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6">
          <div className="flex items-center">
            <p className="text-sm mb-2 md:mb-0">Hardware</p>
          </div>
          <div className="md:col-span-3 lg:col-span-4">
            <Select
              className="w-full"
              value={hardware?.id ?? "0"}
              onChange={(e) => handleHardwareChange(e.currentTarget.value)}
            >
              <option value="0">Select Hardware</option>
              {allHardware.data?.map((hardware) => (
                <option key={hardware.id} value={hardware.id}>
                  {hardware.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
