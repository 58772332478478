import { useEffect, useState } from "react";
import { useLiveReading } from "../../../contexts/LiveReadingContext";
import {
  ProcessedReading,
  useReadingsProcessor,
} from "../../../hooks/useReadingProcessor";
import { IDataPoint } from "../../../types/DataPoint/DataPoint";
import clsx from "clsx";

interface ReadingTableRowProps {
  dataPoint: IDataPoint;
  rowIndex: number;
}

export function ReadingTableRow({ dataPoint, rowIndex }: ReadingTableRowProps) {
  const { processorReady, readingProcessor } = useReadingsProcessor();
  const { latest } = useLiveReading();
  const [processedReading, setProcessedReading] = useState<ProcessedReading>();

  useEffect(() => {
    //Find reading for DataPoint
    const reading = latest?.readingData.find(
      (r) => r.dataPointId === dataPoint.id
    );

    if (reading) {
      // Throw reading & dataPoint for this card into processor
      let updatedReading = readingProcessor(reading!, dataPoint);
      setProcessedReading(updatedReading);
    } else {
      //Fake a reading
      let updatedReading = readingProcessor(null, dataPoint);
      setProcessedReading(updatedReading);
    }
  }, [latest, readingProcessor]);

  return (
    <tr className={rowIndex % 2 !== 0 ? "bg-primary/10" : ""}>
      <td width="50%" className="whitespace-nowrap px-3 py-4">
        {dataPoint.name}
      </td>
      {dataPoint.profiles.length > 0 ? (
        <td
          width="25%"
          className="whitespace-nowrap px-3 py-4 text-gray-200 dark:text-gray-400"
        >
          {dataPoint.profiles[0].registerName}
        </td>
      ) : (
        <td
          width="25%"
          className="whitespace-nowrap px-3 py-4 text-gray-400 dark:text-gray-600"
        >
          Unavailable
        </td>
      )}
      {processedReading && processorReady ? (
        <td
          width="15%"
          className={clsx(
            "whitespace-nowrap px-3 py-4",
            !processedReading.hasValue && "text-gray-400 dark:text-gray-600"
          )}
        >
          {processedReading.displayString}
        </td>
      ) : (
        <td
          width="15%"
          className="whitespace-nowrap px-3 py-4 text-gray-400 dark:text-gray-600"
        >
          Unavailable
        </td>
      )}
    </tr>
  );
}
