import { ReactNode } from "react";

export interface CardProps {
  title?: string;
  className?: string;
  children: ReactNode | ReactNode[];
}

export function Card({ title, className, children, ...rest }: CardProps) {
  return (
    <div
      className={`bg-paper py-3 px-4 rounded shadow-md xl:py-4 xl:px-5 dark:text-white ${className}`}
      {...rest}
    >
      {title && <h2 className="text-2xl font-medium xl:text-3xl">{title}</h2>}
      {children}
    </div>
  );
}
