import { useQuery } from "react-query";

import { configurationQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { ITemplateConfiguration } from "../../types/Template/Template";

export default function useInstallerTemplateConfigurations() {
  const { get } = useApiHelper();

  const templateConfigurations = useQuery({
    queryKey: configurationQueryKeys.templates("installer"),
    queryFn: () =>
      get<IDefaultResponseWithData<ITemplateConfiguration>>(
        "/v1/installer/configurations?type=2"
      ).then((res) => res.data.data),
  });

  return {
    templateConfigurations,
  };
}
