export function upsert<T extends Record<string, any>>(
  matchValueExtractor: (item: T) => string,
  value: any,
  newValue: T,
) {
  return (arr: T[]) => {
    let matchIndex = arr.findIndex((obj) => matchValueExtractor(obj) === value);

    if (matchIndex !== -1) {
      arr.splice(matchIndex, 1, newValue);
    } else {
      arr.push(newValue);
    }

    return arr;
  };
}
