import { useEffect, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import date from "date-and-time";

import { Card } from "../../components/base/Card";
import { Table } from "../../components/base/Table";

import { usePageTitle } from "../../contexts/PageTitleContext";

import { IJob } from "../../types/Job/Job";
import { Button } from "../../components/base/Button";
import { CreateEditJob } from "../../components/Job/CreateEditJob";
import { CategoryPill } from "../../components/Job/CategoryPill";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useCategoryLookups } from "../../hooks/useCategoryLookups";
import { LoadingWheel } from "../../components/base/LoadingWheel";

const colHelper = createColumnHelper<IJob>();

export function JobsPage() {
  const { categoryLookup, isLoading: isCategoryLookupLoading } =
    useCategoryLookups();
  const { setPageTitle } = usePageTitle();
  const [open, setOpen] = useState(false);
  const [editJob, setEditJob] = useState<IJob | undefined>(undefined);

  const handleEditJob = (job: IJob) => {
    setEditJob(job);
    setOpen(true);
  };

  const cols = useMemo(
    () => [
      colHelper.accessor("title", { header: "Title" }),
      colHelper.accessor("description", { header: "Description" }),
      colHelper.display({
        header: "Category",
        cell: (info) => (
          <CategoryPill
            category={categoryLookup(info.row.original.categoryId)!}
          />
        ),
      }),
      colHelper.accessor("createdOn", {
        header: "Created",
        cell: (info) =>
          date.format(new Date(info.getValue()), "DD/MM/YYYY HH:mm"),
      }),
      colHelper.display({
        header: "Edit",
        cell: (info) => (
          <PencilIcon
            className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
            onClick={() => handleEditJob(info.row.original)}
          />
        ),
      }),
    ],
    [isCategoryLookupLoading],
  );

  useEffect(() => {
    setPageTitle("Jobs");
  }, [setPageTitle]);

  return (
    <>
      <CreateEditJob open={open} onClose={setOpen} job={editJob} />
      <Card title="All Jobs">
        <div className="my-6">
          <Button onClick={() => setOpen(true)}>Add Job</Button>
        </div>
        {isCategoryLookupLoading ? (
          <LoadingWheel />
        ) : (
          <div>
            <Table<IJob>
              url="/job"
              queryKey={["jobs"]}
              columns={cols}
              // filterFields={[
              //   { value: "organisationNumber", label: "Customer Number" },
              //   { value: "organisationName", label: "Customer Name" }
              // ]}
            />
          </div>
        )}
      </Card>
    </>
  );
}
