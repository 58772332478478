import * as Sentry from "@sentry/react";
import { Fragment, Dispatch, SetStateAction, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ErrorFallback } from "../ErrorFallback";
import { stat } from "fs";

export interface ModalControlProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

export interface ModalProps extends ModalControlProps {
  children: ReactNode | ReactNode[];
  title?: string;
  description?: string;
  sizeClasses?: string;
  dialogStatic?: boolean;
}

export function Modal({
  open,
  onClose,
  children,
  title,
  description,
  sizeClasses = "sm:max-w-2xl sm:p-6 lg:max-w-4xl",
  dialogStatic = false,
}: ModalProps) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          static={dialogStatic}
          as="div"
          className="relative z-10"
          onClose={!dialogStatic ? onClose : () => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    "w-full relative transform overflow-hidden rounded-lg bg-paper px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:text-white sm:my-8 sm:w-full",
                    sizeClasses
                  )}
                >
                  <Dialog.Title as="h3" className="text-2xl font-semibold">
                    {title}
                  </Dialog.Title>
                  <Dialog.Description className="my-2">
                    {description}
                  </Dialog.Description>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Sentry.ErrorBoundary>
  );
}
