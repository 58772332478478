import { ReactNode } from "react";

export interface MiniTableProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export function MiniTable({ children, className }: MiniTableProps) {
  return (
    <div
      className={`rounded border border-gray-400 dark:border-gray-600 divide-y divide-gray-400 dark:divide-gray-600 ${className}`}
    >
      {children}
    </div>
  );
}
