import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { IReading } from "../../types/DataPoint/Reading";
import { latestReadingTimestampKey } from "../../constants/common";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useReadings(hardwareId: string) {
  const { get } = useApiHelper();

  const readings = useQuery({
    queryKey: ["readings", hardwareId, "latest"],
    queryFn: async () => {
      let prev = sessionStorage.getItem(latestReadingTimestampKey);

      return get<IDefaultResponseWithData<IReading>>(
        `/v1/reading/${hardwareId}/latest${prev ? `?from=${prev}` : ``}`,
      ).then((res) => res.data.data);
    },
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });

  return {
    readings,
  };
}
