import { useState } from "react";
import { Modal } from "../../layouts/Modal";
import { Button } from "../base/Button";
import { Toggle } from "../base/Toggle";

export interface ConfirmDeleteProps {
  itemName?: string;
  open: boolean;
  showToggle?: boolean;
  toggleLabel?: string;
  onCancel: () => void;
  onConfirm: (() => void) | ((checked: boolean) => void);
}

export function ConfirmDelete({
  itemName = "this item",
  open,
  showToggle = false,
  toggleLabel,
  onCancel,
  onConfirm,
}: ConfirmDeleteProps) {
  const [checked, setChecked] = useState(false);

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Confirm Delete"
      description={`Are you sure you want to delete ${itemName}?`}
      sizeClasses="text-center sm:max-w-xl sm:p-6 lg:max-w-lg"
    >
      {showToggle && (
        <div className="my-6 mb-10 flex justify-center">
          <Toggle
            label={toggleLabel}
            checked={checked}
            onChange={() => setChecked((c) => !c)}
          />
        </div>
      )}
      <div className="mt-6 space-x-4">
        <Button onClick={() => onConfirm(checked)}>Confirm</Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
