import { ReactNode } from "react";
import clsx from "clsx";

import ThemeColorOptions from "../../types/system/ThemeColorOption";
import React from "react";

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
  containerClassName?: string;
  small?: boolean;
  color?: ThemeColorOptions;
  label?: string;
}

export const Select = React.forwardRef<any, SelectProps>(
  (
    {
      children,
      fullWidth = false,
      className,
      containerClassName,
      small = false,
      color = "primary",
      label,
      ...rest
    }: SelectProps,
    ref,
  ) => {
    return (
      <div className={`flex flex-col space-y-1 ${containerClassName}`}>
        {label && (
          <label className="text-sm font-medium opacity-80">{label}</label>
        )}
        <select
          ref={ref}
          className={clsx(
            "rounded bg-white border outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:text-white",
            fullWidth && "w-full",
            color === "primary"
              ? "border-primary focus:ring-primary"
              : "border-secondary focus:ring-secondary",
            small ? "px-1 py-0.5 pr-10" : "h-[42px] px-3 py-2 pr-10",
            className,
          )}
          {...rest}
        >
          {children}
        </select>
      </div>
    );
  },
);
