import { useEffect, useState } from "react";

import date from "date-and-time";
import useReadings from "../../data/datapoint/useReading";
import { IHardware } from "../../types/Hardware/Hardware";
import { alphabeticalSort } from "../../utils/alphabeticalSort";
import { SectionLoading } from "../../components/shared/SectionLoading";
import { usePendingActions } from "../../contexts/PendingActionContext";
import {
  ProcessedReading,
  useReadingsProcessor,
} from "../../hooks/useReadingProcessor";
import { upsert } from "../../utils/upsert";
import { usePageTitle } from "../../contexts/PageTitleContext";
import useDataPointsByHardwareProfileId from "../../data/datapoint/useDataPointsByHardwareProfile";

interface IAllReadingsDisplayProps {
  hardware: IHardware;
}

export function AllReadingsTable({ hardware }: IAllReadingsDisplayProps) {
  const { setSubTitle } = usePageTitle();
  const { readings } = useReadings(hardware.id);
  const { dataPoints } = useDataPointsByHardwareProfileId(hardware.id);
  const { processorReady, readingProcessor } = useReadingsProcessor();
  const { setHardware, reset } = usePendingActions();
  const [processedReadings, setProcessedReadings] = useState<
    ProcessedReading[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      processorReady &&
      !readings.isLoading &&
      readings.data &&
      readings.data.length &&
      !dataPoints.isLoading
    ) {
      readings.data![0].readingData.forEach((reading) => {
        let pr = readingProcessor(
          reading,
          dataPoints.data![0].datapoints.find((d) => d.id === reading.dataPointId)!
        );

        if (pr !== null) {
          setProcessedReadings(
            upsert((i) => i.dataPoint.id, reading.dataPointId, pr)
          );
        }
      });

      setSubTitle(
        `Last updated: ${date.format(
          new Date(readings.data![0].timestamp),
          "DD/MM/YYYY HH:mm:ss"
        )}`
      );

      setIsLoading(false);
    } else if (processorReady && !readings.isLoading) {
      setSubTitle("Last updated: Never");
      setIsLoading(false);
    }
  }, [processorReady, readings.isLoading, readings.data]);

  useEffect(() => {
    setHardware(hardware.id);

    return () => {
      reset();
    };
  }, [setHardware, reset, hardware.id]);

  return (
    <>
      {isLoading ? (
        <SectionLoading />
      ) : readings &&
        readings.data?.length &&
        readings.data[0] &&
        readings.data[0].readingData.length ? (
        <div className="block overflow-x-auto overflow-y-hidden">
          <table className="min-w-full table border border-primary/40 divide-y divide-primary dark:border-primary">
            <thead className="bg-primary/40">
              <tr>
                <th className="px-3 py-3 text-left text-sm font-semibold">
                  Name
                </th>
                <th className="px-3 py-3 text-left text-sm font-semibold">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-primary/40">
              {processedReadings
                .sort(alphabeticalSort((i) => i.dataPoint.name))
                .map((reading, i) => (
                  <tr
                    key={reading.dataPoint.id}
                    className={i % 2 !== 0 ? "bg-primary/10" : ""}
                  >
                    <td className="whitespace-nowrap px-3 py-4">
                      {reading.dataPoint.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4">
                      {reading.displayString}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="col-span-3">No Readings!</div>
      )}
    </>
  );
}
