import { useFormContext } from "react-hook-form";

import { FormSelect } from "../../base/Form/FormSelect";
import {
  BaudRateOptions,
  DataBitsOptions,
  ParityOptions,
  StopBitsOptions,
} from "../../../types/Hardware/Hardware";

export function SerialSettings() {
  const { register } = useFormContext();

  return (
    <div className="sm:col-span-2 border p-4 rounded-md border-gray-600">
      <p className="text-base mb-2 font-medium">Serial Settings</p>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <FormSelect {...register("serialSettings.baudRate")} label="Baud Rate">
          <option disabled hidden value={0}>
            Please Select
          </option>
          {BaudRateOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </FormSelect>

        <FormSelect {...register("serialSettings.dataBits")} label="Data Bits">
          <option disabled hidden value={0}>
            Please Select
          </option>
          {DataBitsOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </FormSelect>

        <FormSelect {...register("serialSettings.parity")} label="Parity">
          <option disabled hidden value={"default"}>
            Please Select
          </option>
          {ParityOptions.map((x, i) => (
            <option key={i} value={x.value}>
              {x.key}
            </option>
          ))}
        </FormSelect>

        <FormSelect {...register("serialSettings.stopBits")} label="Stop Bits">
          <option disabled hidden value={0}>
            Please Select
          </option>
          {StopBitsOptions.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </FormSelect>
      </div>
    </div>
  );
}
