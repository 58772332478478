import { IStatisticConfiguration } from "../Statistic/Statistic";
import { ITemplateConfiguration } from "../Template/Template";
import { IViewConfiguration } from "../Tile/Tile";

export enum ConfigurationType {
  none,
  statistic,
  template,
  tile,
}

export type IConfiguration =
  | IStatisticConfiguration
  | ITemplateConfiguration
  | IViewConfiguration;
