import { useState } from "react";

import { Button } from "../../base/Button";
import { AddTemplateModal } from "./AddTemplate";
import { TemplateListRow } from "./TemplateListRow";
import { LoadingWheel } from "../../base/LoadingWheel";
import { SectionLoading } from "../../shared/SectionLoading";
import { CreateEditTemplateModal } from "./CreateEditTemplateModal";
import { useHardwareLookups } from "../../../hooks/useHardwareLookups";
import { ITemplateConfiguration } from "../../../types/Template/Template";
import { TemplateEditContextProvider } from "../../../contexts/TemplateEditContext";
import useCustomerTemplateConfigurations from "../../../data/configuration/useCustomerTemplateConfiguration";
import useCustomerTemplateConfigurationsMutations from "../../../data/configuration/useCustomerTemplateConfigurationsMutations";
import { ConfirmDelete } from "../../shared/ConfirmDelete";

export function CustomerTemplates() {
  const { isLoading: isLookupsLoading } = useHardwareLookups();
  const { templateConfigurations } = useCustomerTemplateConfigurations();
  const { updateTemplate, delTemplate } =
    useCustomerTemplateConfigurationsMutations();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editTemplate, setEditTemplate] = useState<ITemplateConfiguration>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteTemplate, setDeleteTemplate] =
    useState<ITemplateConfiguration>();

  const handleEditOpen = (template: ITemplateConfiguration) => {
    addOpen && setAddOpen(false);
    setEditTemplate(template);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditTemplate(undefined);
  };

  const handleDeleteTemplate = (template: ITemplateConfiguration) => {
    setDeleteTemplate(template);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteTemplate = () => {
    delTemplate.mutate(deleteTemplate!, {
      onSettled: () => {
        setDeleteTemplate(undefined);
        setDeleteOpen(false);
      },
    });
  };

  return templateConfigurations.isLoading || isLookupsLoading ? (
    <div className="flex items-center justify-center py-10">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <TemplateEditContextProvider>
        <CreateEditTemplateModal
          updateMutationFn={updateTemplate}
          open={editOpen}
          onClose={handleEditClose}
          template={editTemplate!}
        />
      </TemplateEditContextProvider>
      <AddTemplateModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        setEditTemplate={handleEditOpen}
      />
      <ConfirmDelete
        itemName={`template ${deleteTemplate?.template.name}`}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={handleConfirmDeleteTemplate}
      />
      <div className="mt-6">
        <p>Customer templates</p>
        <br />
        <p>
          Since the available data points vary by appliance model, templates are
          created for each specific model. Once a template is created, you can
          apply it by navigating to the Live View page on your account or any of
          your customers' accounts.
        </p>
      </div>

      <div>
        <Button onClick={() => setAddOpen(true)}>Add Template</Button>
      </div>

      <div>
        {templateConfigurations.isLoading ? (
          <SectionLoading />
        ) : (
          <ul className="rounded border divide-y bg-gray-100 divide-gray-400 border-gray-400 dark:bg-gray-700 dark:divide-gray-600 dark:border-gray-600">
            {templateConfigurations.data?.length ? (
              templateConfigurations.data?.map((template) => (
                <TemplateListRow
                  key={template.id}
                  template={template}
                  onEdit={handleEditOpen}
                  onDelete={handleDeleteTemplate}
                />
              ))
            ) : (
              <div className="flex items-center justify-center py-6">
                <p className="italic text-gray-600 dark:text-gray-300">No templates</p>
              </div>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
