import { useNotification } from "./useNotifications";

export function useDefaultMutateResponseHandlers(objectName: string) {
  const addNotification = useNotification();

  function handleSuccess(data: any, action: string, fallbackMessage?: string) {
    if (data.message) {
      addNotification("success", data.message);
    } else {
      addNotification(
        "success",
        fallbackMessage
          ? fallbackMessage
          : `Successfully ${action}d ${objectName}`,
      );
    }
  }

  function handleError(error: any, action: string, fallbackMessage?: string) {
    if (error.response.data.message) {
      addNotification("error", error.response.data.message);
    } else {
      addNotification(
        "error",
        fallbackMessage ? fallbackMessage : `Failed to ${action} ${objectName}`,
      );
    }
  }

  return {
    handleSuccess,
    handleError,
  };
}
