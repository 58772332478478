import { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

import { Toggle } from "../../base/Toggle";
import { useTemplateEditContext } from "../../../contexts/TemplateEditContext";

export function DataPointConfiguration() {
  const {
    state: template,
    dispatch,
    selectedDataPoint,
  } = useTemplateEditContext();

  const dataPoint = useMemo(
    () =>
      [...template.avaliableDataPoints, ...template.visibleDataPoints].find(
        (t) => t.dataPointId === selectedDataPoint?.dataPointId
      ),
    [
      template.avaliableDataPoints,
      template.visibleDataPoints,
      selectedDataPoint,
    ]
  );

  const isVisibleDataPoint = useMemo(
    () =>
      template.visibleDataPoints.some(
        (t) => t.dataPointId === selectedDataPoint?.dataPointId
      ),
    [template.visibleDataPoints, selectedDataPoint]
  );

  const displayIcon = useMemo(
    () =>
      selectedDataPoint?.dataPoint.icon
        ? (selectedDataPoint?.dataPoint.icon.split(",") as [
            IconPrefix,
            IconName,
          ])
        : (["fas", "question"] as [IconPrefix, IconName]),
    [selectedDataPoint]
  );

  return (
    <div className="md:w-5/6 mx-auto my-8 rounded bg-slate-100 border-slate-300 dark:bg-slate-700 border dark:border-gray-600">
      {dataPoint ? (
        <div className="px-6 py-4 grid gap-4 grid-cols-1 md:gap-0 md:grid-cols-3 content-center">
          <div className="md:col-span-2 flex flex-row items-center space-x-6">
            <FontAwesomeIcon fontSize={42} icon={displayIcon} />
            <div>
              <p className="text-base">{dataPoint.dataPoint.name}</p>
              {dataPoint.dataPoint.registerName ? (
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  {dataPoint.dataPoint.registerName}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex justify-end">
              <Toggle
                label="Allow visibility"
                onChange={() =>
                  dispatch({
                    type: "hide",
                    dataPointId: dataPoint.dataPointId,
                    value: !dataPoint.hide,
                  })
                }
                checked={!dataPoint.hide}
                disabled={
                  isVisibleDataPoint ||
                  dataPoint.dataPoint.customerEnable === true
                }
              />
            </div>
            <div className="flex justify-end">
              <Toggle
                label="Allow control"
                onChange={() =>
                  dispatch({
                    type: "control",
                    dataPointId: dataPoint.dataPointId,
                    value: !dataPoint.control,
                  })
                }
                disabled={!dataPoint.dataPoint.hasActions}
                checked={
                  !dataPoint.dataPoint.hasActions ? false : dataPoint.control
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="py-4 flex items-center justify-center">
          <p>No data point selected</p>
        </div>
      )}
    </div>
  );
}
