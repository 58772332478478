import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useReducer,
  useState,
} from "react";

import { IDataPoint, ISimpleDataPoint } from "../types/DataPoint/DataPoint";
import { ITemplateConfiguration } from "../types/Template/Template";
import { IViewConfiguration, TileVisability } from "../types/Tile/Tile";

const tileTemplateMixin = {
  hide: false,
  control: true,
};

export interface TemplateEditDataPoint {
  dataPointId: string;
  dataPoint: ISimpleDataPoint;
  control: boolean;
  hide: boolean;
}

interface TemplateEditStateValue {
  avaliableDataPoints: TemplateEditDataPoint[];
  visibleDataPoints: TemplateEditDataPoint[];
}

const initialContextStateValue = {
  avaliableDataPoints: [],
  visibleDataPoints: [],
};

export type TemplateEditReducerActionType = "add" | "remove" | "up" | "down";

type TemplateEditReducerActions =
  | { type: "loadFromDataPoints"; dataPoints: ISimpleDataPoint[] }
  | {
      type: "loadFromTemplate";
      template: ITemplateConfiguration;
      dataPoints: ISimpleDataPoint[];
    }
  | {
      type: "loadFromViewConfig";
      viewConfig: IViewConfiguration;
      dataPoints: ISimpleDataPoint[];
    }
  | { type: TemplateEditReducerActionType; dataPoint: TemplateEditDataPoint }
  | { type: "hide" | "control"; dataPointId: string; value: boolean }
  | { type: "reset" };

function templateEditReducer(
  state: TemplateEditStateValue,
  action: TemplateEditReducerActions
): TemplateEditStateValue {
  switch (action.type) {
    case "loadFromDataPoints":
      return {
        ...state,
        avaliableDataPoints: action.dataPoints.map((dp) => ({
          ...tileTemplateMixin,
          dataPointId: dp.id,
          dataPoint: dp,
        })),
      };

    case "loadFromTemplate": {
      const visableDataPoints = action.template.template.tiles.filter(
        (tile) => tile.visability === TileVisability.show
      );

      const avaliableDataPoints = action.template.template.tiles.filter(
        (tile) => tile.visability !== TileVisability.show
      );

      return {
        visibleDataPoints: visableDataPoints.map((t) => ({
          dataPointId: t.dataPointId,
          dataPoint: action.dataPoints.find((dp) => dp.id === t.dataPointId)!,
          control: t.control,
          hide: false,
        })),
        avaliableDataPoints: avaliableDataPoints.map((t) => ({
          dataPointId: t.dataPointId,
          dataPoint: action.dataPoints.find((dp) => dp.id === t.dataPointId)!,
          control: t.control,
          hide: t.visability === TileVisability.exclude ? true : false,
        })),
      };
    }

    case "loadFromViewConfig": {
      const visableDataPoints = action.viewConfig.view.tiles.filter(
        (tile) => tile.visability === TileVisability.show
      );

      const avaliableDataPoints = action.viewConfig.view.tiles.filter(
        (tile) => tile.visability !== TileVisability.show
      );

      return {
        visibleDataPoints: visableDataPoints.map((t) => ({
          dataPointId: t.dataPointId,
          dataPoint: action.dataPoints.find((dp) => dp.id === t.dataPointId)!,
          control: t.control,
          hide: false,
        })),
        avaliableDataPoints: avaliableDataPoints.map((t) => ({
          dataPointId: t.dataPointId,
          dataPoint: action.dataPoints.find((dp) => dp.id === t.dataPointId)!,
          control: t.control,
          hide: t.visability === TileVisability.exclude ? true : false,
        })),
      };
    }

    case "add": {
      const exists = state.visibleDataPoints.some(
        (dp) => dp.dataPointId === action.dataPoint.dataPointId
      );

      if (exists) return state; // Prevent duplicate adds

      const dataPoint = state.avaliableDataPoints.find(
        (dp) => dp.dataPointId === action.dataPoint.dataPointId
      );

      if (!dataPoint) return state;

      return {
        ...state,
        avaliableDataPoints: state.avaliableDataPoints.filter(
          (t) => t.dataPointId !== action.dataPoint.dataPointId
        ),
        visibleDataPoints: [
          ...state.visibleDataPoints,
          { ...dataPoint, hide: false },
        ],
      };
    }

    case "remove":
      const exists = state.avaliableDataPoints.some(
        (dp) => dp.dataPointId === action.dataPoint.dataPointId
      );

      if (exists) return state;

      const dataPoint = state.visibleDataPoints.find(
        (dp) => dp.dataPointId === action.dataPoint.dataPointId
      );

      if (!dataPoint) return state;

      return {
        ...state,
        avaliableDataPoints: [...state.avaliableDataPoints, dataPoint!],
        visibleDataPoints: state.visibleDataPoints.filter(
          (t) => t.dataPointId !== action.dataPoint.dataPointId
        ),
      };

    case "up": {
      const index = state.visibleDataPoints.findIndex(
        (t) => t.dataPointId === action.dataPoint.dataPointId
      );

      if (index > 0) {
        const newVisibleDataPoints = [...state.visibleDataPoints];

        [newVisibleDataPoints[index - 1], newVisibleDataPoints[index]] = [
          newVisibleDataPoints[index],
          newVisibleDataPoints[index - 1],
        ];

        return { ...state, visibleDataPoints: newVisibleDataPoints };
      }

      return state;
    }

    case "down": {
      const index = state.visibleDataPoints.findIndex(
        (t) => t.dataPointId === action.dataPoint.dataPointId
      );

      if (index < state.visibleDataPoints.length - 1) {
        const newVisibleDataPoints = [...state.visibleDataPoints];

        [newVisibleDataPoints[index + 1], newVisibleDataPoints[index]] = [
          newVisibleDataPoints[index],
          newVisibleDataPoints[index + 1],
        ];

        return { ...state, visibleDataPoints: newVisibleDataPoints };
      }

      return state;
    }

    case "hide": {
      const updatedVisibleDataPoints = state.visibleDataPoints.map((dp) =>
        dp.dataPointId === action.dataPointId
          ? { ...dp, hide: action.value }
          : dp
      );

      const updatedAvailableDataPoints = state.avaliableDataPoints.map((dp) =>
        dp.dataPointId === action.dataPointId
          ? { ...dp, hide: action.value }
          : dp
      );

      return {
        ...state,
        visibleDataPoints: updatedVisibleDataPoints,
        avaliableDataPoints: updatedAvailableDataPoints,
      };
    }

    case "control": {
      const updatedVisibleDataPoints = state.visibleDataPoints.map((dp) =>
        dp.dataPointId === action.dataPointId
          ? { ...dp, control: action.value }
          : dp
      );

      const updatedAvailableDataPoints = state.avaliableDataPoints.map((dp) =>
        dp.dataPointId === action.dataPointId
          ? { ...dp, control: action.value }
          : dp
      );

      return {
        ...state,
        visibleDataPoints: updatedVisibleDataPoints,
        avaliableDataPoints: updatedAvailableDataPoints,
      };
    }

    case "reset":
      return initialContextStateValue;
    default:
      return state;
  }
}

interface TemplateEditContextValue {
  state: TemplateEditStateValue;
  dispatch: Dispatch<TemplateEditReducerActions>;
  selectedDataPoint?: TemplateEditDataPoint;
  setSelectedDataPoint: Dispatch<
    SetStateAction<TemplateEditDataPoint | undefined>
  >;
}

const TemplateEditContext = createContext<TemplateEditContextValue | undefined>(
  undefined
);

interface TemplateEditContextProviderProps {
  children: ReactElement | ReactElement[];
}

export function TemplateEditContextProvider({
  children,
}: TemplateEditContextProviderProps) {
  const [state, dispatch] = useReducer(
    templateEditReducer,
    initialContextStateValue
  );
  const [selectedDataPoint, setSelectedDataPoint] = useState<
    TemplateEditDataPoint | undefined
  >(undefined);

  return (
    <TemplateEditContext.Provider
      value={{ state, dispatch, selectedDataPoint, setSelectedDataPoint }}
    >
      {children}
    </TemplateEditContext.Provider>
  );
}

export function useTemplateEditContext() {
  const templateEditCtx = useContext(TemplateEditContext);

  if (!templateEditCtx) {
    throw Error(
      "useTemplateEditContext() must be used within a TemplateEditContextProvider"
    );
  }

  return templateEditCtx;
}
