import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import {
  ArrowUturnDownIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../../../components/base/Card";
import { Table } from "../../../components/base/Table";
import { Button } from "../../../components/base/Button";
import { ConfirmDelete } from "../../../components/shared/ConfirmDelete";
import { IHardwareManufacturer } from "../../../types/Hardware/Hardware";
import useHardwareManufacturerMutations from "../../../data/hardware/useHardwareManufacturerMutations";
import { CreateEditHardwareManufacturer } from "../../../components/Hardware/CreateEditHardwareManufacturer";
import { LoadingWheel } from "../../../components/base/LoadingWheel";
import { useSuperAdminHardwareLookups } from "../../../hooks/useSuperAdminHardwareLookups";
import { useClipboardCopy } from "../../../hooks/useClipboardCopy";

const colHelper = createColumnHelper<IHardwareManufacturer>();

export function HardwareManufacturersPage() {
  const { CopyToClipboardButton } = useClipboardCopy();
  const { delHardwareManufacturer, updateHardwareManufacturer } =
    useHardwareManufacturerMutations();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editHardwareManufacturer, setEditHardwareManufacturer] = useState<
    IHardwareManufacturer | undefined
  >(undefined);
  const [deleteHardwareManufacturer, setDeleteHardwareManufacturer] = useState<
    IHardwareManufacturer | undefined
  >(undefined);
  const { isLoading, typeLookup } = useSuperAdminHardwareLookups();

  const handleDeleteHardwareManufacturer = (
    hardwareManufacturer: IHardwareManufacturer
  ) => {
    setDeleteHardwareManufacturer(hardwareManufacturer);
    setDeleteOpen(true);
  };

  const handleConfirmDeleteHardwareManufacturer = () => {
    delHardwareManufacturer.mutate(deleteHardwareManufacturer!);
    setDeleteOpen(false);
  };

  const handleRestoreHardwareManufacturer = (
    hardwareManufacturer: IHardwareManufacturer
  ) => {
    updateHardwareManufacturer.mutate({
      ...hardwareManufacturer,
      restore: true,
    });
  };

  const handleEditHardwareManufacturer = (
    hardwareManufacturer: IHardwareManufacturer
  ) => {
    setEditHardwareManufacturer(hardwareManufacturer);
    setEditOpen(true);
  };

  const handleCreateEditClose = () => {
    editHardwareManufacturer &&
      setTimeout(() => setEditHardwareManufacturer(undefined), 200);
    setEditOpen(false);
  };

  const cols = [
    colHelper.display({
      header: "Status",
      cell: (info) =>
        info.row.original.disabled ? (
          <XMarkIcon className="w-5 h-5 opacity-70" />
        ) : (
          <CheckIcon className="w-5 h-5 opacity-70" />
        ),
    }),
    colHelper.accessor("id", { header: "Id" }),
    colHelper.accessor((row) => typeLookup(row.hardwareTypeId)?.name, {
      header: "Type",
    }),
    colHelper.accessor("name", { header: "Manufacturer" }),
    colHelper.display({
      header: "Actions",
      cell: (info) => (
        <div className="flex items-center space-x-4">
          {info.cell.row.original.deletedOn ? (
            <ArrowUturnDownIcon
              className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
              onClick={() =>
                handleRestoreHardwareManufacturer(info.row.original)
              }
            />
          ) : (
            <>
              <CopyToClipboardButton iconOnly copyText={info.row.original.id} />
              <PencilIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleEditHardwareManufacturer(info.row.original)
                }
              />
              <TrashIcon
                className="w-5 h-5 opacity-70 hover:opacity-100 hover:cursor-pointer"
                onClick={() =>
                  handleDeleteHardwareManufacturer(info.row.original)
                }
              />
            </>
          )}
        </div>
      ),
    }),
  ];

  return (
    <>
      <CreateEditHardwareManufacturer
        open={editOpen}
        onClose={handleCreateEditClose}
        hardwareManufacturer={editHardwareManufacturer}
      />
      <ConfirmDelete
        itemName={deleteHardwareManufacturer?.name}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onConfirm={() => handleConfirmDeleteHardwareManufacturer()}
      />
      <Card title="Hardware Manufacturers">
        <div className="my-6">
          <Button onClick={() => setEditOpen(true)}>Add Manufacturer</Button>
        </div>
        {isLoading ? (
          <LoadingWheel />
        ) : (
          <div>
            <Table<IHardwareManufacturer>
              url="/superadmin/hardware/manufacturers"
              queryKey={["superadmin", "hardware", "manufacturers"]}
              columns={cols}
              enableShowDeleted
            />
          </div>
        )}
      </Card>
    </>
  );
}
