import { useEffect, useReducer, useState } from "react";

import {
  AdjustmentsVerticalIcon,
  ChartBarIcon,
  ChartPieIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { Card } from "../base/Card";
import { StatCard } from "./StatCard";
import { GraphCard } from "./GraphCard";
import { Button } from "../base/Button";
import { generateRandomString } from "../../utils/random";
import { useGraphSettings } from "../../contexts/GraphSettingsContext";
import {
  IStatisticConfiguration,
  StatisticConfigurationCreateUpdate,
} from "../../types/Statistic/Statistic";
import { IStatistic, StatisticType } from "../../types/Statistic/Statistic";
import useStatisticConfigurationsMutations from "../../data/configuration/useStatisticConfigurationsMutations";

interface StatisticsState {
  shouldSync: boolean;
  configuration: IStatistic[];
}

export type StatisticStateReducerActions =
  | { type: "addGraph" }
  | { type: "removeGraph"; payload: { id: string } }
  | { type: "updateTitle"; payload: { id: string; title: string } }
  | { type: "addDataPoint"; payload: { id: string; dataPoint: string } }
  | {
      type: "removeDataPoint";
      payload: { id: string; dataPoint: string };
    }
  | { type: "addStat"; payload: { type: Exclude<StatisticType, "graph"> } }
  | { type: "removeStat"; payload: { id: string } }
  | {
      type: "updateStatConfig";
      payload: { id: string; dataPointId: string; dataPointName: string };
    }
  | { type: "moveTile"; payload: { id: string; direction: "up" | "down" } };

function statisticsConfigReducer(
  state: StatisticsState,
  action: StatisticStateReducerActions
): StatisticsState {
  switch (action.type) {
    case "updateTitle": {
      const updatedConfigurations = state.configuration.map((config) =>
        config.id === action.payload.id
          ? { ...config, title: action.payload.title }
          : config
      );
      return {
        ...state,
        shouldSync: true,
        configuration: updatedConfigurations,
      };
    }
    case "addDataPoint": {
      const updatedConfigurations = state.configuration.map((config) =>
        config.id === action.payload.id
          ? {
              ...config,
              dataPointIds: [...config.dataPointIds, action.payload.dataPoint],
            }
          : config
      );
      return {
        ...state,
        shouldSync: true,
        configuration: updatedConfigurations,
      };
    }
    case "removeDataPoint": {
      const updatedConfigurations = state.configuration.map((config) =>
        config.id === action.payload.id
          ? {
              ...config,
              dataPointIds: config.dataPointIds.filter(
                (d) => d !== action.payload.dataPoint
              ),
            }
          : config
      );
      return {
        ...state,
        shouldSync: true,
        configuration: updatedConfigurations,
      };
    }
    case "addGraph": {
      const newGraph: IStatistic = {
        type: StatisticType.graph,
        id: generateRandomString(6),
        title: "New Graph",
        dataPointIds: [],
      };
      return {
        ...state,
        shouldSync: true,
        configuration: [...state.configuration, newGraph],
      };
    }
    case "removeGraph": {
      return {
        ...state,
        shouldSync: true,
        configuration: state.configuration.filter(
          (config) => config.id !== action.payload.id
        ),
      };
    }
    case "addStat": {
      const newStat: IStatistic = {
        type: action.payload.type,
        id: generateRandomString(6),
        title: `New ${
          action.payload.type === StatisticType.accumulation
            ? "Accumulation"
            : "Average"
        } `,
        dataPointIds: [],
      };
      return {
        ...state,
        shouldSync: true,
        configuration: [...state.configuration, newStat],
      };
    }
    case "updateStatConfig": {
      const updatedConfigurations = state.configuration.map((config) =>
        config.id === action.payload.id
          ? {
              ...config,
              title: `${config.type === StatisticType.average ? "Average" : "Total"} ${action.payload.dataPointName}`,
              dataPointIds: [action.payload.dataPointId],
            }
          : config
      );
      return {
        ...state,
        shouldSync: true,
        configuration: updatedConfigurations,
      };
    }
    case "removeStat": {
      return {
        ...state,
        shouldSync: true,
        configuration: state.configuration.filter(
          (config) => config.id !== action.payload.id
        ),
      };
    }
    case "moveTile": {
      const index = state.configuration.findIndex(
        (cfg) => cfg.id === action.payload.id
      );

      if (
        index === -1 ||
        (index === 0 && action.payload.direction === "up") ||
        (index === state.configuration.length - 1 &&
          action.payload.direction === "down")
      ) {
        return state;
      }

      // Determine the index to swap with
      const newIndex =
        action.payload.direction === "up" ? index - 1 : index + 1;

      // Create a new configuration array
      const newConfiguration = [...state.configuration];

      // Swap the objects
      const temp = newConfiguration[index];
      newConfiguration[index] = newConfiguration[newIndex];
      newConfiguration[newIndex] = temp;

      // Return the new state
      return { ...state, configuration: newConfiguration, shouldSync: true };
    }
    default:
      return state;
  }
}

interface StatisticsProps {
  userConfiguration: IStatisticConfiguration;
}

export function Statistics({ userConfiguration }: StatisticsProps) {
  const { createConfiguration, updateConfiguration } =
    useStatisticConfigurationsMutations();
  const [statisticsState, dispatch] = useReducer(statisticsConfigReducer, {
    shouldSync: false,
    configuration: userConfiguration.statistics,
  });
  const {
    currentValue: { hardware },
  } = useGraphSettings();
  const [showAddOptions, setShowAddOptions] = useState(false);

  const handleAddGraph = () => {
    dispatch({ type: "addGraph" });
    setShowAddOptions(false);
  };

  const handleAddAverage = () => {
    dispatch({ type: "addStat", payload: { type: StatisticType.average } });
    setShowAddOptions(false);
  };

  const handleAddAccumulation = () => {
    dispatch({
      type: "addStat",
      payload: { type: StatisticType.accumulation },
    });
    setShowAddOptions(false);
  };

  const createUserConfiguration = (
    config: StatisticConfigurationCreateUpdate
  ) => createConfiguration.mutate(config);

  const updateUserConfiguration = (config: IStatisticConfiguration) =>
    updateConfiguration.mutate(config);

  useEffect(() => {
    if (statisticsState.shouldSync) {
      if (userConfiguration.id) {
        updateUserConfiguration({
          ...userConfiguration,
          statistics: statisticsState.configuration.map((cfg) =>
            cfg.id && !cfg.id.includes("-") ? { ...cfg, id: undefined } : cfg
          ),
        });
      } else {
        createUserConfiguration({
          ...userConfiguration,
          id: undefined,
          createdOn: undefined,
          createdBy: undefined,
          statistics: statisticsState.configuration.map((cfg) =>
            cfg.id && !cfg.id.includes("-") ? { ...cfg, id: undefined } : cfg
          ),
        });
      }
    }
  }, [statisticsState]);

  return (
    <div className="grid grid-cols-1 gap-6">
      {hardware !== undefined ? (
        <>
          {statisticsState.configuration.map(
            (config) =>
              ({
                0: (
                  <GraphCard
                    {...config}
                    id={config.id!}
                    key={`statistic-${config.id}`}
                    dispatch={dispatch}
                    hardware={hardware}
                  />
                ),
                1: (
                  <StatCard
                    {...config}
                    id={config.id!}
                    key={`statistic-${config.id}`}
                    dispatch={dispatch}
                    hardware={hardware}
                  />
                ),
                2: (
                  <StatCard
                    {...config}
                    id={config.id!}
                    key={`statistic-${config.id}`}
                    dispatch={dispatch}
                    hardware={hardware}
                  />
                ),
              })[config.type as StatisticType]
          )}
          <div className="flex">
            {showAddOptions ? (
              <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                <Button
                  onClick={handleAddGraph}
                  className="w-full flex justify-center items-center space-x-4"
                >
                  <ChartBarIcon className="w-6 h-6" />
                  <span>Add Graph</span>
                </Button>
                <Button
                  onClick={handleAddAverage}
                  className="w-full flex justify-center items-center space-x-4"
                >
                  <AdjustmentsVerticalIcon className="w-6 h-6" />
                  <span>Add Average</span>
                </Button>
                <Button
                  onClick={handleAddAccumulation}
                  className="w-full flex justify-center items-center space-x-4"
                >
                  <ChartPieIcon className="w-6 h-6" />
                  <span>Add Accumulation</span>
                </Button>
                <div className="md:col-span-3">
                  <Button
                    variant="outlined"
                    className="w-full flex justify-center items-center space-x-4 border-red-500 dark:border-red-700 hover:bg-red-700/20"
                    onClick={() => setShowAddOptions(false)}
                  >
                    <XMarkIcon className="w-6 h-6" />
                    <span>Cancel</span>
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                onClick={() => setShowAddOptions(true)}
                className="w-full flex justify-center items-center space-x-4"
              >
                <PlusIcon className="w-6 h-6" />
                Add Tile
              </Button>
            )}
          </div>
        </>
      ) : (
        <Card className="h-[200px] flex items-center justify-center">
          <p>Please select a device and a timespan above</p>
        </Card>
      )}
    </div>
  );
}
