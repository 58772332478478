import { useQuery } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IStatisticConfiguration } from "../../types/Statistic/Statistic";

export default function useStatisticConfigurations() {
  const { get } = useApiHelper();

  const statisticConfigurations = useQuery({
    queryKey: userQueryKeys.configurations(1),
    queryFn: () =>
      get<IDefaultResponseWithData<IStatisticConfiguration>>(
        "/v1/organisation/configurations?type=1"
      ).then((res) => res.data.data),
  });

  return {
    userConfigurations: statisticConfigurations,
  };
}
