// import clsx from "clsx";

import {
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { Card } from "../base/Card";
import { Button } from "../base/Button";
import { MiniTable } from "../base/MiniTable";
import { useNavigate } from "react-router";
import { SectionLoading } from "../shared/SectionLoading";
import useAlert from "../../data/alerts/useAlert";
import { AlertSeverity } from "../../types/Alerts/Alerts";
import { AlertIcon } from "../shared/AlertIcon";

interface AlertRowProps {
  severity: AlertSeverity;
  customer: string;
  alertText: string;
  timestamp: string;
}

function AlertRow({
  severity = 0,
  customer,
  alertText,
  timestamp,
}: AlertRowProps) {
  return (
    <div className="flex items-center space-x-4 px-4 py-3 bg-gray-400/10 hover:bg-gray-400/20 hover:cursor-pointer dark:bg-white/10 dark:hover:bg-white/20">
      <div className="">
        <AlertIcon severity={severity} />
      </div>
      <div className="flex flex-col">
        <span className="">{alertText}</span>
        <span className="text-sm text-gray-700 dark:text-gray-400">
          {customer}
        </span>
        <span className="text-xs text-gray-700 dark:text-gray-400">
          {timestamp}
        </span>
      </div>
    </div>
  );
}

export function Alerts() {
  const navigate = useNavigate();
  const { alerts } = useAlert();

  return (
    <Card title="Alerts">
      <div className="mt-4 space-y-6">
        <div className="grid grid-cols-2 divide-x divide-gray-400 dark:divide-gray-600">
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center">
              <XCircleIcon className="mt-2 mr-2 w-10 h-10 text-red-500" />
              <span className="text-6xl">
                {
                  alerts.data?.filter((x) => x.severity === AlertSeverity.Error)
                    .length
                }
              </span>
            </div>
            <span className="text-md">Error(s)</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center">
              <ExclamationCircleIcon className="mt-2 mr-2 w-10 h-10 text-amber-500" />
              <span className="text-6xl">
                {
                  alerts.data?.filter(
                    (x) => x.severity === AlertSeverity.Warning,
                  ).length
                }
              </span>
            </div>
            <span className="text-md">Warning(s)</span>
          </div>
        </div>
        <div>
          <h4 className="mb-2 font-medium text-lg">Most Recent Alerts</h4>
          {alerts.isLoading ? (
            <SectionLoading />
          ) : (
            <MiniTable>
              {alerts.data
                ?.sort((a, b) => (a.severity > b.severity ? -1 : 1))
                .slice(0, 4)
                .map((alert) => (
                  <AlertRow
                    severity={alert.severity}
                    customer={alert.customerId}
                    alertText={alert.title}
                    timestamp={alert.description}
                  />
                ))}
            </MiniTable>
          )}
        </div>
        <div>
          <Button onClick={() => navigate("alerts")}>View All</Button>
        </div>
      </div>
    </Card>
  );
}
