import { useEffect, useState } from "react";

import date from "date-and-time";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { IHardware } from "../../types/Hardware/Hardware";
import { usePageTitle } from "../../contexts/PageTitleContext";
import { useLiveReading } from "../../contexts/LiveReadingContext";
import { SectionLoading } from "../../components/shared/SectionLoading";
import useDataPointsByHardwareProfileId from "../../data/datapoint/useDataPointsByHardwareProfile";
import { useReadingsProcessor } from "../../hooks/useReadingProcessor";
import { ITemplateConfiguration } from "../../types/Template/Template";
import { ReadingCard } from "./LiveReadingCard/ReadingCard";
import { Card } from "../base/Card";
import { ReadingTableRow } from "./LiveReadingTable/ReadingTableRow";
import { Register } from "./../../pages/public/register";

interface IReadingsDisplayProps {
  selectedHardware: IHardware;
  tableView: boolean;
  selectedTemplate?: ITemplateConfiguration;
}

export function ReadingsDisplay({
  selectedHardware,
  tableView,
  selectedTemplate,
}: IReadingsDisplayProps) {
  const { setSubTitle } = usePageTitle();
  const { dataPoints } = useDataPointsByHardwareProfileId(
    selectedHardware.id,
    selectedTemplate?.id,
    selectedTemplate?.ownerId
  );
  const { latest, latestTimestamp, loading } = useLiveReading();
  const { processorReady } = useReadingsProcessor();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      processorReady &&
      !loading &&
      !dataPoints.isLoading &&
      dataPoints.data
    ) {
      setIsLoading(false);
    }
  }, [processorReady, loading, dataPoints.isLoading, dataPoints.data]);

  useEffect(() => {
    if (!loading && latestTimestamp) {
      setSubTitle(
        `Last updated: ${date.format(
          new Date(latestTimestamp),
          "DD/MM/YYYY HH:mm:ss"
        )}`
      );
    } else if (!loading && !latest) {
      setSubTitle("Last updated: Never");
    }
  }, [setSubTitle, loading, latest, latestTimestamp]);

  return isLoading || dataPoints.isLoading || !dataPoints.data ? (
    <SectionLoading />
  ) : !tableView ? (
    <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 768: 2 }}>
      <Masonry gutter="1.5rem">
        {dataPoints.data![0].datapoints.map((dp) => (
          <ReadingCard
            key={dp.id}
            hardware={selectedHardware}
            timestamp={latest ? latest.timestamp : ""}
            dataPoint={dp}
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  ) : (
    <Card>
      <div className="block overflow-x-auto overflow-y-hidden">
        <table className="min-w-full table border border-primary/40 divide-y divide-primary dark:border-primary">
          <thead className="bg-primary/40">
            <tr>
              <th className="w-6/8 px-3 py-3 text-left text-sm font-semibold">
                Name
              </th>
              <th className="w-1/8 px-3 py-3 text-left text-sm font-semibold">
                Manufacturer Reference
              </th>
              <th className="w-1/8 px-3 py-3 text-left text-sm font-semibold">
                Value
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-primary/40">
            {dataPoints.data![0].datapoints.map((dp, i) => (
              <ReadingTableRow key={dp.id} dataPoint={dp} rowIndex={i} />
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
