import { useMutation, useQuery, useQueryClient } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";

import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IJob } from "../../types/Job/Job";
import { PostJobRequest } from "../../types/requests/job/PostJobRequest";
import { useDefaultMutateResponseHandlers } from "../../hooks/useDefaultMutateResponeHandlers";

export default function useJobs() {
  const queryClient = useQueryClient();
  const { get, post, put } = useApiHelper();
  const { handleSuccess, handleError } =
    useDefaultMutateResponseHandlers("Hardware");

  const jobs = useQuery({
    queryKey: ["jobs"],
    queryFn: () =>
      get<IDefaultResponseWithData<IJob>>("/v1/job").then(
        (res) => res.data.data,
      ),
  });

  const createJob = useMutation({
    mutationFn: (job: PostJobRequest) => post("/v1/job", job),
    onError: (error) => handleError(error, "create"),
    onSuccess: (data) => handleSuccess(data, "create"),
    onSettled: () => queryClient.invalidateQueries("jobs"),
  });

  const updateJob = useMutation({
    mutationFn: (job: IJob) => put(`/v1/job/${job.id}`, job),
    onError: (error) => handleError(error, "update"),
    onSuccess: (data) => handleSuccess(data, "update"),
    onSettled: () => queryClient.invalidateQueries("jobs"),
  });

  return {
    jobs,
    createJob,
    updateJob,
  };
}
