import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { DateTime } from "luxon";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useTheme } from "../../../contexts/ThemeContext";
import { IDataPoint } from "../../../types/DataPoint/DataPoint";
import { IReadingPartial } from "../../../types/DataPoint/Reading";
import { useLiveReading } from "../../../contexts/LiveReadingContext";
import useReadingsHistory from "../../../data/datapoint/useReadingsHistory";

interface LiveGraphProps {
  dataPoint: IDataPoint;
  hardwareId: string;
  endDate: string;
}

interface GraphData {
  timestamp: number;
  value: number | null;
}

export function LiveGraph({ dataPoint, hardwareId, endDate }: LiveGraphProps) {
  const { theme } = useTheme();
  const [endDateInternal, __] = useState(
    new Date(DateTime.fromISO(endDate).toJSDate())
  );
  const [startDateInternal, _] = useState(
    new Date(
      DateTime.fromJSDate(endDateInternal).minus({ minutes: 2 }).toJSDate()
    )
  );
  const { readings } = useReadingsHistory(
    hardwareId,
    dataPoint.id,
    startDateInternal.toISOString(),
    endDateInternal.toISOString()
  );
  const { lastUpdate, loading } = useLiveReading();
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRef = useRef<am5.Series | null>(null);
  const chartColour = theme[theme.isDark ? "dark" : "light"].primary;
  const cache = useRef<{ shouldFill: boolean; data: IReadingPartial[] }>({
    shouldFill: true,
    data: [],
  });

  const graphReady = useRef(false);
  const shouldUpdate = useRef(false);
  const noReadings = useRef(false);

  const showNoReadings = () => {
    noReadings.current = true;
    chartRef.current!.hide();
    rootRef.current!.container.children.push(
      am5.Label.new(rootRef.current!, {
        id: `${hardwareId}-${dataPoint.id}-no-readings-text`,
        text: "No Readings",
        fontSize: 16,
        x: am5.percent(50),
        y: am5.percent(50),
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        textAlign: "center",
      })
    );
  };

  const hideNoReadings = () => {
    noReadings.current = false;
    rootRef
      .current!.container.children.getIndex(
        rootRef.current!.container.children.length - 1
      )
      ?.dispose();
    chartRef.current!.show();
  };

  const addReadingsToGraph = (readings: IReadingPartial[]) => {
    const readingsToAdd: GraphData[] = [];

    // Find readings for the data point of this card
    // Push to array in correct format
    readings.forEach((r) => {
      let readingsForDataPoint = r.readingData.filter(
        (rd) => rd.dataPointId === dataPoint.id
      );

      if (readingsForDataPoint.length > 0) {
        readingsToAdd.push({
          timestamp: new Date(r.timestamp).getTime(),
          value: readingsForDataPoint[0].transformedValue,
        });
      } else {
        readingsToAdd.push({
          timestamp: new Date(r.timestamp).getTime(),
          value: null,
        });
      }
    });

    if (readingsToAdd.length > 0) {
      // Reverse readings for graph
      readingsToAdd.reverse();

      // Get the most recent reading timetamp and convert to luxon object
      const mostRecentReading =
        readingsToAdd[readingsToAdd.length - 1].timestamp;
      const luxonDateTime = DateTime.fromMillis(mostRecentReading);

      // Subtract 5 minutes from the most recent timestamp
      const fiveMinutesAgo = luxonDateTime.minus({ minutes: 2 }).toMillis();

      // Add all items older than 5 mins to an array
      let removeItems: any[] = [];
      seriesRef.current!.data.values.forEach((v: any, i) =>
        v.timestamp < fiveMinutesAgo ? removeItems.push(v) : () => {}
      );

      seriesRef!.current?.data.pushAll(readingsToAdd);

      removeItems.forEach((v) => seriesRef.current!.data.removeValue(v));

      // Check series contains any valid data
      const hasNoData = seriesRef.current!.data.values.every(
        (v: any) => v.value === null
      );

      if (hasNoData && chartRef.current!.isHidden() === false) {
        showNoReadings();
      } else if (!hasNoData && chartRef.current!.isHidden()) {
        hideNoReadings();
      }

      // Set flag to allow updates
      if (shouldUpdate.current === false) {
        shouldUpdate.current = true;
      }
    }
  };

  useLayoutEffect(() => {
    const root = am5.Root.new(`chartdiv-${hardwareId}-${dataPoint.id}`);

    root.setThemes([am5themes_Animated.new(root)]);

    if (theme.isDark) {
      root.interfaceColors.set("text", am5.color(0xffffff));
    } else {
      root.interfaceColors.set("text", am5.color(0x000000));
    }

    rootRef.current = root;

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        paddingLeft: 0,
        paddingRight: 0,
        layout: root.verticalLayout,
        pinchZoomY: false,
        pinchZoomX: false,
        wheelY: "none",
        wheelX: "none",
        wheelable: false,
        panX: false,
        panY: false,
      })
    );

    chartRef.current = chart;

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        autoZoom: false,
        extraTooltipPrecision: 1,
        numberFormat: `##${dataPoint.unit}`,
        renderer: am5xy.AxisRendererY.new(root, {}),
        zoomY: false,
        zoomX: false,
        panX: false,
        panY: false,
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        baseInterval: {
          timeUnit: "second",
          count: 1,
        },
        zoomY: false,
        zoomX: false,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      visible: false,
    });

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        connect: false,
        autoGapCount: 10,
        name: "Data",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "timestamp",
        tooltip: am5.Tooltip.new(root, {
          labelText: `[bold]{valueY} ${dataPoint.unit}`,
        }),
        fill: am5.color(
          `rgb(${chartColour.r}, ${chartColour.g}, ${chartColour.b})`
        ),
        stroke: am5.color(
          `rgb(${chartColour.r}, ${chartColour.g}, ${chartColour.b})`
        ),
      })
    );

    series.strokes.template.set("strokeWidth", 2);
    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.4,
    });

    seriesRef.current = series;

    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      })
    );

    graphReady.current = true;

    return () => {
      rootRef.current = null;
      chartRef.current = null;
      seriesRef.current = null;
      root.dispose();
    };
  }, [
    chartColour.b,
    chartColour.g,
    chartColour.r,
    dataPoint.id,
    dataPoint.name,
    dataPoint.unit,
    hardwareId,
    theme.isDark,
  ]);

  useEffect(() => {
    if (graphReady.current === true && !readings.isLoading && readings.data) {
      if (readings.data.length > 0) {
        addReadingsToGraph(readings.data!);
      } else if (!noReadings.current) {
        showNoReadings();
      }
    }
  }, [graphReady, readings.isLoading, readings.data]);

  useEffect(() => {
    if (!loading && lastUpdate && cache.current.shouldFill) {
      cache.current.data.unshift(...lastUpdate);
    }
  }, [hardwareId, loading, lastUpdate, cache.current.shouldFill]);

  useEffect(() => {
    if (
      seriesRef.current &&
      shouldUpdate.current === true &&
      !loading &&
      lastUpdate
    ) {
      if (cache.current.shouldFill && cache.current.data.length > 0) {
        cache.current.shouldFill = false;

        addReadingsToGraph(cache.current.data);
      } else {
        const readings = [...lastUpdate];

        addReadingsToGraph(readings);
      }
    }
  }, [loading, lastUpdate]);

  return (
    <div
      id={`chartdiv-${hardwareId}-${dataPoint.id}`}
      className="flex items-center justify-center"
      style={{ width: "100%", height: "200px" }}
    ></div>
  );
}
