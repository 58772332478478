import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { AlertSeverity } from "../../types/Alerts/Alerts";

export interface AlertIconProps {
  severity: AlertSeverity;
}

export function AlertIcon({ severity }: AlertIconProps) {
  if (severity === AlertSeverity.Info) {
    return <InformationCircleIcon className="w-8 h-8 text-blue-500" />;
  } else if (severity === AlertSeverity.Success) {
    return <CheckCircleIcon className="w-8 h-8 text-green-500" />;
  } else if (severity === AlertSeverity.Warning) {
    return <ExclamationCircleIcon className="w-8 h-8 text-amber-500" />;
  } else {
    return <XCircleIcon className="w-8 h-8 text-red-500" />;
  }
}
