import { useQuery } from "react-query";

import useApiHelper from "../../hooks/useApiHelper";
import { subscriptionQueryKeys } from "./QueryKeys";
import { ISubscriptionSeats } from "../../types/Billing/Subscription";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";

export default function useUsedSeats() {
  const { get } = useApiHelper();

  const usedSeats = useQuery({
    queryKey: subscriptionQueryKeys.usedSeats(),
    queryFn: () =>
      get<IDefaultResponseWithData<ISubscriptionSeats>>(
        "/v1/billing/subscription/used",
      ).then((res) => res.data.data),
  });

  return {
    usedSeats,
  };
}
