import { Fragment, useEffect, useState } from "react";

import clsx from "clsx";
import jwtDecode from "jwt-decode";
import { NavLink } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate } from "react-router";

import * as Sentry from "@sentry/react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MoonIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import useUser from "../data/user/useUser";
import useApiHelper from "../hooks/useApiHelper";
import { ErrorFallback } from "../ErrorFallback";
import { Button } from "../components/base/Button";
import { useTheme } from "../contexts/ThemeContext";
import { Avatar } from "../components/shared/Avatar";
import { useUserContext } from "../contexts/UserContext";
import { usePageTitle } from "../contexts/PageTitleContext";
import { LoadingWheel } from "../components/base/LoadingWheel";
import { FullscreenLoading } from "../components/shared/FullscreenLoading";
import { authAPIURL, CDNURL, isProd, pdOrgId } from "../config";
import useUserOrganisations from "../data/user/useUserOrganisations";

export default function AuthenticatedLayout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    theme: { isDark, logo },
    setTheme,
  } = useTheme();
  const {
    isLoggedIn,
    isInstaller,
    isSuperAdmin,
    organisationName,
    organisationId,
    installerId,
    installerName,
    superAdminId,
    loading: userLoading,
    setUser,
  } = useUserContext();
  const { pageTitle, subTitle } = usePageTitle();
  const { post, get } = useApiHelper({
    apiUrl: authAPIURL,
    options: { credentials: "include" },
    headers: { Authorization: "" },
  });
  const { user } = useUser();
  const { userOrganisations } = useUserOrganisations();
  const [loading, setLoading] = useState(true);

  // ! stops tailwind stripping the class
  const bgBackdrop = "bg-backdrop"; //eslint-disable-line

  const getNavigation = () => {
    let type = "customer";

    if (superAdminId && installerId) {
      type = "installerActAsCustomer";
    } else if (superAdminId) {
      type = "installer";
    } else if (installerId) {
      type = "installerActAsCustomer";
    } else {
      if (isSuperAdmin) {
        type = "superadmin";
      } else if (isInstaller) {
        type = "installer";
      } else {
        type = "customer";
      }
    }

    switch (type) {
      case "superadmin":
        let routes = [
          { name: "Dashboard", href: "dashboard" },
          { name: "Alerts", href: "alerts" },
          { name: "Jobs", href: "jobs" },
          { name: "Live Readings", href: "/", end: true },
          { name: "Historical Readings", href: "history" },
          { name: "Installers", href: "installers" },
          { name: "Customers", href: "customers" },
          { name: "Devices", href: "hardware" },
        ];

        !isProd && routes.push({ name: "System", href: "system" });

        return routes;
      case "installer":
        return [
          { name: "Live Readings", href: "/", end: true },
          { name: "Historical Readings", href: "history" },
          { name: "Customers", href: "customers" },
          { name: "Devices", href: "hardware" },
        ];
      case "installerActAsCustomer":
        return [
          { name: "Live Readings", href: "/", end: true },
          { name: "Historical Readings", href: "history" },
          { name: "Devices", href: "hardware" },
        ];
      case "customer":
      default:
        return [
          { name: "Live Readings", href: "/", end: true },
          { name: "Historical Readings", href: "history" },
          { name: "Devices", href: "hardware" },
        ];
    }
  };

  const handleLogout = () => {
    get("/v1/sso/logout", { withCredentials: true }).then(() => {
      setUser({ type: "loading", payload: true });
      setUser({ type: "logout" });
      queryClient.clear();
      setTheme({ type: "reset" });
      setUser({ type: "loading", payload: false });
      navigate("/login");
    });
  };

  const handleInstallerSwitchBack = async () => {
    try {
      let tokenResponse = await post(
        "/v1/sso/token",
        {
          organisationId: installerId ?? "",
          type: "portal",
        },
        { withCredentials: true }
      );

      let decodedToken = jwtDecode<{ oid: string }>(tokenResponse.data.token!);

      setUser({ type: "loading", payload: true });
      setUser({
        type: "installerSwitchBack",
        payload: {
          token: tokenResponse.data.token!,
          organisationId: decodedToken.oid,
        },
      });
      queryClient.clear();
      navigate("/customers");
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleSuperAdminSwitchBack = async () => {
    try {
      let tokenResponse = await post(
        "/v1/sso/token",
        {
          organisationId: pdOrgId,
          type: "portal",
        },
        {
          withCredentials: true,
        }
      );

      let decodedToken = jwtDecode<{ oid: string; uid: string }>(
        tokenResponse.data.token!
      );

      setUser({ type: "loading", payload: true });
      setUser({ type: "superAdminSwitchBack" });
      setUser({
        type: "login",
        payload: {
          token: tokenResponse.data.token!,
          userId: decodedToken.uid,
          organisationId: decodedToken.oid,
        },
      });
      queryClient.clear();
      navigate("/installers");
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleLoginAsCustomer = async (organisationId: string) => {
    try {
      let tokenResponse = await post(
        "/v1/sso/token",
        {
          organisationId: organisationId,
          type: "portal",
        },
        {
          withCredentials: true,
        }
      );

      let decodedToken = jwtDecode<{ oid: string }>(tokenResponse.data.token!);

      setUser({ type: "loading", payload: true });
      setUser({
        type: "installerLoginAs",
        payload: {
          token: tokenResponse.data.token!,
          organisationId: decodedToken.oid,
          installerId: user.data?.defaultOrganisation!,
          installerName: installerId ? organisationName! : installerName!,
        },
      });

      queryClient.clear();
      queryClient.cancelQueries();

      navigate("/");
    } catch (err) {
      console.error("err", err);
    } finally {
      setUser({ type: "loading", payload: false });
    }
  };

  const getUserNavigation = () => {
    return [
      { name: "Settings", onClick: () => navigate("settings") },
      { name: "Sign out", onClick: handleLogout },
    ];
  };

  useEffect(() => {
    if (!userLoading) {
      if (isLoggedIn) {
        setLoading(false);
      } else {
        navigate("/login");
      }
    }
  }, [userLoading, isLoggedIn, navigate]);

  if (loading) {
    return <FullscreenLoading />;
  } else {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <div className="min-h-full">
          {installerId !== undefined ||
          (isSuperAdmin && organisationId !== pdOrgId) ? (
            <div className="py-2 text-center bg-blue-200">
              {isSuperAdmin && organisationId !== pdOrgId && (
                <>
                  Acting as installer
                  <span className="font-medium">
                    {" "}
                    {installerId ? installerName : organisationName}
                  </span>
                  <Button
                    size="sm"
                    className="ml-2 !border-none !text-white !bg-blue-500 hover:!bg-blue-600 active:!bg-blue-600"
                    onClick={handleSuperAdminSwitchBack}
                  >
                    Switch Back
                  </Button>
                </>
              )}
              {installerId !== undefined && (
                <>
                  {isSuperAdmin && organisationId !== pdOrgId && (
                    <span className="mx-4 text-bold">|</span>
                  )}
                  Acting as customer
                  <span className="font-medium"> {organisationName}</span>
                  <Button
                    size="sm"
                    className="ml-2 !border-none !text-white !bg-blue-500 hover:!bg-blue-600 active:!bg-blue-600"
                    onClick={handleInstallerSwitchBack}
                  >
                    Switch Back
                  </Button>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="pb-32 bg-primary/60">
            <Disclosure
              as="nav"
              className="bg-primary border-primary border-b border-opacity-25 lg:border-none"
            >
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                    <div className="border-primary relative flex h-16 items-center justify-between lg:border-b lg:border-opacity-25">
                      <div className="flex-shrink-0">
                        <img
                          className="block h-12 max-w-[400px]"
                          src={
                            logo
                              ? logo
                              : `${CDNURL}/assets/images/pd-logo-long.png`
                          }
                          alt="Your Company"
                        />
                      </div>
                      <div className="hidden xl:ml-auto xl:mr-auto xl:block">
                        <div className="flex space-x-4">
                          {getNavigation().map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              end={item.end}
                              className={({ isActive }) =>
                                clsx(
                                  isActive
                                    ? `bg-white/20 text-primaryContrast`
                                    : `text-primaryContrast hover:bg-white/40`,
                                  "rounded-md py-2 px-3 text-sm font-medium"
                                )
                              }
                            >
                              {item.name}
                            </NavLink>
                          ))}
                        </div>
                      </div>
                      <div className="flex xl:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-primary p-2 text-white hover:bg-primary/90 focus:outline-none focus:ring-1 focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="hidden xl:ml-4 xl:block">
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() =>
                              setTheme({
                                type: "dark",
                                payload: !isDark,
                              })
                            }
                            className="flex-shrink-0 rounded-full bg-primary p-1 text-primaryContrast focus:outline-none focus:ring-1 focus:ring-primaryContrast"
                          >
                            <span className="sr-only">Toggle dark mode</span>
                            {isDark ? (
                              <SunIcon className="h-6 w-6" />
                            ) : (
                              <MoonIcon className="h-6 w-6" />
                            )}
                          </button>
                          {/* Hidden as notifications don't exist yet */}
                          {/* <button
                            type="button"
                            className="ml-2 flex-shrink-0 rounded-full bg-primary p-1 text-primaryContrast focus:outline-none focus:ring-1 focus:ring-primaryContrast"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button> */}

                          {/* Profile dropdown */}
                          <Menu
                            as="div"
                            className="relative ml-3 flex-shrink-0"
                          >
                            <div>
                              {user.isLoading ? (
                                <LoadingWheel size="xs" />
                              ) : (
                                <Menu.Button className="flex rounded-full bg-primary text-sm text-white focus:outline-white focus:ring-2 focus:ring-white">
                                  <span className="sr-only">
                                    Open user menu
                                  </span>
                                  <Avatar size={8} {...user.data!} />
                                </Menu.Button>
                              )}
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-paper py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {getUserNavigation().map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <button
                                        onClick={item.onClick}
                                        className={clsx(
                                          active
                                            ? "bg-gray-100 dark:bg-gray-700"
                                            : "",
                                          "w-full block px-4 py-2 text-sm text-left text-gray-700 dark:text-gray-200"
                                        )}
                                      >
                                        {item.name}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                                <div className="mx-1 h-px my-1 bg-gray-200 dark:bg-gray-700" />
                                {userOrganisations.isLoading ? (
                                  <div className="py-6 flex items-center justify-center">
                                    <LoadingWheel />
                                  </div>
                                ) : (
                                  <>
                                    {userOrganisations.data
                                      ?.filter(
                                        (organisation) =>
                                          organisation.id !== organisationId &&
                                          organisation.id !==
                                            user.data?.defaultOrganisation
                                      )
                                      .map((organisation) => {
                                        let nameParts =
                                          organisation.organisationName.split(
                                            " "
                                          );

                                        return (
                                          <Menu.Item key={organisation.id}>
                                            {({ active }) => (
                                              <button
                                                onClick={() =>
                                                  handleLoginAsCustomer(
                                                    organisation.id
                                                  )
                                                }
                                                className={clsx(
                                                  active
                                                    ? "bg-gray-100 dark:bg-gray-700"
                                                    : "",
                                                  "w-full block px-4 py-2 text-sm text-left text-gray-700 dark:text-gray-200"
                                                )}
                                              >
                                                <div className="grid grid-cols-4 space-x-3">
                                                  <div>
                                                    <Avatar
                                                      size={4}
                                                      id={organisation.id}
                                                      forename={nameParts[0]}
                                                      surname={
                                                        nameParts[1] ?? ""
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-span-3 flex flex-col justify-around items-start">
                                                    <span className="mt-0.5">
                                                      {
                                                        organisation.organisationName
                                                      }
                                                    </span>
                                                    <span className="text-xs text-gray-400 ">
                                                      {
                                                        organisation.organisationNumber
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </button>
                                            )}
                                          </Menu.Item>
                                        );
                                      })}
                                  </>
                                )}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="xl:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                      {getNavigation().map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as={NavLink}
                          to={item.href}
                          end={item.end}
                          className={({ isActive }: { isActive: boolean }) =>
                            clsx(
                              isActive
                                ? `bg-white/20 text-white`
                                : `text-white hover:bg-white/40`,
                              "block rounded-md py-2 px-3 text-base font-medium"
                            )
                          }
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                    <div className="border-t border-primary/70 pb-3 pt-4">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <Avatar
                            id={user.data?.id ?? ""}
                            forename={user.data?.forename ?? ""}
                            surname={user.data?.surname ?? ""}
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-white">
                            {`${user.data?.forename} ${user.data?.surname}`}
                          </div>
                          <div className="text-sm font-medium text-white/70">
                            {user.data?.email}
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() =>
                            setTheme({
                              type: "dark",
                              payload: !isDark,
                            })
                          }
                          className="ml-auto flex-shrink-0 rounded-full bg-white/20 p-2 text-white focus:outline-none focus:ring-1 focus:ring-white"
                        >
                          <span className="sr-only">Toggle dark mode</span>
                          {isDark ? (
                            <SunIcon className="h-6 w-6" />
                          ) : (
                            <MoonIcon className="h-6 w-6" />
                          )}
                        </button>
                        {/* Hidden as notifications don't exist yet */}
                        {/* <button
                          type="button"
                          className="ml-2 flex-shrink-0 rounded-full bg-white/20 p-2 text-white focus:outline-none focus:ring-1 focus:ring-white"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button> */}
                      </div>
                      <div className="mt-3 space-y-1 px-2 w-full">
                        {getUserNavigation().map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="button"
                            onClick={item.onClick}
                            className="w-full block rounded-md px-3 py-2 text-base font-medium text-left text-white hover:bg-white/40"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-primaryContrast xl:text-4xl">
                  {pageTitle}
                </h1>
                {subTitle !== undefined && (
                  <h3 className="text-sm tracking-tight text-primaryContrast xl:text-lg mt-2 ">
                    {subTitle}
                  </h3>
                )}
              </div>
            </header>
          </div>

          <main className="-mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </Sentry.ErrorBoundary>
    );
  }
}
