import { useState } from "react";

import { Button } from "../../base/Button";
import { ITemplateConfiguration } from "../../../types/Template/Template";
import { useUserContext } from "../../../contexts/UserContext";
import useInstallerTemplateConfigurations from "../../../data/configuration/useInstallerTemplateConfiguration";
import useCustomerTemplateConfigurationsMutations from "../../../data/configuration/useCustomerTemplateConfigurationsMutations";
import { ConfigurationType } from "../../../types/Configuration/Configuration";
import { LoadingWheel } from "../../base/LoadingWheel";
import { Alert } from "../../base/Alert";
import { TemplateSelect } from "./TemplateSelect";
import { Toggle } from "../../base/Toggle";

export interface ImportTemplateProps {
  setEditTemplate: (template: ITemplateConfiguration) => void;
}

export function ImportTemplate({
  setEditTemplate,
}: ImportTemplateProps) {
  const { organisationId } = useUserContext();
  const { templateConfigurations } = useInstallerTemplateConfigurations();
  const { createTemplate } = useCustomerTemplateConfigurationsMutations();
  const [selectedTemplate, setSelectedTemplate] =
    useState<ITemplateConfiguration>();
  const [shouldOpenEdit, setShouldOpenEdit] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const handleImportTemplate = () => {
    if (!selectedTemplate) {
      setError("Please select a template to import");
      return;
    }

    setSubmitting(true);

    createTemplate.mutate(
      {
        ...selectedTemplate!,
        id: undefined,
        type: ConfigurationType.template,
        ownerId: organisationId!,
      },
      {
        onSuccess: (data) => {
          if (shouldOpenEdit) {
            setEditTemplate(data[0]);
          }
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return templateConfigurations.isLoading ? (
    <div className="flex items-center justify-center py-10">
      <LoadingWheel />
    </div>
  ) : (
    <>
      {submitting ? (
        <div className="flex items-center justify-center py-10">
          <LoadingWheel />
        </div>
      ) : (
        <div className="mt-4 space-y-6">
          {error && <Alert message={error} severity="error" />}
          <TemplateSelect
            templates={templateConfigurations.data!}
            onChange={setSelectedTemplate}
          />
          <Toggle
            label="Edit after import"
            checked={shouldOpenEdit}
            onChange={() => setShouldOpenEdit((cv) => !cv)}
          />
          <Button fullWidth onClick={handleImportTemplate}>
            Import Template
          </Button>
        </div>
      )}
    </>
  );
}
