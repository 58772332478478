import { useNotifier } from "react-headless-notifier";
import {
  InfoNotification,
  ErrorNotification,
  SuccessNotification,
  WarningNotification,
} from "../components/shared/Notifications";

export function useNotification() {
  const { notify } = useNotifier();

  function addNotification(
    type: "info" | "success" | "warning" | "error",
    message: string,
    title?: string,
  ) {
    switch (type) {
      case "success":
        notify(SuccessNotification({ title, message }));
        break;
      case "error":
        notify(ErrorNotification({ title, message }));
        break;
      case "warning":
        notify(WarningNotification({ title, message }));
        break;
      case "info":
      default:
        notify(InfoNotification({ title, message }));
        break;
    }
  }

  return addNotification;
}
