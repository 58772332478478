import { useQuery } from "react-query";

import { userQueryKeys } from "../QueryKeys";
import useApiHelper from "../../hooks/useApiHelper";
import { IDefaultResponseWithData } from "../../types/system/DefaultResponse";
import { IViewConfiguration } from "../../types/Tile/Tile";

export default function useTileViewConfigurations(hardwareId: string) {
  const { get } = useApiHelper();

  const tileViewConfigurations = useQuery({
    queryKey: userQueryKeys.configurations(3),
    queryFn: () =>
      get<IDefaultResponseWithData<IViewConfiguration>>(
        `/v1/user/configurations?type=3&hardwareId=${hardwareId}`
      ).then((res) => res.data.data),
  });

  return {
    tileViewConfigurations,
  };
}
