import clsx from "clsx";

import ThemeColorOptions from "../../../types/system/ThemeColorOption";
import React, { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { getErrorTypeText } from "./getErrorTypeText";

export interface FormSelectProps extends React.HTMLProps<HTMLSelectElement> {
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
  containerClassName?: string;
  small?: boolean;
  color?: ThemeColorOptions;
  label?: string;
}

export const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      children,
      fullWidth = false,
      className,
      containerClassName,
      small = false,
      color = "primary",
      label,
      disabled,
      name,
      ...rest
    }: FormSelectProps,
    ref,
  ) => {
    const {
      formState: { errors },
    } = useFormContext();

    const errorKey = name as string;
    const isError = errors[errorKey];

    const getErrorText = () => {
      if (errors[errorKey]) {
        if (errors[errorKey]?.message) {
          return errors[errorKey]?.message as string;
        } else {
          return `${label} is ${getErrorTypeText(
            errors[errorKey]?.type as string,
          )}`;
        }
      } else {
        return `${label} is ${getErrorTypeText(
          errors[errorKey]?.type as string,
        )}`;
      }
    };

    return (
      <div className={`flex flex-col space-y-1 ${containerClassName}`}>
        {label && (
          <label className="text-sm font-medium opacity-80">{label}</label>
        )}
        <select
          ref={ref}
          className={clsx(
            "appearance-none rounded bg-white border outline-none focus:ring-2 focus:ring-primary dark:bg-gray-700 dark:text-white",
            fullWidth && "w-full",
            disabled
              ? "bg-gray-200 text-gray-800 dark:bg-gray-500 dark:text-gray-300 cursor-not-allowed"
              : "bg-white text-black dark:bg-gray-700 dark:text-white",
            color === "primary"
              ? "border-primary focus:ring-primary"
              : "border-secondary focus:ring-secondary",
            small ? "px-1 py-0.5 pr-10" : "h-[42px] px-3 py-2 pr-10",
            className,
            isError && "!border-red-600 focus:!ring-red-600",
          )}
          disabled={disabled}
          aria-label={label}
          name={name}
          {...rest}
        >
          {children}
        </select>
        {isError && <p className="text-xs text-red-500">{getErrorText()}</p>}
      </div>
    );
  },
);
